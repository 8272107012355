$action-color: #ffd557;
$action-text-color: #ac8600;
$text-color: white;
$main-background-color: white;
$heading-color: #4f5356;
$paragraph-color: #9b9fa4;

.main_container {
  min-height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  background-color: $main-background-color;
  border-radius: 16px;
  margin: 15px;
  width: 480px;
  @media (max-width: 350px) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main_container h3 {
  font-weight: 600;
  font-size: 24px;
  color: $heading-color;
}

.main_container p {
  font-weight: 500;
  font-size: 16px;
  color: $paragraph-color;
  margin-top: 16px;
}

.buttons_container {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.main_container button {
  font-weight: 600;
}

.main_container button:active {
  transform: scale(0.95);
}

.cancel_button {
  color: $paragraph-color;
  width: 90px;
  height: 40px;
  border-radius: 20px;
}

.confirm_button {
  background-color: $action-color;
  color: $action-text-color;
  width: 252px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  @media (max-width: 320px) {
    height: 60px;
    width: 200px;
    border-radius: 12px;
  }
}
