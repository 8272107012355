/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import 'utils';

$main-bg-color: #f3f3f4;
$main-text-color: #4f5356;
$secondary-text-color: #9b9fa4;
$input-color: white;
$input-border-color: #d3d6d8;
$action-color: #0278e4;

.main_container {
  font-weight: 500;
  width: 100%;
  max-width: 898px;
  max-height: 696px;
  height: 70vh;
  background-color: $main-bg-color;
  border-radius: 16px;
  display: flex;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;
  color: $main-text-color;
  display: flex;
  margin-top: 120px;
  @media (max-width: 769px) {
    height: 656px;
    margin-top: 0;
  }
  @media (max-width: 480px) {
    width: 100vw;
    height: 100vh;
    margin: 0;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    max-height: unset;
  }
}

.main_section_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: $main-text-color;
  font-weight: 500;
}

.main_section_content {
  height: 100%;
  width: 100%;
  max-width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_section_heading_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 769px) {
    align-items: center;
  }
}

.main_section_heading {
  font-size: 32px;
  font-weight: 300;
}

.main_section_sub_heading {
  display: flex;
  font-size: 14px;
  @media (max-width: 350px) {
    flex-direction: column;
  }
}

.sign_in_up_switcher {
  color: $action-color;
}

.sign_in_form {
  width: 100%;
  margin-top: 40px;
}

.sign_in_selection_container {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.tab_button {
  height: 56px;
  width: calc(100%);
  background-color: $input-color;
  border: 1px solid $input-border-color;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 12%);
}

.tab_button:active {
  transform: scale(0.97);
  box-shadow: unset;
}

.tab_button_icon {
  margin-right: 10px;
}

.email_tab_button {
  margin-right: 6px;
}

.wallet_tab_button {
  margin-left: 6px;
}

.wallet_button {
  width: 100%;
  height: 48px;
  background-color: $input-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid $input-border-color;
}

.secondary_section_container {
  height: 100%;
  width: 39%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  background-image: url('/images/assets/login/24986f130cfcb826f05620e1aa318a84.png');
  background-size: contain;
  @media (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.secondary_section_section_heading {
  font-size: 14px;
  font-weight: 600;
  margin-top: auto;
}

.secondary_section_paragraph {
  font-size: 14px;
  font-weight: 500;
  margin-top: 24px;
}

.secondary_section_section_list {
  font-size: 14px;
  list-style-type: disc;
  margin-top: 8px;
  padding-left: 10px;
}

.secondary_section_section_list_item {
  margin-top: 5px;

  b {
    font-weight: 600;
  }
}

.secondary_section_section_1tm_description {
  font-size: 14px;
  font-weight: 500px;
  margin-top: auto;
  margin-bottom: 28px;
  text-align: center;
  color: $secondary-text-color;
}

.button_active {
  background-color: rgb(225, 239, 252);
  border: 1px solid rgb(2, 120, 228);
  box-shadow: unset;
}

.signup_container {
  width: 100%;
}

.sign_in_up_container {
  display: flex;
  flex-direction: column;
  width: 65%;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  padding-bottom: 60px;
  overflow-y: overlay;
  @media (max-width: 769px) {
    width: 100%;
    padding-top: 90px;
  }
  @media (max-width: 480px) {
    padding-top: 60px;
  }
}

.sign_in_up_container::-webkit-scrollbar {
  width: 5px;
}

.sign_in_up_container::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 199);
  border-radius: 5px;
}

.sign_in_up_width_adjust {
  width: 100%;
  max-width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.copyright {
  color: #9b9fa4;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  bottom: 16px;
  display: none;
  @media (max-width: 480px) {
    display: unset;
  }
}

.sign_in_selection_heading {
  margin-top: 32px;
}
