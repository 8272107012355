.tribe-card:first-child {
  margin-top: 2.813rem;
}
.tribe-card:last-child {
  border-bottom: none;
}
.tribe-card .desc {
  width: 35.813rem;
}
.search-result-topic__tag.active {
  border: 1px solid #4f5356;
  border-radius: 10rem;
  margin: -1px;

  transition: border 0.1s ease-in-out;
}
.card {
  display: inline-block;
  width: 300px !important;
}

.search-result-creator-card {
  width: 300px;
}

.creator-card {
  width: 300px;
  height: 16rem;
}
.creator-card .motto {
  max-height: 3rem;
}
.creator-card-img {
  width: 4.875rem;
}
.creator-card-img:nth-child(2) {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.creator-card-img-cover {
  right: 1px;
  @apply pt-4 text-center font-semibold bg-black text-white bg-opacity-40 absolute rounded-md z-10 top-0 h-full;
}
.collection-card {
  width: 300px;
  height: 300px;
}
.collection-imgs-1 .img-wrap {
  width: 100%;
  height: 100%;
}
.collection-imgs-3 .img-wrap {
  width: 50%;
  height: 50%;
}
.collection-imgs-2 .img-wrap {
  width: 100%;
  height: 50%;
}

.collection-imgs-3 .img-wrap {
  display: inline-block;
}
.collection-imgs-3 .img-wrap:first-child {
  width: 100%;
  height: 50%;
  display: block;
}
.card-desc {
  max-height: 3.563rem;
}
.creation-card .line-clamp-2 {
  max-height: 3rem;
}
.project-card .line-clamp-3 {
  max-height: 3.75rem;
}
.project-card .project-image:hover .project-detail {
  display: block;
}
