/* fonts.css */
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.text-shadow {
  text-shadow: #ccc 0px 2px 4px;
}

.block-border {
  border-color: transparent;
}

.block-border:hover {
  border-color: #e6e8e9;
}

.block-border:active {
  border-color: 0278e4;
}

.block-border:hover .buttons {
  display: flex;
}

.block-border .buttons {
  display: none;
}

.main-flex > :nth-child(4),
.main-flex > :nth-child(5) {
  background-color: white;
}

.grid-topic > * {
  flex-basis: calc((100% - 24px * 3) / 4);
}

@media screen and (max-width: 1280px) {
  .grid-topic > * {
    flex-basis: calc((100% - 24px * 2) / 3);
  }
}

@media screen and (max-width: 1023px) {
  .grid-topic > * {
    flex-basis: calc((100% - 24px * 1) / 2);
  }
}

@media screen and (max-width: 680px) {
  .grid-topic > * {
    flex-basis: 100%;
  }
}

.grid > div {
  flex-basis: calc((100% - 64px) / 3) !important;
}

.grid4 > div {
  flex-basis: calc((100% - 64px) / 3) !important;
}

.grid2 > :nth-child(1) {
  flex-basis: calc((100% - 96px) / 4) !important;
}
.grid2 > :nth-child(2) {
  flex-basis: calc((100% - 96px) * 3 / 4 + 64px) !important;
}

.grid3 > :nth-child(1) {
  flex-basis: calc((100% - 96px) / 4) !important;
}
.grid3 > :nth-child(2) {
  flex-basis: calc((100% - 96px) * 3 / 4 + 64px) !important;
}

/* LG */
@media (max-width: 1399px) {
  .grid4 > div {
    flex-basis: calc((100% - 32px) / 2) !important;
  }

  .grid3 > :nth-child(1) {
    flex-basis: calc((100% - 64px) / 3) !important;
  }
  .grid3 > :nth-child(2) {
    flex-basis: calc((100% - 64px) * 2 / 3 + 32px) !important;
  }
}
/* MD */
@media (max-width: 991px) {
  .grid > div {
    flex-basis: calc((100% - 32px) / 2) !important;
  }
  .grid2 > :nth-child(1) {
    flex-basis: calc((100% - 64px) / 3) !important;
  }
  .grid2 > :nth-child(2) {
    flex-basis: calc((100% - 64px) * 2 / 3 + 32px) !important;
  }
}

/* SM */
@media (max-width: 767px) {
  .grid4 > div {
    flex-basis: calc((100% - 0px) / 1) !important;
  }
}

/* XS */
@media (max-width: 575px) {
  .grid > div {
    flex-basis: 100% !important;
  }
  .grid2 > :nth-child(1) {
    flex-basis: 100% !important;
  }
  .grid2 > :nth-child(2) {
    flex-basis: 100% !important;
  }
}

@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  src: url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
  src: url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0")
      format("embedded-opentype"),
    url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0")
      format("woff2"),
    url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0")
      format("woff"),
    url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0")
      format("truetype"),
    url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular")
      format("svg");
}
