@import './apply.css';
@import './custom-default.css';
@import './search.css';
@import './card.css';
@import './explore.css';
@import './reppl.css';

@media (min-width: 768px) {
  .md\:width-960 {
    width: 960px;
  }
  .md\:width-1080 {
    width: 1080px;
  }
}

@media (min-width: 768px) {
  .md\:w-85 {
    max-width: 357px;
  }
}

#main {
  height: 100%;
  min-height: calc(100vh - 70px);
}
.desktop-width {
  width: 1440px;
}
.desktop-max-width {
  max-width: 1440px;
  margin: 0 auto;
}
.w-container {
  max-width: 1272px;
}
.tip {
  width: 14.375rem;
  border: 1px solid #e6e8e9;
}
.tip-fadeIn {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}
.tip:after,
.tip:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.tip:after {
  left: -20px;
  border-color: transparent #e6e8e9 transparent transparent;
  border-width: 10px;
  top: 42px;
}

.tip:before {
  left: -19px;
  top: 41px;
  border-color: transparent white transparent transparent;
  border-width: 11px;
  z-index: 10;
}
.license-tip {
  right: -15.375rem;
  top: -2.4rem;
}
.svg-logo-size {
  width: 70% !important;
  height: initial;
}
.button-disable {
  background-color: #9ac9f4;
}
.card-wrap {
  min-height: 385px;
  width: 100%;
}
.hoveredUser {
  background: #c5c5c5;
}
.chakra-select__icon-wrapper {
  right: 0 !important;
}
#editor a {
  @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}
#studioForm a {
  @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}
#signinModal a {
  @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}
.general-content-block a {
  @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}
#editorDetail .codex-editor__redactor,
#assetComment .codex-editor__redactor,
.commentContainer .codex-editor__redactor {
  padding-bottom: 0px !important;
  margin: 0 !important;
}
.codex-editor__redactor {
  padding-bottom: 0px !important;
  margin: 16px 0 !important;
}
.comment_block .codex-editor__redactor {
  margin-right: 0 !important;
}
.ce-block__content {
  margin: 0px !important;
  max-width: unset;
}
.ce-paragraph[data-placeholder]:empty::before {
  color: #bbc0c5 !important;
  font-weight: 500 !important;
}
.ce-header[contentEditable='true'][data-placeholder]::before {
  color: #bbc0c5 !important;
  font-weight: 500 !important;
}
#studioForm p {
  padding: 12px 0;
}
#studioForm :is(h1, h2, h3, h4, h5, h6):first-child {
  padding: 0 0 8px 0;
}
#studioForm :is(h1, h2, h3, h4, h5, h6) {
  padding: 30px 0 8px 0;
}
#toolTip a {
  text-decoration: none;
}
.quotation {
  top: -16px;
  line-height: 72px;
}
.Montserrat {
  font-family: Montserrat;
}
.tab-link .link {
  margin-right: 3rem;
}
.tab-link .link:last-child {
  margin-right: 0;
}
.explore-link .link-active,
.tab-link .link-active,
.reppl-nav .link-active {
  border-bottom: 3px solid;
}
.text-end {
  text-align: end;
}

.marquee:hover .marquee-child {
  animation-play-state: paused;
}

.fade-in-enter {
  opacity: 0;
}
.fade-in-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
.fade-in-exit {
  opacity: 1;
}
.fade-in-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.fade-default {
  transition: opacity 400ms ease-in-out;
  opacity: 0;
}
.fade-entering,
.fade-entered {
  opacity: 1;
}
.fade-exiting,
.fade-exited {
  opacity: 0;
}
.comma::after {
  content: ',';
}
.comma:last-child::after {
  content: '';
}
.tab-bar {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.accordion {
  transition: max-height 0.4s ease-in-out;
}
.accordion-max-hgight {
  max-height: 1000px;
}
/* .accordion .list-layer-content::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 7px;
  border-color: transparent transparent transparent #9b9fa4;
  content: '';
  position: absolute;
  right: 9px;
  top: 14px;
} */
.accordion .list-layer-content:hover .sub-list-layer {
  display: block;
  left: 216px;
  width: fit-content;
  min-width: 216px;
}
.sub-list-layer .reset {
  margin-right: 1rem;
}
.list-layer li {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 2.188rem;
}
.list-layer li:hover {
  @apply text-white bg-gray-400 rounded;
}
.delist:hover {
  background: unset !important;
}
.layer-icon-hover:hover svg {
  display: inline;
}
.line-clamp,
.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4,
.line-clamp-6,
.line-clamp-10 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-1 {
  -webkit-line-clamp: 1;
}
.line-clamp-2 {
  -webkit-line-clamp: 2;
}
.line-clamp-3 {
  -webkit-line-clamp: 3;
}
.line-clamp-4 {
  -webkit-line-clamp: 4;
}
.line-clamp-5 {
  -webkit-line-clamp: 5;
  min-height: 100px;
}
.line-clamp-6 {
  -webkit-line-clamp: 6;
}
.line-clamp-10 {
  -webkit-line-clamp: 10;
}
.input-placeholder::-webkit-input-placeholder {
  text-align: center;
}

.input-placeholder:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

.input-placeholder::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

.input-placeholder:-ms-input-placeholder {
  text-align: center;
}
/* desktop */
@media (min-width: 768px) {
  .md\:line-clamp-3 {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .md\:block-import {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .md\:max-w-720 {
    max-width: 720px;
  }
}
@media (min-width: 1024px) {
  .lg\:max-detail {
    max-width: 808px;
    margin: 0 56px;
  }
}
@media (min-width: 1280px) {
  .xl\:max-detail {
    max-width: 808px;
    margin: 0 10.53%;
  }
}
footer {
  z-index: 500;
}
.app-popup {
  z-index: 1000;
}
.app-popup .container {
  top: 10rem;
}
.filter-popup {
  z-index: 1000;
}
.filter-popup-layer-2 .list-layer {
  margin-top: 0;
}
.filter-popup-layer-2 {
  z-index: 1002;
}
.filter-popup-layer-list .layer-icon-hover {
  padding: 1.5rem;
  border-top: 1px solid #d3d6d8;
}
.filter-popup-layer-list .list-layer > .cursor-pointer {
  padding: 1.5rem;
}
.filter-popup-layer-list .list-layer .reset {
  color: #9b9fa4;
}
.arrow-up {
  position: absolute;
  width: 0;
  height: 0;
  top: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid currentColor;
}
.negative-left {
  left: -2rem;
}
.wave-bg {
  background-image: url('/images/assets/wave-white.svg');
  background-repeat: repeat;
}
.collapse.image:hover {
  width: 105px;
  transition: width 0.7s;
}
.collapse.hashtag:hover {
  width: 120px;
  transition: width 0.7s;
}
.collapse.discussion:hover {
  width: 140px;
  transition: width 0.7s;
}
.collapse.poll:hover {
  width: 90px;
  transition: width 0.7s;
}
.collapse:hover div {
  opacity: 1;
  transition: opacity 0.5s;
}
.collapse div {
  opacity: 0;
  transition: opacity 0.5s;
}
.collapse .icon {
  opacity: 1;
  transition: opacity 0.5s;
}
.collapse:hover .icon {
  opacity: 1;
  transition: opacity 0.5s;
}
.collapse:hover .svgIcon {
  color: #4f5356;
}
.collapse {
  width: 42px;
  overflow: hidden;
  transition: width 0.7s;
}
.item-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.item-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.slider-container-transition {
  transition: transform 300ms ease-in-out;
}
.skeleton-circle {
  @apply rounded-full bg-gray-300 opacity-30;
}
.skeleton-line {
  @apply rounded-sm bg-gray-300 opacity-30;
}
.skeleton-circle-white {
  @apply rounded-full bg-white opacity-30;
}
.skeleton-line-white {
  @apply rounded-sm bg-white opacity-30;
}
.section-empty {
  background-image: url('/images/assets/wave-gray.svg');
  background-repeat: repeat;
  text-align: center;
}
.section-empty-wrap {
  width: 100%;
}
.card-shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
}
.inline-code {
  background: rgba(250, 239, 240, 0.78);
  color: #b44437;
  padding: 3px 4px;
  border-radius: 5px;
  margin: 0 1px;
  font-family: inherit;
  font-size: 0.86em;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.full-banner {
  margin-top: -3.75rem;
}
.w-fit {
  width: 'fit-content';
}
.bg-blend-mode {
  background-blend-mode: multiply, normal;
}
.h-900 {
  height: 900px;
}
.nav-panel-top {
  top: 60px;
  z-index: 1000;
}
.div-offest {
  margin-top: -60px;
}
.nav .menu-list {
  padding: 16px;
}
.policy p,
.policy h5,
.policy ul {
  margin-bottom: 12px;
}
.policy ul {
  list-style: disc;
  margin-left: 20px;
}
.policy ul li,
.policy p {
  line-height: 1.8;
}
.policy .list-lower-latin {
  list-style-type: lower-latin;
}
.policy .list-circle {
  list-style-type: circle;
}
.parallelogram {
  width: 84px;
  height: 5px;
  background: #242526;
  transform: rotate(90deg) skew(145deg);
}
.bg-seagreen-100 {
  background-color: #eaf9f7;
}
.bg-outrageousOrange-100 {
  background-color: #fff0eb;
}
.select-categories .css-48zk6u-control {
  border: 1px solid #ff6633;
  border-radius: 5px;
  background-color: #ff66331a;
}
.fontWeight-500 {
  font-weight: 500 !important;
}
.progress-bar > div {
  background-color: #4f5356;
}
.overflow-auto::-webkit-scrollbar {
  display: none;
}
.overflow-auto {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#nav {
  z-index: 100 !important;
}
.editorBtn {
  color: #fff !important;
  text-decoration: unset !important;
  background-color: unset !important;
}
.editorBtnContainer {
  width: fit-content;
  height: 40px;
  border-radius: 20px;
  color: #fff !important;
  padding: 10px 32px;
  text-decoration: unset !important;
  margin: auto;
  background-color: #242526;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.card-300 {
  width: 300px !important;
  margin: 0 12px !important;
}
b {
  @apply font-bold text-gray-900;
}
.gradientBG {
  background: linear-gradient(180deg, #ffffff 0%, #f3f3f4 25%, #f3f3f4 100%);
}
.gradient-border-transparent {
  background: url('data:image/svg+xml;utf8,<svg   xmlns="http://www.w3.org/2000/svg" ><defs><linearGradient id="Gradient" x1="0" x2="100" y1="0" y2="0" gradientUnits="userSpaceOnUse"><stop stop-color="%235BB1FF" offset="0"/><stop stop-color="%231E80DA" offset="1.0"/></linearGradient></defs><rect x="5" y="5" width="100%" height="100%" style="height:calc(100% - 10px);width:calc(100% - 10px)" rx="15" ry="15" stroke-width="2" fill="transparent" stroke="url(%23Gradient)"/></svg>');
  border-radius: 15px;
}
.gradientHeader {
  background: linear-gradient(180deg, #ffffff 0%, #ffffffd4 25%, #fff0 100%);
}
.gradient_bg_dark {
  background: linear-gradient(
    180deg,
    #00000000 0%,
    #00000000 25%,
    #0000001a 100%
  );
}
.-gridtopic > * {
  flex-basis: 33.33% !important;
}

@media screen and (max-width: 1600px) {
  .grid-topic > * {
    flex-basis: 33.33% !important;
  }
}

@media screen and (max-width: 1440px) {
  .grid-topic > * {
    flex-basis: 50% !important;
  }
}

@media screen and (max-width: 768px) {
  .grid-topic > * {
    flex-basis: 50% !important;
  }
}

@media screen and (max-width: 767px) {
  .grid-topic > * {
    flex-basis: 100% !important;
  }
}
grid-topicindex > * {
  flex-basis: 50% !important;
}

@media screen and (max-width: 3000px) {
  .grid-topicindex > * {
    flex-basis: 50% !important;
  }
}

@media screen and (max-width: 1440px) {
  .grid-topicindex > * {
    flex-basis: 50% !important;
  }
}

@media screen and (max-width: 1200px) {
  .grid-topicindex > * {
    flex-basis: 100% !important;
  }
}

@media screen and (max-width: 992px) {
  .grid-topicindex > * {
    flex-basis: 50% !important;
  }
}

@media screen and (max-width: 768px) {
  .grid-topicindex > * {
    flex-basis: 50% !important;
  }
}

@media screen and (max-width: 590px) {
  .grid-topicindex > * {
    flex-basis: 100% !important;
  }
}

.text-shadow {
  text-shadow: #ccc 0px 2px 4px;
}

.block-border {
  border-color: transparent;
}

.block-border:hover::before {
  content: '';
  position: absolute;
  border: 1px solid #e6e8e9;
  border-radius: 8px;
  width: 100%;
  /*height: calc(100% + 56px);
  top: -28px;*/
  top: 0;
  height: 100%;
  left: -0.5px;
  z-index: 10;
  pointer-events: none;
}

/*.active-block::before {
  content: '';
  position: absolute;
  border: 2px dashed #4F5356 !important;
  border-radius: 24px;
  width: 100%;
  height: calc(100% + 56px);
  top: -28px;
  z-index: 2;
  background-color: ;
}*/

.left-panel {
  border-color: transparent;
}

.block-border:hover .buttons {
  display: flex;
  z-index: 50;
}

.block-border .buttons {
  display: none;
}
.bg-unset {
  background-color: unset !important;
}

.hover\:filter-none:hover {
  filter: unset !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.padding-offset {
  padding-top: 60px;
}

#submission-sidebar .css-1hkcbxk {
  display: block !important;
  right: 0 !important;
  width: unset !important;
  left: unset !important;
}
#submission-sidebar .css-45rysj,
.css-cokb9n,
.css-896018 {
  height: 100% !important;
  border-radius: 0 !important;
  background: rgb(241 245 249) !important;
}

.manager_submission_tab .css-hkp4xs,
.css-1ose741,
.css-17fwn2k {
  text-align: inherit;
  display: block;
}

@media (min-width: 0px) {
  .base\:right-6-6 {
    right: 6.6%;
  }
}
@media (min-width: 769px) {
  .md\:right-4 {
    right: 1rem;
  }
}

@media (min-width: 1280px) {
  .xl\:right-7 {
    right: 1.75rem;
  }
}
@media (min-width: 769px) {
  .md\:right-4 {
    right: 1rem;
  }
}
@media (min-width: 1280px) {
  .xl\:right-7 {
    right: 1.75rem;
  }
}
.player-ratio > video {
  object-fit: cover;
}
.scroll-hidden::-webkit-scrollbar {
  display: none;
}
.iframely-open-spotify {
  margin: 0 auto;
  width: 500px;
}
.iframely-responsive {
  border-width: 1px;
  border-color: #dcdee2;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .iframely-responsive {
    border-radius: 8px;
  }
}
.unfocus:focus {
  box-shadow: none !important;
}
.no_border {
  border: none !important;
}
.ldsRing {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 575px) {
  .responsiveDown {
    display: grid;
  }
}

.inline-image__control-panel [contenteditable]:empty:not(:focus):after {
  content: attr(data-placeholder);
}

.remove-box-shadow {
  box-shadow: unset !important;
}

.ca-property:hover .delete {
  opacity: 100;
}

select {
  cursor: pointer;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
