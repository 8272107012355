.main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  background: linear-gradient(
    99.18deg,
    #28aee8 6.57%,
    #2875e8 40.1%,
    #ac5ba7 95.45%
  );

  button {
    text-decoration: underline;
  }

  @media only screen and (max-width: 400px) {
    font-size: 12px;
  }
}
