
.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  background-color: white;
  width: 480px;
  border-radius: 16px;
  padding: 40px;
  transition: 300ms;
  position: relative;
  animation: scalein 300ms ease-in-out;

  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #242526;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #242526;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #9b9fa4;
  }

  @media only screen and (max-width: 520px) {
    height: 100vh !important;
    width: 100%;
    border-radius: unset;
    padding: 56px 24px 0 24px;
  }
}

.section_container {
  display: flex;
  gap: 32px;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
}

.text_wrapper {
  max-width: 320px;
}

.image {
  width: 48px;
  height: 48px;
}

.image2 {
  width: 40px;
  height: 40px;
}

.buttons_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 40px;
}

.get_wallet_button {
  width: 166px;
  height: 40px;
  background: #288ce8;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}

.learn_more_button {
  border-radius: 20px;
  color: #288ce8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-top: 12px;
  cursor: pointer;
}

.option_cards_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}

.option_card {
  border: 1px solid #d3d6d8;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.option_button {
  width: 154px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d3d6d8;
  border-radius: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #0278e4;
  margin-top: 14px;
  cursor: pointer;
}

.back_button {
  position: absolute;
  top: 21px;
  left: 21px;
}

.back_icon {
  rotate: 180deg;
  font-size: 24px;
}

.close_button {
  position: absolute;
  top: 32px;
  right: 26px;
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.x1,
.x2 {
  position: absolute;
  width: 18px;
  height: 2px;
  border-radius: 20px;
  background-color: #9b9fa4;
  top: 0;
  left: 0;
}

.x1 {
  rotate: 45deg;
}

.x2 {
  rotate: -45deg;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scalein {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}

.fadein {
  animation: fadein 300ms ease-in-out;
}
