.search-panel-wrap {
  top: 3.75rem;
}
.filter-wrap {
  width: 300px;
}
.search-width {
  width: 1272px;
}
.search-right-width {
  width: 948px;
}
.search-result.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.search-result-tabs {
  width: fit-content;
  margin: auto;
}
@media (min-width: 1024px) {
  .global-search {
    width: 948px;
  }
  .search-result-tabs-container {
    max-width: 615px;
  }
}

@media (max-width: 1330px) {
  .search-result-content {
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: column;
  }
  .search-result-content .search-filter-block {
    width: 100%;
  }
  .search-result-content .filter-wrap {
    width: 100%;
  }
  .search-result-content .search-right-width {
    width: 100%;
  }
  .search-result-content .search-result-creator-card {
    width: 100% !important;
  }
  .search-result-content .tribe-card-v2 {
    width: 100% !important;
  }
  .search-tabs-block {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
  .search-input-block {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
  .search-width {
    width: 100%;
  }
}

@media (max-width: 994px) {
  .search-tabs-block .sort-by-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
