/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 700 12px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type1: 600 16px/1.25 "Montserrat", Helvetica, Arial, serif;
$font_type2: 500 14px/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type3: 600 14px/1.28 "Montserrat", Helvetica, Arial, serif;
$font_type4: 500 1em/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type5: 600 40px/1.2 "Montserrat", Helvetica, Arial, serif;
$font_type6: 16px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type7: 500 72px/0.5 "Montserrat", Helvetica, Arial, serif;
$font_type8: 20px/1.4 "Montserrat", Helvetica, Arial, serif;

.group {
  @include flex-column;
}
.group_layout4 {
  position: relative;
}
.icon1 {
  background: var(--src) center center / contain no-repeat;
}
.icon1_layout {
  position: absolute;
  z-index: 4;
  top: 24px;
  height: 44px;
  width: 44px;
  min-width: 44px;
  right: 24px;
  @include lg {
    display: none;
  }
}
.main {
  @include flex-column;
  background-color: rgb(255, 255, 255);
}
.main_layout {
  position: relative;
  height: min-content;
}
.main_item {
  @include flex-column;
  position: relative;
}
.group_layout3 {
  position: relative;
  height: min-content;
  @include lg {
    margin: 0px;
  }
}
.block28 {
  display: flex;
}
.block28_layout {
  position: relative;
  height: min-content;
}
.block28_item {
  @include flex-column;
  position: relative;
  flex: 1 0 auto;
  min-width: 32px;
  @media (max-width: 1280px) {
    display: none;
  }
}
.block29 {
  @include flex-column;
}
.block29_layout {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
}
.block28_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 1376px;
}
.block1 {
  display: flex;
  background-color: rgb(255, 255, 255);
  @include lg {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.block1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  max-width: 1376px;
  min-height: calc(100vh - 207px);
}
.block1_item {
  @include flex-column;
  position: relative;
  flex: 0 1 25%;
  min-width: 320px;
  @media (max-width: 991px) {
    background: linear-gradient(180deg, #F3F3F4 0%, #F3F3F4 65.62%, rgba(243, 243, 244, 0) 100%);
    flex: 0 0 100%;
    order: 3;
    max-width: 100vw;
  }
}
.cover_block1 {
  @include flex-column;
}
.cover_block1_layout {
  position: relative;
  height: 100%;
}
.block19 {
  @include flex-column;
}
.block19_layout {
  position: relative;
  height: min-content;
  width: 77.62%;
  min-width: 252px;
  margin: 80px auto 0px;
  @include md {
    width: 100%;
    padding: 80px 24px;
  }
}
.flex {
  @include flex-column;
}
.flex_layout {
  position: relative;
  height: min-content;
  margin: 0px 1.5px;
}
.small_text_body {
  @include font-face($font_type0, rgb(187, 192, 197), 0px);
}
.small_text_body_layout {
  position: relative;
  height: min-content;
}
.icon {
  background: var(--src) center center / contain no-repeat;
}
.icon_layout {
  position: relative;
  height: 56px;
  width: 56px;
  min-width: 56px;
  margin: 16px 0px 0px;
}
.highlights {
  @include font-face($font_type1, rgb(79, 82, 86), 0px);
}
.highlights_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.paragraph_body {
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.paragraph_body_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.flex_item {
  @include flex-column;
  position: relative;
}
.block2 {
  @include flex-column;
  background-color: rgb(2, 120, 228);
  border-radius: 16px 16px 16px 16px;
}
.block2_layout {
  position: relative;
  height: min-content;
  width: 103px;
  min-width: 103px;
  margin: 8px 0px 0px;
}
.text_body {
  @include h-center-content;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
}
.text_body_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 7px auto;
}
.group_layout {
  position: relative;
  height: min-content;
  margin: 48px 1.5px 0px 0px;
}
.block20 {
  @include flex-column;
}
.block20_layout {
  position: relative;
  height: min-content;
}
.small_text_body_layout1 {
  position: relative;
  height: min-content;
  margin: 0px 1.5px;
}
.flex2 {
  display: flex;
}
.flex2_layout {
  position: relative;
  height: min-content;
  margin: 16px 1.5px 0px;
}
.flex2_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 40px;
}
.icon1_layout1 {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.flex2_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.text_body1 {
  @include font-face($font_type3, rgb(79, 82, 86), 0px);
}
.text_body1_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 11px 0px;
}
.flex3 {
  display: flex;
}
.flex3_layout {
  position: relative;
  height: min-content;
  margin: 8px 1px 0px;
}
.text_body2 {
  @include v-center-content;
  justify-content: center;
  font: $font_type2;
  color: rgb(154, 159, 163);
  text-align: center;
  letter-spacing: 0px;
}
.text_body2_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.flex3_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
}
.icon2 {
  background: var(--src) center center / contain no-repeat;
}
.icon2_layout {
  position: relative;
  height: 3px;
  width: 3px;
  min-width: 3px;
  margin: 8.5px auto;
}
.block20_item {
  @include flex-column;
  position: relative;
}
.cover_block {
  @include flex-column;
  border-radius: 8px 8px 8px 8px;
}
.cover_block_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  max-width: 264px;
  margin: 16px 0px 0px 1.5px;
  @include xxs {
    max-width: 999px;
  }
}
.cover_block2 {
  border-radius: 8px 8px 8px 8px;
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  display: initial;
}
.block3 {
  @include flex-column;
  background-color: rgb(36, 37, 38);
  border-radius: 13px 13px 13px 13px;
}
.block3_layout {
  position: absolute;
  top: 16px;
  height: 26px;
  left: 16px;
  min-width: 0px;
  width: fit-content;
}
.small_text_body1 {
  @include font-face($font_type0, rgb(210, 213, 216), 0px);
}
.small_text_body1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 4px 10px;
}
.text_body1_layout1 {
  position: relative;
  height: min-content;
  margin: 16px 1.5px 0px;
}
.text_body21 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body21_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px 1.5px;
}
.paragraph_body1 {
  @include v-center-content;
  justify-content: center;
  font: $font_type2;
  color: rgb(79, 82, 86);
  text-align: center;
  letter-spacing: 0px;
}
.paragraph_body1_layout {
  position: relative;
  height: min-content;
  width: 68px;
  min-width: 68px;
  margin: 8px 0px 0px;
}
.block21 {
  @include flex-column;
}
.block21_layout {
  position: relative;
  height: min-content;
  margin: 48px 0px 0px 0.56%;
}
.flex4 {
  display: flex;
}
.flex4_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex4_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 40px;
}
.icon1_layout2 {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 1px 0px;
}
.flex4_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex4_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 99px;
}
.flex5 {
  @include flex-column;
}
.flex5_layout {
  position: relative;
  height: min-content;
}
.text_body1_layout2 {
  position: relative;
  height: min-content;
}
.text_body22 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body22_layout {
  position: relative;
  height: min-content;
  margin: 4px 19px 0px 0px;
}
.flex6 {
  display: flex;
  align-items: center;
}
.flex6_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex6_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 40px;
}
.icon1_layout3 {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 1px 0px;
}
.flex6_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex6_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 102px;
}
.flex7 {
  @include flex-column;
}
.flex7_layout {
  position: relative;
  height: min-content;
}
.text_body1_layout3 {
  position: relative;
  height: min-content;
}
.text_body23 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body23_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px 0px;
  width: max-content;
}
.block22 {
  @include flex-column;
}
.block22_layout {
  position: relative;
  height: min-content;
  margin: 48px 0px 0px 0.56%;
}
.flex8 {
  display: flex;
  align-items: center;
}
.flex8_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex8_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 40px;
}
.icon1_layout4 {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 1px 0px;
}
.flex8_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex8_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 167px;
}
.flex9 {
  @include flex-column;
}
.flex9_layout {
  position: relative;
  height: min-content;
}
.text_body1_layout4 {
  position: relative;
  height: min-content;
}
.text_body24 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body24_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px 0px;
}
.flex10 {
  display: flex;
}
.flex10_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex10_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 40px;
}
.icon1_layout5 {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 1px 0px;
}
.flex10_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex10_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 102px;
}
.text_body1_layout5 {
  position: relative;
  height: min-content;
}
.text_body25 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body25_layout {
  position: relative;
  height: min-content;
  margin: 4px 29px 0px 0px;
}
.flex12 {
  display: flex;
}
.flex12_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex12_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 40px;
}
.icon1_layout6 {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 1px 0px;
}
.flex12_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex12_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 101px;
}
.flex13 {
  @include flex-column;
}
.flex13_layout {
  position: relative;
  height: min-content;
}
.text_body1_layout6 {
  position: relative;
  height: min-content;
}
.text_body26 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body26_layout {
  position: relative;
  height: min-content;
  margin: 4px 28px 0px 0px;
}
.flex14 {
  display: flex;
}
.flex14_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex14_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 40px;
}
.icon1_layout7 {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 1px 0px;
}
.flex14_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex14_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 92px;
}
.flex15 {
  @include flex-column;
}
.flex15_layout {
  position: relative;
  height: min-content;
}
.text_body1_layout7 {
  position: relative;
  height: min-content;
}
.text_body27 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body27_layout {
  position: relative;
  height: min-content;
  margin: 4px 19px 0px 0px;
}
.group_layout1 {
  position: relative;
  height: 40px;
  width: 112px;
  min-width: 112px;
  margin: 16px 0px 0px;
}
.block4 {
  background: var(--src) center center / contain no-repeat;
}
.block4_layout {
  top: 0px;
  height: 40px;
  left: 72px;
  width: 40px;
  min-width: 40px;
}
.icon1_layout8 {
  position: absolute;
  top: 0px;
  height: 40px;
  left: 48px;
  width: 40px;
  min-width: 40px;
}
.icon1_layout9 {
  position: absolute;
  top: 0px;
  height: 40px;
  left: 24px;
  width: 40px;
  min-width: 40px;
}
.icon1_layout10 {
  position: absolute;
  top: 0px;
  height: 40px;
  left: 0px;
  width: 40px;
  min-width: 40px;
}
.block23 {
  @include flex-column;
}
.block23_layout {
  position: relative;
  height: min-content;
  margin: 48px 6.5px 0px 1.5px;
}
.flex18 {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
}
.flex18_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex18_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block5 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 14px;
}
.block5_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.text_body3 {
  @include font-face($font_type2, rgb(2, 120, 228), 0px);
}
.text_body3_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 4px 10px;
}
.block6 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block6_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.block7 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block7_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.block24 {
  @include flex-column;
}
.block24_layout {
  position: relative;
  height: min-content;
  width: 82.02%;
  margin: 48px 17.42% 0px 0.56%;
  @include xs {
    width: 98.65%;
    margin: 48px auto 0px;
  }
}
.flex19 {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
}
.flex19_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex19_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block8 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 14px;
}
.block8_layout {
  position: relative;
  height: min-content;
}
.block9 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block9_layout {
  position: relative;
  height: min-content;
}
.block10 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block10_layout {
  position: relative;
  height: min-content;
}
.block11 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block11_layout {
  position: relative;
  height: min-content;
}
.flex1 {
  @include flex-column;
}
.flex1_layout {
  position: relative;
  height: min-content;
  margin: 48px 1.5px 24px;
}
.flex1_item {
  @include flex-column;
  position: relative;
  flex: 0 1 28px;
}
.flex20 {
  display: flex;
}
.flex20_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 16px 0px 0px;
}
.image1 {
  background: var(--src) center center / contain no-repeat;
}
.image1_layout {
  position: relative;
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex20_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.paragraph_body11 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type2, rgb(0, 0, 0), 0px);
  white-space: pre-wrap;
}
.paragraph_body11_box_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.paragraph_body11_span0 {
  @include font-face($font_type4, #9a9fa3ff, 0px);
}
.paragraph_body11_span1 {
  @include font-face($font_type4, #c6c8d1ff, 0px);
}
.paragraph_body11_span2 {
  @include font-face($font_type4, #0278e4ff, 0px);
}
.paragraph_body11_span3 {
  @include font-face($font_type4, #9a9fa3ff, 0px);
}
.block1_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @media (max-width: 991px) {
    display: none;
    flex: 0 0 100%;
    order: 4;
    min-width: unset;
  }
}
.image2 {
  background-color: #E6E8E9;
}
.image2_layout {
  position: relative;
  height: 100%;
}
.block1_spacer {
  @include flex-column;
  position: relative;
  width: auto;
  margin: auto;
  @include md {
    display: none;
  }
}
.block1_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 66%;
  max-width: 54vw;
  margin: auto;
  margin-top: 0;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    order: 1;
    max-width: 100vw;
  }
}
.group_layout2 {
  position: relative;
  height: min-content;
  margin: 80px 0px 64px;
}
.flex22 {
  @include flex-column;
}
.flex22_layout {
  position: relative;
  height: min-content;
  width: 100%;
  @include lg {
    margin: 0px;
  }
}
.flex22_item {
  @include flex-column;
  position: relative;
  @include md {
    max-width: 100vw;
  }
}
.flex16 {
  @include flex-column;
}
.flex16_layout {
  position: relative;
  height: min-content;
  width: auto;
  min-width: 585px;
  @include lg {
    min-width: 430px;
  }
  @include xxs {
    min-width: 300px;
  }
}
.flex16_item {
  @include flex-column;
  position: relative;
  flex: 0 1 42px;
  @include max {
    display: none;
  }
  @include md {
    display: flex;
    margin: 0px 24px;
  }
}
.flex12_layout1 {
  position: relative;
  height: min-content;
}
.icon1_layout11 {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 1px 0px;
}
.flex12_item2 {
  @include flex-column;
  position: relative;
  flex: 1 1 101px;
}
.text_body1_layout8 {
  position: relative;
  height: min-content;
}
.text_body28 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body28_layout {
  position: relative;
  height: min-content;
  margin: 4px 28px 0px 0px;
}
.flex16_item1 {
  @include flex-column;
  position: relative;
}
.hero_title {
  @include v-center-content;
  @include font-face($font_type5, rgb(36, 37, 38), -0.4px);
  @include sm {
    font-size: 32px;
    text-align: left;
  }
}
.hero_title_layout {
  position: relative;
  height: min-content;
  @include md {
    margin: 16px 24px 0px;
  }
}
.hero_title_layout_asset{
  position: relative;
  height: min-content;
}
.flex16_item2 {
  @include flex-column;
  position: relative;
}
.text_body29 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body29_layout {
  position: relative;
  height: min-content;
  margin: 24px 0px 0px;
  @include md {
    margin: 24px;
  }
}
.flex21 {
  @include flex-column;
}
.flex21_layout {
  position: relative;
  height: min-content;
  margin: 40px 0px 0px;
}
.image3 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.image3_layout {
  position: relative;
  width: 86.03%;
  margin: 0px auto;
  @include sm {
    width: 100%;
    margin: 0px 0px;
  }
}
.block25 {
  @include flex-column;
}
.block25_layout {
  position: relative;
  height: min-content;
  width: 69.35%;
  margin: 24px auto 0px;
  @include sm {
    width: 86.04%;
  }
  @include xxs {
    width: 87.73%;
  }
}
.block25_item {
  @include flex-column;
  position: relative;
}
.flex23 {
  display: flex;
}
.flex23_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px auto;
  @include sm {
    margin: 0px auto 0px 0px;
  }
}
.flex23_item {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
  @include sm {
    display: none;
  }
}
.block12 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.block12_layout {
  position: relative;
  width: 40px;
  min-width: 40px;
}
.flex23_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 32px;
  @include sm {
    display: none;
  }
}
.flex23_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.paragraph_body12 {
  font: $font_type2;
  color: rgb(0, 0, 0);
  text-align: right;
  letter-spacing: 0px;
}
.paragraph_body12_box {
  display: flex;
  justify-content: flex-end;
}
.paragraph_body12_box_layout {
  position: relative;
  height: min-content;
  margin: 10px 0px;
}
.paragraph_body12_span0 {
  @include font-face($font_type4, #4f5256ff, 0px);
}
.paragraph_body12_span1 {
  @include font-face($font_type4, #9a9fa3ff, 0px);
}
.highlights1 {
  display: flex;
  justify-content: flex-end;
  font: $font_type1;
  color: rgb(79, 82, 86);
  text-align: right;
  letter-spacing: 0px;
  @include lg {
    justify-content: flex-start;
    text-align: left;
  }
}
.highlights1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 16px auto 0px;
  @include sm {
    margin: 16px auto 0px 0px;
  }
}
.text_body210 {
  @include h-center-content;
  font: $font_type2;
  color: rgb(154, 159, 163);
  text-align: center;
  letter-spacing: 0px;
  @include sm {
    justify-content: flex-start;
    text-align: left;
  }
}
.text_body210_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex22_item1 {
  @include flex-column;
  position: relative;
  @include max {
    flex: 0 1 722px;
    display: none;
  }
  @include sm {
    flex: 0 1 auto;
    display: flex;
  }
}
.flex21_layout1 {
  position: relative;
  height: min-content;
  @include sm {
    margin: 24px 0px 0px;
  }
}
.flex23_layout1 {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.paragraph_body13 {
  font: $font_type2;
  color: rgb(0, 0, 0);
  text-align: right;
  letter-spacing: 0px;
}
.paragraph_body13_box {
  display: flex;
  justify-content: flex-end;
}
.paragraph_body13_box_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 10px 0px;
}
.paragraph_body13_span0 {
  @include font-face($font_type4, #4f5256ff, 0px);
}
.paragraph_body13_span1 {
  @include font-face($font_type4, #9a9fa3ff, 0px);
}
.highlights_layout1 {
  position: relative;
  height: min-content;
  width: 59.44%;
  margin: 16px auto 0px;
  @include lg {
    width: 100%;
    margin: 16px 0px 0px;
  }
}
.text_body211 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
  white-space: pre-wrap;
}
.text_body211_box_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex21_layout2 {
  position: relative;
  height: min-content;
}
.block25_layout1 {
  position: relative;
  height: min-content;
  width: 69.35%;
  margin: 0px auto;
}
.flex24 {
  display: flex;
}
.flex24_layout {
  position: relative;
  height: min-content;
  width: 48px;
  min-width: 48px;
  margin: 40px auto 0px;
}
.flex24_item {
  @include flex-column;
  position: relative;
  flex: 0 1 4px;
}
.icon3 {
  background: var(--src) center center / contain no-repeat;
}
.icon3_layout {
  position: relative;
  height: 4px;
  width: 4px;
  min-width: 4px;
}
.flex24_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.highlights11 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type6, rgb(36, 37, 38), 0px);
  white-space: pre-wrap;
}
.highlights11_box_layout {
  position: relative;
  height: min-content;
  width: 69.35%;
  margin: 40px auto 0px;
  @include sm {
    width: 86.04%;
  }
  @include xxs {
    width: 87.73%;
  }
}
.block26 {
  @include flex-column;
}
.block26_layout {
  position: relative;
  height: min-content;
  width: 69.35%;
  margin: 76px auto 0px;
  @include sm {
    width: 86.04%;
  }
  @include xxs {
    width: 87.73%;
  }
}
.image5 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.image5_layout {
  position: relative;
}
.flex25 {
  display: flex;
}
.flex25_layout {
  position: relative;
  height: min-content;
  width: 44px;
  min-width: 44px;
  margin: 40px auto 0px;
}
.flex25_item {
  @include flex-column;
  position: relative;
  flex: 0 1 4px;
}
.flex25_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 16px;
}
.flex17 {
  @include flex-column;
}
.flex17_layout {
  position: relative;
  height: min-content;
  width: 100%;
  margin: 40px auto 0px;
  @include lg {
    width: 86.04%;
  }
  @include xxs {
    width: 87.73%;
  }
}
.flex17_item {
  @include flex-column;
  position: relative;
  margin: auto;
}
.text_body212 {
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body212_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px auto;
}
.block13 {
  display: flex;
}
.block13_layout {
  position: relative;
  height: min-content;
  margin: 23px 0px 0px;
}
.hero_title1 {
  @include v-center-content;
  justify-content: center;
  font: $font_type7;
  color: rgb(210, 213, 216);
  text-align: center;
  letter-spacing: 0px;
}
.hero_title1_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 5px 0px 15px;
}
.block13_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.block13_item {
  @include flex-column;
  position: relative;
  flex: 1 1 636px;
}
.subtitle {
  @include v-center-content;
  justify-content: center;
  font: $font_type8;
  color: rgb(36, 37, 38);
  text-align: center;
  letter-spacing: 0px;
}
.subtitle_layout {
  position: relative;
  height: min-content;
  max-width: 764px;
}
.block28_spacer {
  @include flex-column;
  position: relative;
  flex: 1 0 auto;
  min-width: 32px;
  @media (max-width: 991px) {
    display: none;
  }
}
.main_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 88px;
  @include max {
    display: none;
  }
}
