$action-color: #0278E4;
$text-color: white;
$main-background-color: white;
$heading-color: #4F5356;
$paragraph-color:#9B9FA4;

.main_container {
    min-height: 370px;
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    background-color: $main-background-color;
    border-radius: 16px;
    margin: 15px;
    @media(max-width: 350px) {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
      }
}

.main_container h3 {
    font-weight: 600;
    font-size: 24px;
    color: $heading-color;
}

.main_container p {
    font-weight: 500;
    font-size: 16px;
    color: $paragraph-color;
    margin-top: 16px;
}

.main_container button {
    margin-top: 40px;
    height: 40px;
    width: 88px;
    border-radius: 20px;
    color: $text-color;
    background-color: $action-color;
    font-weight: 600;
}

.main_container button:active {
    transform: scale(0.95);
}
