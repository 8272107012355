/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$color_type_0: #9a9fa3ff;
$color_type_1: rgb(115, 120, 124);
$color_type_2: rgb(129, 188, 242);
$color_type_3: rgb(154, 159, 163);
$color_type_4: rgb(187, 192, 197);
$color_type_5: rgb(2, 120, 228);
$color_type_6: rgb(210, 213, 216);
$color_type_7: rgb(230, 232, 233);
$color_type_8: rgb(235, 247, 255);
$color_type_9: rgb(243, 243, 244);
$color_type_10: rgb(250, 251, 251);
$color_type_11: rgb(255, 255, 255);
$color_type_12: rgb(36, 37, 38);
$color_type_13: rgb(40, 140, 232);
$color_type_14: rgb(79, 82, 86);

$font_type_0: 500 40px/1.1 "Montserrat", Helvetica, Arial, serif;
$font_type_1: 500 20px/1.4 "Montserrat", Helvetica, Arial, serif;
$font_type_2: 500 16px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type_3: 600 16px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type_4: 500 14px/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type_5: 600 14px/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type_6: 14px/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type_7: 500 12px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type_8: 500 12px/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type_9: 500 1em/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type_10: 500 0.8571428571428571em/1.5 "Montserrat", Helvetica, Arial, serif;

.group {
  @include flex-column;
}
.group_layout8 {
  position: relative;
}
.block {
  display: flex;
  background-color: $color_type_11;
}
.block_layout {
  position: relative;
  flex-grow: 1;
}
.block_item {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include md {
    display: none;
  }
}
.cover_block {
  @include flex-column;
  background-color: $color_type_9;
  height: 100vh !important;
}
.cover_block_layout {
  position: relative;
  flex-grow: 1;
}
.block_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 600px;
  @include md {
    display: none;
  }
}
.cover_block1 {
  @include flex-column;
  background-color: $color_type_9;
}
.cover_block1_layout {
  position: relative;
  flex-grow: 1;
}
.image {
  background: var(--src) center center / cover no-repeat;
}
.image_layout {
  position: absolute;
  z-index: 2;
  top: 0px;
  bottom: 0px;
  width: 1px;
  right: -1px;
}
.group_layout1 {
  position: relative;
  flex-grow: 1;
}
.cover {
  background: var(--src) center center / cover no-repeat;
}
.cover_layout {
  position: absolute;
  z-index: 1;
  top: 0px;
  height: 250px;
  width: 810px;
  right: 0px;
}
.group_layout {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  margin: 0px 87px;
}
.flex {
  @include flex-column;
  top: 102px;
  position: sticky !important;
}
.flex_layout {
  position: relative;
  height: min-content;
  margin: 134px 0px 0px;
}
.block1 {
  @include flex-column;
}
.block1_layout {
  position: relative;
  height: min-content;
}
.hero_title {
  @include font-face($font_type_0, $color_type_12, -0.8px);
}
.hero_title_layout {
  position: relative;
  height: min-content;
}
.block186 {
  @include flex-column;
}
.block186_layout {
  position: relative;
  height: min-content;
  margin: 36px 0px 0px;
}
.block187 {
  @include flex-column;
}
.block187_layout {
  position: relative;
  height: min-content;
}
.highlights7 {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_3, 0px);
}
.highlights7_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 16px;
}
.block188 {
  @include flex-column;
}
.block188_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.block189 {
  @include flex-column;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09803921568627451);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 24px 24px 24px 24px;
}
.block189_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
}
.nftcard_header {
  @include flex-column;
  background-color: $color_type_11;
}
.nftcard_header_layout {
  position: relative;
  height: min-content;
}
.block192 {
  display: flex;
}
.block192_layout {
  position: relative;
  height: min-content;
  margin: 16px;
}
.block192_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 30px;
}
.block172 {
  @include flex-column;
  background: var(--src) center center / contain no-repeat;
  border-radius: 8px 8px 8px 8px;
}
.block172_layout {
  position: relative;
  height: 30px;
}
.block192_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.block193 {
  @include flex-column;
}
.block193_layout {
  position: relative;
  flex: 1 1 auto;
  height: min-content;
  margin: 5px 0px;
}
.text_body6 {
  @include font-face($font_type_4, $color_type_12, 0px);
}
.text_body6_layout {
  position: relative;
  height: min-content;
}
.block189_item {
  @include flex-column;
  position: relative;
}
.background_wrap {
  @include flex-column;
}
.background_wrap_layout {
  position: relative;
  height: min-content;
}
.nftcard_imageholder {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.nftcard_imageholder_layout {
  position: relative;
}
.highlights71 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_2;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0px;
  width: 100%;
  height: 100%;
}
.block195 {
  @include flex-column;
  background-color: $color_type_11;
}
.block195_layout {
  position: relative;
  height: min-content;
}
.flex1 {
  @include flex-column;
}
.flex1_layout {
  position: relative;
  height: min-content;
  margin: 28px 24px;
}
.block197 {
  @include flex-column;
}
.block197_layout {
  position: relative;
  height: min-content;
}
.nftcard_title {
  @include font-face($font_type_7, $color_type_4, 0px);
}
.nftcard_title_layout {
  position: relative;
  height: min-content;
}
.nftcard_description {
  @include font-face($font_type_1, $color_type_4, 0px);
}
.nftcard_description_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.block198 {
  display: flex;
}
.block198_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.block198_item {
  @include flex-column;
  position: relative;
  flex: 0 1 312px;
}
.block199 {
  @include flex-column;
}
.block199_layout {
  position: relative;
  height: min-content;
}
.icon2 {
  background: var(--src) center center / contain no-repeat;
}
.icon2_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.block198_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 12px;
}
.block198_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}
.block198_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}
.block_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 900px;
  @include md {
    flex: 1 1 900px;
  }
}
.flex5 {
  @include flex-column;
}
.flex5_layout {
  position: relative;
  flex-grow: 1;
}
.flex4 {
  @include flex-column;
  background-color: $color_type_11;
}
.flex4_layout {
  position: relative;
  margin: 140px 0px 0px;
  @include md {
    margin: 167px 0px 0px;
    overflow: auto;
    height: 100%;
  }
}
.flex4_layout_edit {
  position: relative;
  margin: 100px 0px 0px;
  @include md {
    margin: 127px 0px 0px;
    overflow: auto;
    height: 100%;
  }
}
.flex4_item {
  @include flex-column;
  position: relative;
  @include max {
    flex: 0 1 auto;
    display: none;
  }
  @include md {
    display: flex;
  }
}
.block1_layout1 {
  position: relative;
  flex-grow: 1;
  @include md {
    margin: 0px 40px;
  }
  @include xxs {
    margin: 0px 24px;
  }
}
.hero_title1 {
  @include font-face($font_type_0, $color_type_12, -0.8px);
  @include md {
    font-size: 28px;
    text-align: left;
  }
  @include sm {
    font-size: 20px;
  }
}
.hero_title1_layout {
  position: relative;
  flex-grow: 1;
}
.flex4_item1 {
  @include flex-column;
  position: relative;
  @include md {
    display: none;
  }
}
.cover_block7 {
  @include flex-column;
  background-color: $color_type_11;
}
.cover_block7_layout {
  position: relative;
  flex-grow: 1;
  @include md {
    margin: 32px 40px 0px;
  }
  @include xxs {
    margin: 32px 24px 0px;
  }
}
.line6 {
  background: var(--src) center center / cover no-repeat;
}
.line6_layout {
  position: absolute;
  height: 1px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.group_layout3 {
  position: relative;
  overflow: auto hidden;
  height: 50px;
  width: 80%;
  margin: 0px auto;
  @include md {
    width: 100%;
    margin: 0px 0px;
  }
}
.group_layout2 {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flex11 {
  display: flex;
}
.flex11_layout {
  position: relative;
  flex-grow: 1;
}
.group1 {
  @include flex-column;
}
.group1_layout {
  position: relative;
  flex: 0 0 auto;
}
.tab {
  @include font-face($font_type_3, $color_type_12, 0px);
}
.tab_layout {
  position: relative;
  flex-grow: 1;
  margin: 11px 16px 15px;
}
.line {
  background-color: $color_type_12;
}
.line_layout {
  position: absolute;
  height: 2px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.tab1 {
  @include font-face($font_type_3, $color_type_4, 0px);
}
.tab1_layout {
  position: relative;
  flex: 0 0 auto;
  margin: 12px 16px 15px;
}
.tab11 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_3, $color_type_4, 0px);
  white-space: pre-wrap;
}
.tab11_box_layout {
  position: relative;
  flex: 0 0 auto;
  margin: 12px 16px 15px;
}
.flex4_item2 {
  @include flex-column;
  position: relative;
}
.group_layout4 {
  position: relative;
  flex-grow: 1;
  width: 80%;
  margin: 12px auto 32px;
  @include md {
    width: 100%;
    margin: 12px 0px 32px;
  }
}
.flex2 {
  @include flex-column;
}
.flex2_layout {
  position: relative;
  flex-grow: 1;
  @include md {
    margin: 0px 40px;
  }
  @include xxs {
    margin: 0px 24px;
  }
}
.text_body {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: $font_type_4;
  color: $color_type_6;
  text-align: right;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
.text_body_box {
  @include v-center-content;
  justify-content: flex-end;
}
.text_body_box_layout {
  position: relative;
}
.flex3 {
  @include flex-column;
}
.flex3_layout {
  position: relative;
  margin: 32px 0px 0px;
}
.text_body1 {
  @include v-center-content;
  @include font-face($font_type_5, $color_type_1, 0px);
}
.text_body1_layout {
  position: relative;
}
.flex_item {
  @include flex-column;
  position: relative;
}
.cover_block9 {
  @include flex-column;
}
.cover_block9_layout {
  position: relative;
  flex-grow: 1;
  width: 58.64%;
  margin: 12px 41.36% 0px 0%;
  @include xs {
    width: 100%;
    margin: 12px 0px 0px;
  }
}
.cover_image {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.cover_image_layout {
  position: relative;
}
.flex6 {
  @include flex-column;
  width: 100%;
  height: 100%;
}
.small_paragraph_body {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: $font_type_7;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
.small_paragraph_body_box {
  @include h-center-content;
}
.small_paragraph_body_box_layout {
  position: relative;
}
.cover_block8 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 16px 16px 16px 16px;
}
.cover_block8_layout {
  position: absolute;
  margin: 16px auto auto;
  left: 0;
  right: 0;
  top: 16px;
  width: max-content;
  padding: 0px 26px;
}
.text_body2 {
  @include h-center-content;
  font: $font_type_5;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body2_layout {
  position: relative;
  flex-grow: 1;
  width: 56px;
  min-width: 56px;
  margin: 6px auto;
}
.flex7 {
  @include flex-column;
}
.flex7_layout {
  position: relative;
  margin: 32px 0px 0px;
}
.cover_block11 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.cover_block11_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex8 {
  display: flex;
}
.flex8_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 16px;
}
.text_body3 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_6, 0px);
}
.text_body3_layout {
  position: relative;
  flex: 0 0 auto;
}
.flex_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.small_paragraph_body1 {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type_7;
  color: $color_type_3;
  text-align: right;
  letter-spacing: 0px;
}
.small_paragraph_body1_layout {
  position: relative;
  flex: 0 0 auto;
  margin: 0px 0px 2px;
}
.flex9 {
  @include flex-column;
}
.flex9_layout {
  position: relative;
  margin: 32px 0px 0px;
}
.cover_block12 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.cover_block12_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex61 {
  @include flex-column;
}
.flex61_layout {
  position: relative;
  flex-grow: 1;
  margin: 11px 16px;
}
.text_body4 {
  @include font-face($font_type_4, $color_type_6, 0px);
}
.text_body4_layout {
  position: relative;
}
.text_body5 {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type_8;
  color: $color_type_3;
  text-align: right;
  letter-spacing: 0px;
}
.text_body5_layout {
  position: relative;
  height: min-content;
}
.flex10 {
  @include flex-column;
}
.flex10_layout {
  position: relative;
  margin: 32px 0px 0px;
}
.flex12 {
  display: flex;
}
.flex12_layout {
  position: relative;
}
.flex_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body1_layout1 {
  position: relative;
  flex-grow: 1;
}
.flex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 509px;
  @include md {
    flex: 1 1 509px;
  }
}
.flex_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body31 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_4, 0px);
}
.text_body31_layout {
  position: relative;
  flex-grow: 1;
}
.cover_block16 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.cover_block16_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex13 {
  display: flex;
}
.flex13_layout {
  position: relative;
  flex-grow: 1;
  margin: 7px 16px;
}
.flex51 {
  display: flex;
}
.flex51_layout {
  position: relative;
  flex: 0 0 auto;
}
.cover_block14 {
  @include flex-column;
  background-color: $color_type_9;
  border-radius: 13px 13px 13px 13px;
}
.cover_block14_layout {
  position: relative;
  flex: 0 0 auto;
}
.flex14 {
  display: flex;
}
.flex14_layout {
  position: relative;
  flex-grow: 1;
  margin: 4px 10px 4px 12px;
}
.small_text_body {
  @include font-face($font_type_7, $color_type_13, 0px);
}
.small_text_body_layout {
  position: relative;
  flex: 0 0 auto;
}
.flex_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.icon1 {
  background: var(--src) center center / cover no-repeat;
}
.icon1_layout {
  position: relative;
  flex: 0 0 auto;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin: 1px 0px;
}
.flex5_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex5_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.cover_block15 {
  @include flex-column;
  background-color: $color_type_9;
  border-radius: 13px 13px 13px 13px;
}
.cover_block15_layout {
  position: relative;
  flex-grow: 1;
  min-width: 0px;
  width: fit-content;
}
.flex15 {
  display: flex;
}
.flex15_layout {
  position: relative;
  flex-grow: 1;
  min-width: 0px;
  width: fit-content;
  margin: 4px 10px 4px 12px;
}
.flex_spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.icon {
  background: var(--src) center center / cover no-repeat;
}
.icon_layout {
  position: relative;
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 1px 0px;
}
.flex16 {
  @include flex-column;
}
.flex16_layout {
  position: relative;
  margin: 32px 0px 0px;
}
.flex17 {
  display: flex;
}
.flex17_layout {
  position: relative;
}
.text_body1_layout2 {
  position: relative;
  flex: 0 0 auto;
}
.text_body32 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_4, 0px);
}
.text_body32_layout {
  position: relative;
  flex: 0 0 auto;
}
.cover_block13 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.cover_block13_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.text_body7 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_4, $color_type_6, 0px);
  white-space: pre-wrap;
}
.text_body7_box {
  @include v-center-content;
}
.text_body7_box_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 16px;
}
.flex_item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 104px;
}
.cover_block21 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.cover_block21_layout {
  position: relative;
  flex-grow: 1;
  margin: 32px 0px 0px;
}
.flex18 {
  @include flex-column;
}
.flex18_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px;
}
.flex19 {
  display: flex;
}
.flex19_layout {
  position: relative;
}
.highlights2 {
  @include v-center-content;
  @include font-face($font_type_3, $color_type_14, 0px);
}
.highlights2_layout {
  position: relative;
  flex: 1 1 auto;
}
.flex_spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
}
.image1 {
  background: var(--src) center center / cover no-repeat;
}
.image1_layout {
  position: relative;
  flex: 0 0 auto;
  height: 24px;
  width: 44px;
  min-width: 44px;
}
.flex20 {
  display: flex;
}
.flex20_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.text_body41 {
  @include font-face($font_type_4, $color_type_3, 0px);
}
.text_body41_layout {
  position: relative;
}
.flex_spacer5 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 6px;
}
.icon21 {
  background: var(--src) center center / cover no-repeat;
}
.icon21_layout {
  position: relative;
  flex: 0 0 auto;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.flex_item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 1759px;
}
.block15 {
  @include flex-column;
}
.block15_layout {
  position: relative;
  flex-grow: 1;
  margin: 32px 0px 0px;
}
.block17 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.block17_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex21 {
  @include flex-column;
}
.flex21_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px;
  @include tn {
    margin: 16px;
  }
}
.flex22 {
  display: flex;
}
.flex22_layout {
  position: relative;
  @include tn {
    margin: 0px;
  }
}
.flex_item5 {
  @include flex-column;
  position: relative;
  flex: 1 1 587px;
}
.paragraph_body {
  @include font-face($font_type_4, $color_type_14, -0.2px);
}
.paragraph_body_layout {
  position: relative;
  flex-grow: 1;
}
.flex_spacer6 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}
.image1_layout1 {
  position: relative;
  flex: 0 0 auto;
  height: 24px;
  width: 44px;
  min-width: 44px;
  margin: 0px 0px 16px;
}
.flex23 {
  @include flex-column;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px;
}
.flex23_layout {
  position: relative;
  margin: 24px 0px 0px;
}
.cover_block17 {
  @include flex-column;
  background-color: $color_type_11;
  border-radius: 16px 16px 0px 0px;
}
.cover_block17_layout {
  position: relative;
}
.flex24 {
  @include flex-column;
}
.flex24_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px;
  @include tn {
    margin: 12px;
  }
}
.highlights2_layout1 {
  position: relative;
}
.text_body21 {
  @include font-face($font_type_4, $color_type_3, 0px);
}
.text_body21_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.cover_block2 {
  @include flex-column;
  background-color: $color_type_11;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.cover_block2_layout {
  position: relative;
  width: 86px;
  min-width: 86px;
  margin: 12px 0px 0px;
}
.text_body33 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
}
.text_body33_layout {
  position: relative;
  flex-grow: 1;
  margin: 6px 26.5px;
}
.line4 {
  background-color: $color_type_7;
}
.line4_layout {
  position: relative;
  height: 1px;
}
.cover_block18 {
  @include flex-column;
  background-color: $color_type_11;
}
.cover_block18_layout {
  position: relative;
}
.flex25 {
  @include flex-column;
}
.flex25_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px;
  @include tn {
    margin: 12px;
  }
}
.highlights2_layout2 {
  position: relative;
}
.text_body22 {
  @include font-face($font_type_4, $color_type_3, 0px);
}
.text_body22_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.cover_block3 {
  @include flex-column;
  background-color: $color_type_11;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.cover_block3_layout {
  position: relative;
  width: 86px;
  min-width: 86px;
  margin: 12px 0px 0px;
}
.text_body34 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
}
.text_body34_layout {
  position: relative;
  flex-grow: 1;
  margin: 6px 26.5px;
}
.flex_spacer7 {
  @include flex-column;
  position: relative;
}
.cover_block19 {
  @include flex-column;
  background-color: $color_type_11;
  border-radius: 0px 0px 16px 16px;
}
.cover_block19_layout {
  position: relative;
}
.flex26 {
  @include flex-column;
}
.flex26_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px;
  @include tn {
    margin: 12px;
  }
}
.highlights2_layout3 {
  position: relative;
}
.text_body23 {
  @include font-face($font_type_4, $color_type_3, 0px);
}
.text_body23_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.cover_block4 {
  @include flex-column;
  background-color: $color_type_11;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.cover_block4_layout {
  position: relative;
  width: 86px;
  min-width: 86px;
  margin: 12px 0px 0px;
}
.text_body35 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
}
.text_body35_layout {
  position: relative;
  flex-grow: 1;
  margin: 6px 26.5px;
}
.cover_block25 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.cover_block25_layout {
  position: relative;
  margin: 24px 0px 0px;
}
.flex81 {
  @include flex-column;
}
.flex81_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px 0px;
}
.flex27 {
  @include flex-column;
}
.flex27_layout {
  position: relative;
  margin: 0px 24px;
  @include tn {
    margin: 0px 12px;
  }
}
.highlights2_layout4 {
  position: relative;
}
.text_body24 {
  @include font-face($font_type_4, $color_type_3, 0px);
}
.text_body24_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 16px;
  column-gap: 16px;
}
.list_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.cover_block6 {
  @include flex-column;
  background-color: #fff;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block6_layout {
  position: relative;
  flex: 1 1 187px;
}
.flex28 {
  @include flex-column;
}
.flex28_layout {
  position: relative;
  flex-grow: 1;
  margin: 28.24px 16px;
}
.small_text_body1 {
  @include font-face($font_type_7, $color_type_14, 0px);
}
.small_text_body1_layout {
  position: relative;
}
.text_body8 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_6, 0px);
}
.text_body8_layout {
  position: relative;
  margin: 8px 0px 0px;
}
.icon1_layout1 {
  position: absolute;
  top: 7px;
  height: 6px;
  width: 6px;
  right: 7px;
}
.icon11 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(90deg);
}
.icon11_layout {
  position: absolute;
  top: 7px;
  height: 6px;
  left: 7px;
  width: 6px;
}
.icon12 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(180deg);
}
.icon12_layout {
  position: absolute;
  height: 6px;
  bottom: 7px;
  left: 7px;
  width: 6px;
}
.icon1_layout2 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  width: 6px;
  right: 7px;
}
.flex29 {
  @include flex-column;
}
.flex29_layout {
  position: relative;
  flex-grow: 1;
  margin: 29px 16px;
}
.small_text_body2 {
  @include font-face($font_type_7, $color_type_14, 0px);
}
.small_text_body2_layout {
  position: relative;
}
.text_body9 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_6, 0px);
}
.text_body9_layout {
  position: relative;
  margin: 14px 0px 0px;
}
.icon1_layout3 {
  position: absolute;
  top: 7px;
  height: 6px;
  width: 6px;
  right: 7px;
}
.icon13 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(90deg);
}
.icon13_layout {
  position: absolute;
  top: 7px;
  height: 6px;
  left: 7px;
  width: 6px;
}
.icon14 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(180deg);
}
.icon14_layout {
  position: absolute;
  height: 6px;
  bottom: 7px;
  left: 7px;
  width: 6px;
}
.icon1_layout4 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  width: 6px;
  right: 7px;
}
.cover_block91 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block91_layout {
  position: relative;
  flex: 1 1 187px;
}
.flex30 {
  @include flex-column;
}
.flex30_layout {
  position: relative;
  flex-grow: 1;
  margin: 29px 16px;
}
.small_text_body3 {
  @include font-face($font_type_7, $color_type_14, 0px);
}
.small_text_body3_layout {
  position: relative;
}
.text_body10 {
  @include font-face($font_type_4, $color_type_6, 0px);
}
.text_body10_layout {
  position: relative;
  margin: 14px 0px 0px;
}
.icon15 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(90deg);
}
.icon15_layout {
  position: absolute;
  top: 7px;
  height: 6px;
  left: 7px;
  width: 6px;
}
.icon16 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(180deg);
}
.icon16_layout {
  position: absolute;
  top: 7px;
  height: 6px;
  width: 6px;
  right: 7px;
}
.icon1_layout5 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  width: 6px;
  right: 7px;
}
.icon1_layout6 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  left: 7px;
  width: 6px;
}
.cover_block10 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block10_layout {
  position: relative;
  flex: 1 1 187px;
}
.flex31 {
  @include flex-column;
}
.flex31_layout {
  position: relative;
  flex-grow: 1;
  margin: 29px 16px;
}
.small_text_body4 {
  @include font-face($font_type_7, $color_type_14, 0px);
}
.small_text_body4_layout {
  position: relative;
}
.text_body11 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_6, 0px);
}
.text_body11_layout {
  position: relative;
  margin: 14px 0px 0px;
}
.icon17 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(90deg);
}
.icon17_layout {
  position: absolute;
  top: 7px;
  height: 6px;
  left: 7px;
  width: 6px;
}
.icon18 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(180deg);
}
.icon18_layout {
  position: absolute;
  top: 7px;
  height: 6px;
  width: 6px;
  right: 7px;
}
.icon1_layout7 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  width: 6px;
  right: 7px;
}
.icon1_layout8 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  left: 7px;
  width: 6px;
}
.cover_block71 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block71_layout {
  position: relative;
  flex: 1 1 187px;
}
.flex32 {
  @include flex-column;
}
.flex32_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px 16px;
}
.small_text_body5 {
  @include font-face($font_type_7, $color_type_14, 0px);
}
.small_text_body5_layout {
  position: relative;
}
.flex33 {
  display: flex;
}
.flex33_layout {
  position: relative;
  margin: 14px 0px 0px;
}
.group2 {
  @include flex-column;
  border: 1px solid rgb(2, 120, 228);
  border-radius: 4px;
}
.group2_layout {
  position: relative;
  flex: 1 1 auto;
}
.text_body42 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body42_layout {
  position: relative;
  flex-grow: 1;
}
.flex_spacer8 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
}
.group3 {
  @include flex-column;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px;
}
.group3_layout {
  position: relative;
  flex: 1 1 auto;
}
.text_body12 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_6;
  text-align: center;
  letter-spacing: 0px;
}
.text_body12_layout {
  position: relative;
  flex-grow: 1;
  margin: 5px;
}
.icon19 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(90deg);
}
.icon19_layout {
  position: absolute;
  top: 7px;
  height: 6px;
  left: 7px;
  width: 6px;
}
.icon110 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(180deg);
}
.icon110_layout {
  position: absolute;
  top: 7px;
  height: 6px;
  width: 6px;
  right: 7px;
}
.icon1_layout9 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  width: 6px;
  right: 7px;
}
.icon1_layout10 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  left: 7px;
  width: 6px;
}
.cover_block111 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block111_layout {
  position: relative;
  flex: 1 1 187px;
}
.flex34 {
  @include flex-column;
}
.flex34_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px 16px;
}
.small_text_body6 {
  @include font-face($font_type_7, $color_type_14, 0px);
}
.small_text_body6_layout {
  position: relative;
}
.flex_item6 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}
.flex35 {
  display: flex;
}
.flex35_layout {
  position: relative;
  flex-grow: 1;
  margin: 14px 0px 0px;
}
.group4 {
  @include flex-column;
  border: 1px solid rgb(2, 120, 228);
  border-radius: 4px;
}
.group4_layout {
  position: relative;
  flex: 1 1 auto;
}
.text_body43 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body43_layout {
  position: relative;
  flex-grow: 1;
}
.flex_spacer9 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
}
.group5 {
  @include flex-column;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px;
}
.group5_layout {
  position: relative;
  flex: 1 1 auto;
}
.text_body13 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_6;
  text-align: center;
  letter-spacing: 0px;
}
.text_body13_layout {
  position: relative;
  flex-grow: 1;
  margin: 5px;
}
.icon111 {
  background: var(--src) center center / cover no-repeat;
  transform: rotate(90deg);
}
.icon111_layout {
  position: absolute;
  top: 7px;
  height: 6px;
  left: 7px;
  width: 6px;
}
.icon1_layout11 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  width: 6px;
  right: 7px;
}
.icon1_layout12 {
  position: absolute;
  height: 6px;
  bottom: 7px;
  left: 7px;
  width: 6px;
}
.icon22 {
  background: var(--src) center center / cover no-repeat;
}
.icon22_layout {
  position: absolute;
  top: 7px;
  height: 16px;
  width: 16px;
  right: 7px;
}
.cover_block121 {
  @include flex-column;
  background-color: $color_type_11;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 16px 16px 16px 16px;
}
.cover_block121_layout {
  position: relative;
  width: 86px;
  min-width: 86px;
  margin: 12px 0px 0px;
}
.text_body44 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body44_layout {
  position: relative;
  flex-grow: 1;
  margin: 6px 26.5px;
}
.flex8_item {
  @include flex-column;
  position: relative;
  flex: 0 1 1px;
}
.line4_layout1 {
  position: relative;
  height: 1px;
  margin: 24px 0px 0px;
  @include tn {
    margin: 12px 0px 0px;
  }
}
.flex8_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 360px;
}
.flex36 {
  @include flex-column;
}
.flex36_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px 24px 0px;
  @include tn {
    margin: 12px 12px 0px;
  }
}
.highlights2_layout5 {
  position: relative;
}
.text_body25 {
  @include font-face($font_type_4, $color_type_3, 0px);
}
.text_body25_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex37 {
  @include flex-column;
}
.flex37_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.cover_block22 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block22_layout {
  position: relative;
}
.flex38 {
  display: flex;
  @include lg {
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 16px;
  }
}
.flex38_layout {
  position: relative;
  flex-grow: 1;
  margin: 14px 16px;
}
.flex_item7 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    flex: 0 0 calc(100% - 52px);
    order: 1;
  }
  @include md {
    flex: 0 0 calc(100% - 241px);
  }
  @include sm {
    flex: 0 0 calc(100% - 229px);
  }
  @include xs {
    flex: 0 0 calc(100% - 52px);
  }
}
.text_body51 {
  @include font-face($font_type_5, $color_type_14, 0px);
}
.text_body51_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 0px;
}
.flex_spacer10 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
  }
  @include md {
    display: flex;
    flex: 0 0 16px;
    order: 2;
    min-width: unset;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
  }
}
.flex_item8 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include lg {
    flex: 0 0 100%;
    order: 4;
  }
  @include md {
    flex: 0 0 173px;
    order: 3;
  }
  @include xs {
    flex: 0 0 100%;
    order: 4;
  }
}
.flex39 {
  display: flex;
}
.flex39_layout {
  position: relative;
  flex-grow: 1;
}
.flex_item9 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 72px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block13_layout1 {
  position: relative;
  height: min-content;
}
.text_body14 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_6;
  text-align: center;
  letter-spacing: 0px;
}
.text_body14_layout {
  position: relative;
  flex-grow: 1;
  width: 61.11%;
  margin: 10px auto;
}
.flex_spacer11 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item10 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body26 {
  display: flex;
  @include font-face($font_type_4, $color_type_3, 0px);
  @include tn {
    justify-content: center;
    text-align: center;
  }
}
.text_body26_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 0px;
}
.flex_spacer12 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item11 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 67px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block141 {
  @include flex-column;
  background-color: $color_type_9;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 4px 4px 4px 4px;
}
.cover_block141_layout {
  position: relative;
  flex-grow: 1;
}
.text_body61 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_4, 0px);
  @include tn {
    justify-content: center;
    text-align: center;
  }
}
.text_body61_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 16px;
}
.flex_spacer13 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
  }
}
.flex_spacer14 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
    flex: 0 0 16px;
    order: 5;
    min-width: unset;
  }
  @include md {
    display: flex;
    order: 4;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
    flex: 0 0 100%;
    order: 5;
  }
}
.flex_item12 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 36px;
  @include lg {
    flex: 0 0 52px;
    order: 2;
    min-width: unset;
  }
  @include md {
    flex: 0 0 36px;
    order: 5;
  }
  @include xs {
    flex: 0 0 52px;
    order: 2;
  }
}
.icon3 {
  border-width: 0px;
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.icon3_layout {
  position: relative;
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin: 2px 0px;
  @include lg {
    margin: 2px 0px 2px 16px;
  }
  @include md {
    margin: 2px 0px;
  }
  @include xs {
    margin: 2px 0px 2px 16px;
  }
}
.cover_block211 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block211_layout {
  position: relative;
  margin: 16px 0px 0px;
}
.flex40 {
  display: flex;
  @include lg {
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 16px;
  }
}
.flex40_layout {
  position: relative;
  flex-grow: 1;
  margin: 17px 16px;
}
.flex_item13 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    flex: 0 0 calc(100% - 52px);
    order: 1;
  }
  @include md {
    flex: 0 0 calc(100% - 241px);
  }
  @include sm {
    flex: 0 0 calc(100% - 229px);
  }
  @include xs {
    flex: 0 0 calc(100% - 52px);
  }
}
.text_body52 {
  @include font-face($font_type_5, $color_type_14, 0px);
}
.text_body52_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 0px;
}
.flex_spacer15 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
  }
  @include md {
    display: flex;
    flex: 0 0 16px;
    order: 2;
    min-width: unset;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
  }
}
.flex_item14 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include lg {
    flex: 0 0 100%;
    order: 4;
  }
  @include md {
    flex: 0 0 173px;
    order: 3;
  }
  @include xs {
    flex: 0 0 100%;
    order: 4;
  }
}
.flex41 {
  display: flex;
}
.flex41_layout {
  position: relative;
  flex-grow: 1;
}
.flex_item15 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 72px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block13_layout2 {
  position: relative;
  height: min-content;
}
.text_body15 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_6;
  text-align: center;
  letter-spacing: 0px;
}
.text_body15_layout {
  position: relative;
  flex-grow: 1;
  width: 61.11%;
  margin: 10px auto;
}
.flex_spacer16 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item16 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body27 {
  display: flex;
  @include font-face($font_type_4, $color_type_3, 0px);
  @include tn {
    justify-content: center;
    text-align: center;
  }
}
.text_body27_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 0px;
}
.flex_spacer17 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item17 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 67px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block142 {
  @include flex-column;
  background-color: $color_type_9;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 4px 4px 4px 4px;
}
.cover_block142_layout {
  position: relative;
  flex-grow: 1;
}
.text_body62 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_4, 0px);
  @include tn {
    justify-content: center;
    text-align: center;
  }
}
.text_body62_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 16px;
}
.flex_spacer18 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
  }
}
.flex_spacer19 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
    flex: 0 0 16px;
    order: 5;
    min-width: unset;
  }
  @include md {
    display: flex;
    order: 4;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
    flex: 0 0 100%;
    order: 5;
  }
}
.flex_item18 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 36px;
  @include lg {
    flex: 0 0 52px;
    order: 2;
    min-width: unset;
  }
  @include md {
    flex: 0 0 36px;
    order: 5;
  }
  @include xs {
    flex: 0 0 52px;
    order: 2;
  }
}
.flex_item19 {
  @include flex-column;
  position: relative;
  flex: 0 1 74px;
}
.cover_block212 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block212_layout {
  position: relative;
  flex-grow: 1;
  margin: 16px 0px 0px;
}
.flex42 {
  display: flex;
  @include lg {
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 16px;
  }
}
.flex42_layout {
  position: relative;
  flex-grow: 1;
  margin: 17px 16px;
}
.flex_item20 {
  @include flex-column;
  position: relative;
  flex: 0 1 334px;
  @include lg {
    flex: 0 0 calc(100% - 52px);
  }
  @include md {
    flex: 0 0 calc(100% - 241px);
  }
  @include sm {
    flex: 0 0 calc(100% - 229px);
  }
  @include xs {
    flex: 0 0 calc(100% - 52px);
  }
}
.cover_block191 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.cover_block191_layout {
  position: relative;
  flex-grow: 1;
}
.text_body16 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_6, 0px);
}
.text_body16_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 16px;
}
.flex_spacer20 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
  }
  @include md {
    display: flex;
    flex: 0 0 16px;
    order: 2;
    min-width: unset;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
  }
}
.flex_item21 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include lg {
    flex: 0 0 100%;
    order: 4;
  }
  @include md {
    flex: 0 0 173px;
    order: 3;
  }
  @include xs {
    flex: 0 0 100%;
    order: 4;
  }
}
.flex43 {
  display: flex;
}
.flex43_layout {
  position: relative;
  flex-grow: 1;
}
.flex_item22 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 72px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block13_layout3 {
  position: relative;
  height: min-content;
}
.text_body17 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_6;
  text-align: center;
  letter-spacing: 0px;
}
.text_body17_layout {
  position: relative;
  flex-grow: 1;
  width: 61.11%;
  margin: 10px auto;
}
.flex_spacer21 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item23 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body28 {
  display: flex;
  @include font-face($font_type_4, $color_type_3, 0px);
  @include tn {
    justify-content: center;
    text-align: center;
  }
}
.text_body28_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 0px;
}
.flex_spacer22 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item24 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 67px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block181 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.cover_block181_layout {
  position: relative;
  flex-grow: 1;
}
.text_body18 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_6;
  text-align: center;
  letter-spacing: 0px;
}
.text_body18_layout {
  position: relative;
  flex-grow: 1;
  width: 56.72%;
  margin: 10px auto;
}
.flex_spacer23 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
  }
}
.flex_spacer24 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
    flex: 0 0 16px;
    order: 5;
    min-width: unset;
  }
  @include md {
    display: flex;
    order: 4;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
    flex: 0 0 100%;
    order: 5;
  }
}
.flex_item25 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 36px;
  @include lg {
    flex: 0 0 52px;
    order: 2;
    min-width: unset;
  }
  @include md {
    flex: 0 0 36px;
    order: 5;
  }
  @include xs {
    flex: 0 0 52px;
    order: 2;
  }
}
.icon31 {
  background: var(--src) center center / cover no-repeat;
  border-width: 0px;
}
.icon31_layout {
  position: relative;
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin: 2px 0px;
  @include lg {
    margin: 2px 0px 2px 16px;
  }
  @include md {
    margin: 2px 0px;
  }
  @include xs {
    margin: 2px 0px 2px 16px;
  }
}
.cover_block122 {
  @include flex-column;
  background-color: $color_type_11;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 16px 16px 16px 16px;
}
.cover_block122_layout {
  position: relative;
  width: 86px;
  min-width: 86px;
  margin: 12px 0px 0px;
}
.text_body45 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body45_layout {
  position: relative;
  flex-grow: 1;
  margin: 6px 26.5px;
}
.line4_layout2 {
  position: relative;
  height: 1px;
  margin: 24px 0px 0px;
  @include tn {
    margin: 12px 0px 0px;
  }
}
.flex8_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 270px;
}
.flex44 {
  @include flex-column;
}
.flex44_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px 24px 0px;
  @include tn {
    margin: 12px 12px 0px;
  }
}
.highlights2_layout6 {
  position: relative;
}
.text_body29 {
  @include font-face($font_type_4, $color_type_3, 0px);
}
.text_body29_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex45 {
  @include flex-column;
}
.flex45_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex46 {
  display: flex;
  @include lg {
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 16px;
  }
}
.flex46_layout {
  position: relative;
  flex-grow: 1;
  margin: 14px 16px;
}
.flex_item26 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    flex: 0 0 calc(100% - 52px);
    order: 1;
  }
  @include md {
    flex: 0 0 calc(100% - 241px);
  }
  @include sm {
    flex: 0 0 calc(100% - 229px);
  }
  @include xs {
    flex: 0 0 calc(100% - 52px);
  }
}
.text_body53 {
  @include font-face($font_type_5, $color_type_14, 0px);
}
.text_body53_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 0px;
}
.flex_spacer25 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
  }
  @include md {
    display: flex;
    flex: 0 0 16px;
    order: 2;
    min-width: unset;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
  }
}
.flex_item27 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include lg {
    flex: 0 0 100%;
    order: 4;
  }
  @include md {
    flex: 0 0 173px;
    order: 3;
  }
  @include xs {
    flex: 0 0 100%;
    order: 4;
  }
}
.flex47 {
  display: flex;
}
.flex47_layout {
  position: relative;
  flex-grow: 1;
}
.flex_item28 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 72px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block13_layout4 {
  position: relative;
  height: min-content;
}
.text_body19 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_6;
  text-align: center;
  letter-spacing: 0px;
}
.text_body19_layout {
  position: relative;
  flex-grow: 1;
  width: 61.11%;
  margin: 10px auto;
}
.flex_spacer26 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item29 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body210 {
  display: flex;
  @include font-face($font_type_4, $color_type_3, 0px);
  @include tn {
    justify-content: center;
    text-align: center;
  }
}
.text_body210_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 0px;
}
.flex_spacer27 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item30 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 67px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block143 {
  @include flex-column;
  background-color: $color_type_9;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 4px 4px 4px 4px;
}
.cover_block143_layout {
  position: relative;
  flex-grow: 1;
}
.text_body63 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_4, 0px);
  @include tn {
    justify-content: center;
    text-align: center;
  }
}
.text_body63_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 16px;
}
.flex_spacer28 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
  }
}
.flex_spacer29 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
    flex: 0 0 16px;
    order: 5;
    min-width: unset;
  }
  @include md {
    display: flex;
    order: 4;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
    flex: 0 0 100%;
    order: 5;
  }
}
.flex_item31 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 36px;
  @include lg {
    flex: 0 0 52px;
    order: 2;
    min-width: unset;
  }
  @include md {
    flex: 0 0 36px;
    order: 5;
  }
  @include xs {
    flex: 0 0 52px;
    order: 2;
  }
}
.cover_block213 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block213_layout {
  position: relative;
  margin: 16px 0px 0px;
}
.flex48 {
  display: flex;
  @include lg {
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 16px;
  }
}
.flex48_layout {
  position: relative;
  flex-grow: 1;
  margin: 17px 16px;
}
.flex_item32 {
  @include flex-column;
  position: relative;
  flex: 0 1 334px;
  @include lg {
    flex: 0 0 calc(100% - 52px);
  }
  @include md {
    flex: 0 0 calc(100% - 241px);
  }
  @include sm {
    flex: 0 0 calc(100% - 229px);
  }
  @include xs {
    flex: 0 0 calc(100% - 52px);
  }
}
.cover_block192 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.cover_block192_layout {
  position: relative;
  flex-grow: 1;
}
.text_body20 {
  @include v-center-content;
  @include font-face($font_type_4, $color_type_6, 0px);
}
.text_body20_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 16px;
}
.flex_spacer30 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
  }
  @include md {
    display: flex;
    flex: 0 0 16px;
    order: 2;
    min-width: unset;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
  }
}
.flex_item33 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include lg {
    flex: 0 0 100%;
    order: 4;
  }
  @include md {
    flex: 0 0 173px;
    order: 3;
  }
  @include xs {
    flex: 0 0 100%;
    order: 4;
  }
}
.flex49 {
  display: flex;
}
.flex49_layout {
  position: relative;
  flex-grow: 1;
}
.flex_item34 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 72px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block13_layout5 {
  position: relative;
  height: min-content;
}
.text_body30 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_6;
  text-align: center;
  letter-spacing: 0px;
}
.text_body30_layout {
  position: relative;
  flex-grow: 1;
  width: 61.11%;
  margin: 10px auto;
}
.flex_spacer31 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item35 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body211 {
  display: flex;
  @include font-face($font_type_4, $color_type_3, 0px);
  @include tn {
    justify-content: center;
    text-align: center;
  }
}
.text_body211_layout {
  position: relative;
  flex-grow: 1;
  margin: 10px 0px;
}
.flex_spacer32 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include tn;
}
.flex_item36 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 67px;
  @include lg {
    flex: 1 0 auto;
  }
}
.cover_block182 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.cover_block182_layout {
  position: relative;
  flex-grow: 1;
}
.text_body36 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_6;
  text-align: center;
  letter-spacing: 0px;
}
.text_body36_layout {
  position: relative;
  flex-grow: 1;
  width: 56.72%;
  margin: 10px auto;
}
.flex_spacer33 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
  }
}
.flex_spacer34 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    display: none;
    flex: 0 0 16px;
    order: 5;
    min-width: unset;
  }
  @include md {
    display: flex;
    order: 4;
  }
  @include sm {
    flex: 0 0 10px;
  }
  @include xs {
    display: none;
    flex: 0 0 100%;
    order: 5;
  }
}
.flex_item37 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 36px;
  @include lg {
    flex: 0 0 52px;
    order: 2;
    min-width: unset;
  }
  @include md {
    flex: 0 0 36px;
    order: 5;
  }
  @include xs {
    flex: 0 0 52px;
    order: 2;
  }
}
.icon32 {
  background: var(--src) center center / cover no-repeat;
  border-width: 0px;
}
.icon32_layout {
  position: relative;
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin: 2px 0px;
  @include lg {
    margin: 2px 0px 2px 16px;
  }
  @include md {
    margin: 2px 0px;
  }
  @include xs {
    margin: 2px 0px 2px 16px;
  }
}
.cover_block123 {
  @include flex-column;
  background-color: $color_type_11;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 16px 16px 16px 16px;
}
.cover_block123_layout {
  position: relative;
  width: 86px;
  min-width: 86px;
  margin: 12px 0px 0px;
}
.text_body46 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body46_layout {
  position: relative;
  flex-grow: 1;
  margin: 6px 26.5px;
}
.flex4_item3 {
  @include flex-column;
  position: relative;
}
.line6_layout1 {
  position: relative;
  height: 1px;
  margin: 0px 0px 0px 1px;
}
.flex4_item4 {
  @include flex-column;
  position: relative;
}
.group_layout5 {
  position: relative;
  flex-grow: 1;
  width: 80%;
  margin: 32px auto;
  @include md {
    width: 100%;
    margin: 32px 0px;
  }
}
.flex50 {
  @include flex-column;
}
.flex50_layout {
  position: relative;
  flex-grow: 1;
  @include md {
    margin: 0px 40px;
  }
  @include xxs {
    margin: 0px 24px;
  }
}
.text_body110 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_5, $color_type_1, 0px);
  white-space: pre-wrap;
}
.text_body110_box {
  @include v-center-content;
}
.text_body110_box_layout {
  position: relative;
}
.cover_block33 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.cover_block33_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex52 {
  @include flex-column;
}
.flex52_layout {
  position: relative;
  flex-grow: 1;
  margin: 32px;
}
.flex53 {
  @include flex-column;
}
.flex53_layout {
  position: relative;
}
.text_body91 {
  @include font-face($font_type_5, $color_type_12, -0.1px);
}
.text_body91_layout {
  position: relative;
}
.flex54 {
  display: flex;
  @include xs {
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 16px;
  }
}
.flex54_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex_item38 {
  @include flex-column;
  position: relative;
  flex: 1 1 320px;
  @include xs {
    flex: 0 0 100%;
  }
}
.cover_block30 {
  @include flex-column;
  background-color: $color_type_11;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 16px;
}
.cover_block30_layout {
  position: relative;
  flex-grow: 1;
}
.flex55 {
  @include flex-column;
}
.flex55_layout {
  position: relative;
  flex-grow: 1;
  margin: 16px 24px;
}
.flex56 {
  @include flex-column;
}
.flex56_layout {
  position: relative;
  width: 138px;
  min-width: 138px;
  margin: 0px auto;
}
.icon_layout1 {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0px auto;
}
.highlights {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_12;
  text-align: center;
  letter-spacing: 0px;
}
.highlights_layout {
  position: relative;
  margin: 8px 3px 0px;
}
.text_body64 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_12;
  text-align: center;
  letter-spacing: 0px;
}
.text_body64_layout {
  position: relative;
  margin: 8px 0px 0px;
}
.text_body47 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_4, $color_type_3, 0px);
  white-space: pre-wrap;
}
.text_body47_box_layout {
  position: relative;
  margin: 8px 0px 0px;
}
.icon23 {
  background: var(--src) center center / cover no-repeat;
}
.icon23_layout {
  position: absolute;
  top: 12px;
  height: 20px;
  width: 20px;
  right: 12px;
}
.flex_spacer35 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include xs {
    display: none;
  }
}
.flex_item39 {
  @include flex-column;
  position: relative;
  flex: 1 1 320px;
  @include xs {
    flex: 0 0 100%;
  }
}
.cover_block31 {
  @include flex-column;
  background-color: $color_type_8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(78, 161, 236);
  border-radius: 16px;
}
.cover_block31_layout {
  position: relative;
  flex-grow: 1;
}
.flex57 {
  @include flex-column;
}
.flex57_layout {
  position: relative;
  flex-grow: 1;
  margin: 16px 24px;
}
.flex58 {
  @include flex-column;
}
.flex58_layout {
  position: relative;
  width: 61.62%;
  margin: 0px auto;
}
.icon_layout2 {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0px auto;
}
.highlights3 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.highlights3_layout {
  position: relative;
  margin: 8px 0px 0px;
}
.text_body54 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body54_layout {
  position: relative;
  margin: 8px 0px 0px;
}
.text_body48 {
  @include font-face($font_type_4, $color_type_3, 0px);
}
.text_body48_layout {
  position: relative;
  margin: 8px 0px 0px;
}
.icon24 {
  background: var(--src) center center / cover no-repeat;
}
.icon24_layout {
  position: absolute;
  top: 12px;
  height: 20px;
  width: 20px;
  right: 11px;
}
.flex59 {
  @include flex-column;
}
.flex59_layout {
  position: relative;
  margin: 24px 0px 0px;
}
.text_body92 {
  @include font-face($font_type_5, $color_type_12, 0px);
}
.text_body92_layout {
  position: relative;
}
.cover_block32 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.cover_block32_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex60 {
  @include flex-column;
}
.flex60_layout {
  position: relative;
  flex-grow: 1;
  margin: 16px 24px;
}
.flex62 {
  display: flex;
}
.flex62_layout {
  position: relative;
}
.flex63 {
  display: flex;
}
.flex63_layout {
  position: relative;
  flex: 0 0 auto;
}
.icon33 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.icon33_layout {
  position: relative;
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
  min-width: 40px;
  @include tn {
    width: 25px;
    min-width: 25px;
    margin: 0px;
  }
}
.flex_spacer36 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.icon34 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.icon34_layout {
  position: relative;
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
  min-width: 40px;
  @include tn {
    width: 25px;
    min-width: 25px;
    margin: 0px;
  }
}
.flex_spacer37 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.icon35 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.icon35_layout {
  position: relative;
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
  min-width: 40px;
  @include tn {
    width: 25px;
    min-width: 25px;
    margin: 0px;
  }
}
.flex_spacer38 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.icon36 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.icon36_layout {
  position: relative;
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
  min-width: 40px;
  @include tn {
    width: 25px;
    min-width: 25px;
    margin: 0px;
  }
}
.text_body2_layout1 {
  position: relative;
  flex: 0 0 auto;
  height: 20px;
}
.small_text_body11 {
  @include font-face($font_type_7, $color_type_5, 0px);
}
.small_text_body11_layout {
  position: relative;
  margin: 8px 0px 0px;
}
.paragraph_body1 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_6, $color_type_14, 0px);
  white-space: pre-wrap;
}
.paragraph_body1_box_layout {
  position: relative;
  margin: 24px 0px 0px;
}
.flex64 {
  @include flex-column;
}
.flex64_layout {
  position: relative;
  margin: 24px 0px 0px;
}
.text_body93 {
  @include font-face($font_type_5, $color_type_12, 0px);
}
.text_body93_layout {
  position: relative;
}
.flex65 {
  @include flex-column;
}
.flex65_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.flex66 {
  display: flex;
}
.flex66_layout {
  position: relative;
}
.icon_layout3 {
  position: relative;
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0px 0px 16px;
}
.flex_spacer39 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.text_body55 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_4, $color_type_5, 0px);
  white-space: pre-wrap;
}
.text_body55_box_layout {
  position: relative;
  flex: 1 1 auto;
}
.flex67 {
  display: flex;
}
.flex67_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.icon_layout4 {
  position: relative;
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0px 0px 16px;
}
.flex_spacer40 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.text_body56 {
  @include font-face($font_type_4, $color_type_5, 0px);
}
.text_body56_layout {
  position: relative;
  flex: 1 1 auto;
}
.flex4_item5 {
  @include flex-column;
  position: relative;
}
.line6_layout2 {
  position: relative;
  height: 1px;
  margin: 0px 0px 0px 1px;
}
.flex4_item6 {
  @include flex-column;
  position: relative;
}
.group_layout6 {
  position: relative;
  flex-grow: 1;
  width: 80%;
  margin: 32px auto 24px;
  @include md {
    width: 100%;
    margin: 32px 0px 24px;
  }
}
.flex68 {
  @include flex-column;
}
.flex68_layout {
  position: relative;
  flex-grow: 1;
  @include md {
    margin: 0px 40px;
  }
  @include xxs {
    margin: 0px 24px;
  }
}
.text_body111 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_5, $color_type_1, 0px);
  white-space: pre-wrap;
}
.text_body111_box {
  @include v-center-content;
}
.text_body111_box_layout {
  position: relative;
}
.paragraph_body11 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_6, $color_type_3, 0px);
  white-space: pre-wrap;
}
.paragraph_body11_box_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.cover_block40 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px;
}
.cover_block40_layout {
  position: relative;
  margin: 32px 0px 0px;
}
.flex69 {
  @include flex-column;
}
.flex69_layout {
  position: relative;
  flex-grow: 1;
  margin: 32px;
}
.flex_item40 {
  @include flex-column;
  position: relative;
  flex: 0 1 156px;
}
.flex70 {
  @include flex-column;
}
.flex70_layout {
  position: relative;
  flex-grow: 1;
}
.highlights2_layout7 {
  position: relative;
}
.text_body49 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_4, $color_type_3, 0px);
  white-space: pre-wrap;
}
.text_body49_box_layout {
  position: relative;
  margin: 16px 0px 0px;
}
.text_body49_span0 {
  @include font-face($font_type_9, $color_type_0, 0px);
}
.text_body49_span1 {
  @include font-face($font_type_10, $color_type_0, 0px);
}
.cover_block34 {
  @include flex-column;
  background-color: $color_type_11;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.cover_block34_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 8px 0px 0px;
}
.text_body57 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body57_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 6px 27px;
}
.flex71 {
  @include flex-column;
}
.flex71_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.small_paragraph_body2 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_7, $color_type_3, 0px);
  white-space: pre-wrap;
}
.small_paragraph_body2_box_layout {
  position: relative;
}
.flex7_item {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}
.flex72 {
  display: flex;
}
.flex72_layout {
  position: relative;
  flex-grow: 1;
}
.small_paragraph_body3 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_7, $color_type_3, 0px);
  white-space: pre-wrap;
}
.small_paragraph_body3_box_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.small_paragraph_body4 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_7, $color_type_3, 0px);
  white-space: pre-wrap;
}
.small_paragraph_body4_box_layout {
  position: relative;
  flex: 1 1 auto;
}
.flex73 {
  display: flex;
}
.flex73_layout {
  position: relative;
  flex-grow: 1;
}
.small_paragraph_body5 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_7, $color_type_3, 0px);
  white-space: pre-wrap;
}
.small_paragraph_body5_box_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.small_paragraph_body6 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_7, $color_type_3, 0px);
  white-space: pre-wrap;
}
.small_paragraph_body6_box_layout {
  position: relative;
  flex: 1 1 auto;
}
.flex74 {
  display: flex;
}
.flex74_layout {
  position: relative;
  flex-grow: 1;
}
.small_paragraph_body7 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_7, $color_type_3, 0px);
  white-space: pre-wrap;
}
.small_paragraph_body7_box_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.flex_item41 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.small_paragraph_body8 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_7, $color_type_3, 0px);
  white-space: pre-wrap;
}
.small_paragraph_body8_box_layout {
  position: relative;
  flex-grow: 1;
  min-width: 0px;
  width: fit-content;
}
.flex75 {
  display: flex;
}
.flex75_layout {
  position: relative;
  margin: 12px 0px 0px;
}
.icon25 {
  background: var(--src) center center / cover no-repeat;
}
.icon25_layout {
  position: relative;
  flex: 0 0 auto;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.flex_spacer41 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.small_text_body12 {
  @include font-face($font_type_7, $color_type_5, 0px);
}
.small_text_body12_layout {
  position: relative;
  flex: 1 1 auto;
  margin: 1px 0px;
}
.cover_block42 {
  @include flex-column;
  background-color: $color_type_10;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.cover_block42_layout {
  position: relative;
  margin: 32px 0px 0px;
}
.flex76 {
  @include flex-column;
}
.flex76_layout {
  position: relative;
  flex-grow: 1;
  margin: 24px;
}
.highlights2_layout8 {
  position: relative;
}
.text_body410 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_4, $color_type_3, 0px);
  white-space: pre-wrap;
}
.text_body410_box_layout {
  position: relative;
  margin: 16px 0px 0px;
}
.text_body411 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type_8, $color_type_3, 0px);
  white-space: pre-wrap;
}
.text_body411_box_layout {
  position: relative;
  height: min-content;
}
.text_body58 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body58_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 6px 27px;
}
.flex4_spacer {
  @include flex-column;
  position: relative;
}
.cover_block5 {
  @include flex-column;
  background-color: $color_type_11;
}
.cover_block5_layout {
  position: relative;
  height: min-content;
}
.line7 {
  background: var(--src) center center / cover no-repeat;
}
.line7_layout {
  position: absolute;
  top: 0px;
  height: 1px;
  left: 0px;
  right: -2px;
}
.group_layout7 {
  position: relative;
  flex-grow: 1;
  width: 79.96%;
  margin: 24px auto;
  @include md {
    width: 100%;
    margin: 24px 0px;
  }
  @include tn {
    margin: 20px 0px;
  }
}
.flex77 {
  display: flex;
}
.flex77_layout {
  position: relative;
  flex-grow: 1;
  @include md {
    margin: 0px 40px;
  }
  @include xxs {
    margin: 0px 24px;
  }
  @include tn {
    margin: 0px 12px;
  }
}
.flex_item42 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xxs {
    min-width: unset;
    display: none;
  }
}
.cover_block41 {
  @include flex-column;
  background-color: $color_type_11;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 16px 16px 16px 16px;
}
.cover_block41_layout {
  position: relative;
  flex-grow: 1;
  margin: 4px 0px;
}
.text_body59 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
}
.text_body59_layout {
  position: relative;
  flex-grow: 1;
  margin: 5px 15px;
}
.flex_item43 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include max {
    min-width: unset;
    display: none;
  }
}
.icon_layout5 {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.flex_spacer42 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg;
}
.flex_item44 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xs {
    min-width: unset;
    display: none;
  }
}
.flex78 {
  display: flex;
}
.flex78_layout {
  position: relative;
  flex-grow: 1;
  margin: 8px 0px;
}
.flex_item45 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}
.icon_layout6 {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex_spacer43 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include lg {
    display: none;
  }
  @include md {
    display: flex;
  }
}
.flex_item46 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include lg {
    min-width: unset;
    display: none;
  }
  @include md {
    display: flex;
  }
}
.highlights4 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0px;
}
.highlights4_layout {
  position: relative;
  flex-grow: 1;
}
.flex_spacer44 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
  @include tn {
    min-width: 5px;
  }
}
.flex_item47 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.flex79 {
  display: flex;
}
.flex79_layout {
  position: relative;
  flex-grow: 1;
}
.flex_item48 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.group6 {
  @include flex-column;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 20px;
}
.group6_layout {
  position: relative;
  flex-grow: 1;
}
.highlights3_layout1 {
  position: relative;
  flex-grow: 1;
  margin: 7px 31px;
}
.flex_spacer45 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
  @include tn {
    min-width: 5px;
  }
}
.flex_item49 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.cover_block35 {
  @include flex-column;
  background-color: $color_type_5;
  border-radius: 20px 20px 20px 20px;
}
.cover_block35_layout {
  position: relative;
  flex-grow: 1;
}
.highlights5 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_11;
  text-align: center;
  letter-spacing: 0px;
}
.highlights5_layout {
  position: relative;
  flex-grow: 1;
  margin: 8px 32px;
}
.cover_block20 {
  @include flex-column;
}
.cover_block20_layout {
  position: relative;
  flex-grow: 1;
}
