/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 600 20px/1.2 "Montserrat", Helvetica, Arial, serif;
$font_type1: 500 14px/1.42 "Montserrat", Helvetica, Arial, serif;

.section3_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  width: 100%;
  margin: 0px 0px;
}
.section3 {
  @include flex-column;
}
.block16 {
  @include flex-column;
  background-color: rgb(25, 25, 25);
}
.block16_layout {
  position: relative;
  height: min-content;
}
.block34 {
  @include flex-column;
}
.block34_layout {
  position: relative;
  height: min-content;
  margin: 59px 32px;
}
.flex29 {
  @include flex-column;
}
.flex29_layout {
  position: relative;
  height: min-content;
  width: 100%;
  max-width: 1376px;
  margin: 0px auto;
  @include lg {
    width: 69.35%;
  }
  @include sm {
    width: 86.04%;
  }
  @include xxs {
    width: 87.73%;
  }
}
.flex29_item {
  @include flex-column;
  position: relative;
}
.subtitle1 {
  @include font-face($font_type0, rgb(198, 200, 209), 0px);
}
.subtitle1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px auto;
}
.paragraph_body2 {
  @include font-face($font_type1, rgb(140, 143, 155), 0px);
}
.paragraph_body2_layout {
  position: relative;
  height: min-content;
  width: 89.97%;
  margin: 12px auto 0px;
  @include lg {
    width: 100%;
    margin: 12px 0px 0px;
  }
}
.group {
  @include flex-column;
}
.group_layout {
  position: relative;
  height: min-content;
  margin: 24px 56px 5px;
  @include lg {
    margin: 24px 0px 5px;
  }
}
.block12 {
  background: var(--src) center center / contain no-repeat;
}
.block12_layout {
  position: absolute;
  height: 40px;
  bottom: 92px;
  left: -56px;
  width: 40px;
  @include lg {
    display: none;
  }
}
.block27 {
  display: flex;
}
.block27_layout {
  position: relative;
  height: min-content;
  width: 100%;
  margin: 0px auto;
  @include lg {
    margin: 0px 0px;
  }
}
.block27_item {
  @include flex-column;
  position: relative;
  flex: 0 1 304px;
}
.flex30 {
  @include flex-column;
}
.flex30_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 20px;
}
.image10 {
  border-radius: 16px 16px 16px 16px;
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.image10_layout {
  position: relative;
}
.text_body4 {
  @include font-face($font_type1, rgb(249, 249, 250), 0px);
}
.text_body4_layout {
  position: relative;
  height: min-content;
  margin: 12px 16px 0px;
}
.flex30_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xxs {
    display: none;
  }
  @include tn {
    flex: 0 1 auto;
  }
}
.flex30_item {
  @include flex-column;
  position: relative;
  flex: 0 1 304px;
  @include xxs {
    display: none;
  }
}
.flex31 {
  @include flex-column;
}
.flex31_layout {
  position: relative;
  height: min-content;
}
.flex31_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    display: none;
  }
}
.flex31_item {
  @include flex-column;
  position: relative;
  flex: 0 1 304px;
  @include xs {
    display: none;
  }
}
.text_body41 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type1, rgb(249, 249, 250), 0px);
  white-space: pre-wrap;
}
.text_body41_box_layout {
  position: relative;
  height: min-content;
  margin: 12px 16px 0px;
}
.flex31_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include lg {
    flex: 0 1 auto;
  }
  @include sm {
    display: none;
  }
}
.flex31_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 304px;
  @include lg;
  @include sm {
    display: none;
  }
}
.block12_layout1 {
  position: absolute;
  height: 40px;
  bottom: 92px;
  width: 40px;
  right: -56px;
  @include lg {
    display: none;
  }
}
