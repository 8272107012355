.reppl-cover-wrap:hover .change-img-btn {
  display: block;
}
.reppl-cover-wrap .change-img-btn {
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 1.5rem;
  z-index: 1;
}
.reppl-cover-wrap {
  background-position: center;
  background-size: cover;
  height: 8.813rem;
}

.reppl-cover-content-loading {
  min-width: 650px;
}
.reppl-title {
  line-height: 44px;
}
.reppl-container,
.reppl-container-width {
  max-width: 1376px;
}
.reppl-container .side-widget {
  width: 18.75rem;
}
.space-topics-conteiner {
  width: 100%;
  max-width: 1376px;
}
.episode-card {
  width: 18.75rem;
  height: 12.5rem;
}
.reppl-collection .w-56 {
  width: 11rem;
}
.reppl-collection .menu-list {
  text-indent: -0.5rem;
}
.reppl-collection .collection-card {
  width: 12rem;
  height: 12rem;
}
.picked-episode-cards {
  height: 23.563rem;
}

.reppl-nav-fixed {
  z-index: 1001;
  transition: opacity 0.3s, top 0.3s;
  -webkit-transition: opacity 0.3s, top 0.3s;
}
.reppl-nav-fixed .link-active {
  color: #242526;
  background: #f3f3f4;
  padding: 11px 22px;
  border-bottom: none !important;
}
.corver-edit {
  z-index: 1000;
}
.reppl-banner {
  height: 300px;
}
.about-edit-wrap {
  width: 1056px;
  min-height: 898px;
}
/* desktop */
@media (min-width: 768px) {
  .reppl-cover-wrap {
    height: auto;
  }
  .reppl-nav-wrap .reppl-nav {
    width: 1055px;
  }
  .reppl-nav-fixed .reppl-nav {
    width: 1280px;
  }
  .reppl-nav-fixed .text-xl {
    font-size: 14px;
  }
  .reppl-nav-wrap .bg-blur {
    width: 100%;
    height: 67px;
    backdrop-filter: blur(5px);
  }
  .reppl-cover {
    height: 20.875rem;
  }
  .reppl-cover-content {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .open-episode-img {
    width: 16.25rem;
    height: 10.625rem;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .pin-creations {
    width: 1350px;
    margin-left: -34px;
  }
  .hover-arrow:hover {
    background-color: unset !important;
  }
  .picked-episode-card {
    height: 377px !important;
  }
}
.open-episode-rating {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.open-episode .content {
  max-width: 440px;
}
.management-wrap {
  height: 15.625rem;
}
.publish-block {
  width: 314px;
}
.publish-block button:disabled {
  color: #9b9fa4;
}
.open-episode .content {
  max-width: 440px;
}
.new-card {
  padding-top: 4.585rem;
}
.new-card .border-dashed {
  border-radius: 23px;
}
.guidelines .full-banner {
  margin-top: 0;
}
.guidelines .reppl-banner {
  height: 142px;
}
.drfat-overlay {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.88) 0%,
    #ffffff 50.08%
  );
}
#rule-editor .cdx-list {
  padding-left: 0 !important;
}
.guidelines ul li {
  padding: 0;
  margin-bottom: 24px;
}
.guidelines ul {
  padding: 0;
}

.guidelines ol li {
  padding: 0;
  margin-bottom: 24px;
}
.guidelines ol {
  padding: 0;
}
