a {
  text-decoration: none;
}
:focus,
a:focus,
button:focus {
  outline: none;
}
.align-super{
  vertical-align:super
}
.width-960 {
  width: 960px;
}
.w-7\.5 {
  width: 1.875rem;
}
.w-25 {
  width: 6.25rem;
}
.w-30 {
  width: 7.5rem;
}
.h-4\.5 {
  height: 1.125rem;
}
.h-13 {
  height: 3.25rem;
}
.h-15 {
  height: 3.75rem;
}
.h-50 {
  height: 12.5rem;
}
.h-fit {
  height: fit-content;
}
.z-top {
  z-index: 999;
}
.text-3\.5xl {
  font-size: 2rem;
}
.text-6\.5xl {
  font-size: 4.5rem;
}
.overflow-initial {
  overflow: initial !important;
}
.mt-4\.5 {
  margin-top: 1.125rem;
}
.pt-4\.5 {
  padding-top: 1.125rem;
}
.pb-4\.5 {
  padding-bottom: 1.125rem;
}
.p-4\.5 {
  padding: 1.125rem;
}
.px-4\.5 {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}
.py-4\.5 {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}
.mb-13{
  margin-bottom: 3.375rem
}
.pb-9\.5{
  padding-bottom: 2.375rem;
}
.leading-4\.5 {
  line-height: 1.125rem;
}
.mt-13 {
  margin-top: 3.125rem;
}
/* desktop */
@media (min-width: 768px) {
  .md\:text-3\.5xl {
    font-size: 2rem;
  }
  .md\:text-4\.5xl {
    font-size: 2.5rem;
  }
  .md\:text-6\.5xl {
    font-size: 4.5rem;
  }
  .md\:mb-13{
    margin-bottom: 3.375rem
  }
  .md\:w-18{
    width: 4.5rem
  }
  .md\:w-22{
    width: 5.5rem
  }
  .md\:w-74{
    width: 18.75rem
  }
  .md\:h-74{
    height: 18.75rem
  }
  .md\:w-160{
    width: 39rem
  }
  .md\:h-18{
    height: 4.5rem
  }
}
