/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";
@import "global";

$font_type0: 600 24px/1.25 "Montserrat",
Helvetica,
Arial,
serif;
$font_type1: 500 16px/1.5 "Montserrat",
Helvetica,
Arial,
serif;
$font_type2: 500 20px/1.6 "Montserrat",
Helvetica,
Arial,
serif;
$font_type3: 500 12px/1.5 "Montserrat",
Helvetica,
Arial,
serif;
$font_type4: 500 14px/1.42 "Montserrat",
Helvetica,
Arial,
serif;

.section2_layout {
	position: relative;
	height: min-content;
}

.section2 {
	@include flex-column;
}

.block38 {
	@include flex-column;
}

.block38_layout {
	position: relative;
	height: min-content;
	margin: 40px 40px 64px;

	@include xs {
		margin: 40px 24px 64px;
	}
}

.flex5 {
	@include flex-column;
}

.flex5_layout {
	position: relative;
	height: min-content;
	width: 99.85%;
	max-width: 1376px;
	margin: 0px auto;
}

.medium_title {
	@include font-face($font_type0, rgb(79, 82, 86), -0.24px);
}

.medium_title_layout {
	position: relative;
	height: min-content;
}

.highlights1 {
	@include font-face($font_type1, rgb(79, 82, 86), 0px);
}

.highlights1_layout {
	position: relative;
	height: min-content;
	margin: 24px 20px 0px 0%;

	@include md {
		margin: 24px 10px 0px 0%;
	}

	@include sm {
		width: 100%;
		margin: 24px 0px 0px;
	}
}

.grid2 {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	row-gap: 134px;
	column-gap: 32px;
}

.grid2_layout {
	position: relative;
	height: min-content;
	margin: 40px 0px 12px;
}

.grid2_item {
	@include flex-column;
	position: relative;
	flex: 0 1 320px;


	@include xs {
		display: none;
	}
}

.grid2_item_viewer {
	display: none;
}

.block3 {
	@include flex-column;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
}

.block3_layout {
	position: relative;
	height: min-content;
}

.background {
	width: 100%;
	height: auto;
	vertical-align: top;
	object-fit: contain;
	object-position: center top;
}

.background_layout {
	position: relative;
}

.flex6 {
	@include flex-column;
	width: 100%;
	height: 100%;
	align-items: center;
}

.image19 {
	background: var(--src) center center / contain no-repeat;
}

.image19_layout {
	position: relative;
	font-size: 32px;
	min-width: 32px;
	margin: 0px auto;

	@include md {
		font-size: 24px;
		min-width: 24px;
	}
}

.flex6_item {
	@include flex-column;
	position: relative;
}

.subtitle {
	@include h-center-content;
	font: $font_type2;
	color: rgb(2, 120, 228);
	text-align: center;
	letter-spacing: 0px;

	@include md {
		font-size: 16px;
		text-align: center;
	}
}

.subtitle_layout {
	position: relative;
	height: min-content;
	min-width: 0px;
	width: fit-content;
	margin: 16px 0px 0px;
}

.grid2_item1 {
	@include flex-column;
	position: relative;
	flex: 0 1 1024px;
}

.grid {
	display: flex;
	flex-wrap: wrap;
	row-gap: 32px;
	column-gap: 32px;
}

.grid_layout {
	position: relative;
	height: min-content;
}

.grid_item {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
}

.flex1 {
	@include flex-column;
}

.flex1_layout {
	position: relative;
	height: min-content;
}

.group {
	@include flex-column;
}

.group_layout {
	position: relative;
	height: min-content;
}

.image191 {
	background: var(--src) center center / cover no-repeat;
	border-radius: 28px;

	@include xs {
		box-sizing: border-box;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
	}
}

.image191_layout {
	position: absolute;
	z-index: 1;
	display: none;
	height: 56px;
	bottom: -16px;
	width: 56px;
	right: -16px;
	background-color: white;

	@include xs {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.image2 {
	border: 1px solid rgba(255, 255, 255, 0.4);
	border-radius: 8px 8px 8px 8px;
	width: 100%;
	height: auto;
	vertical-align: top;
	object-fit: contain;
	object-position: center top;
}

.image2_layout {
	position: relative;
}

.small_text_body {
	@include font-face($font_type3, rgb(36, 37, 38), 0px);
}

.small_text_body_layout {
	position: relative;
	height: min-content;
	margin: 16px 8px 0px;
}

.caption_layout {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.subtitle1 {
	@include font-face($font_type2, rgb(36, 37, 38), 0px);
}

.subtitle1_layout {
	position: relative;
	height: min-content;
	margin: 0px 8px;
}

.paragraph_body {
	@include font-face($font_type4, rgb(154, 159, 163), 0px);
}

.paragraph_body_layout {
	position: relative;
	height: min-content;
	max-height: 60px;
	overflow-y: hidden;
	margin: 8px 8px 0px;
}

.flex2 {
	@include flex-column;
}

.flex2_layout {
	position: relative;
	height: min-content;
}

.flex_block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;

	.flex_block_child {
		margin-bottom: 10px;
	}
}

.sent_invitations {
	background: #FFFFFF;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
	border-radius: 36px;
	padding: 12px 24px;
	color: #0278E4;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
}

.editor_avatar {
	position: relative;

	button {
		display: none;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 32px;
		background: #FFFFFF;
		border: 1px solid #D3D6D8;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
		border-radius: 20px;
		color: #FF6633;
		font-weight: 600;
		position: absolute;
		margin: auto;
		left: 0;
		top: auto;
		bottom: 23px;
		right: 0;
		width: max-content;
		height: max-content;
	}

	&:hover {
		cursor: pointer;

		button {
			display: flex;
		}
	}
}
