/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 500 14px/1.42 "Montserrat";
$font_type1: 500 20px/1.6 "Montserrat";
$font_type2: 700 12px/1.5 "Montserrat";
$font_type3: 500 14px/1.4 "Montserrat";

.block8 {
	@include flex-column;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09803921568627451);
	border-radius: 24px 24px 24px 24px;
}

.block8_layout {
	position: relative;
	overflow: hidden;
	height: min-content;
}

.block9 {
	@include flex-column;
	background-color: rgb(255, 255, 255);
}

.block9_layout {
	position: relative;
	height: min-content;
}

.flex1 {
	display: flex;
}

.flex1_layout {
	position: relative;
	height: min-content;
}

.flex1_item {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
}

.icon {
	background: var(--src) center center / contain no-repeat;
}

.icon_layout {
	position: relative;
	flex-grow: 1;
	min-height: 30px;
	flex-shrink: 0;
	width: 30px;
	min-width: 30px;
}

.text_body1 {
	@include font-face($font_type0, rgb(36, 37, 38), 0px);
}

.text_body1_layout {
	position: relative;
	height: min-content;
	min-width: 0px;
	width: fit-content;
}

.flex1_item1 {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;

	@include max {
		min-width: unset;
		display: none;
	}
}

.postgrid_authors {
	@include flex-column;
}

.postgrid_authors_layout {
	position: relative;
	flex-grow: 1;
	min-height: 30px;
	flex-shrink: 0;
	width: 91px;
	min-width: 91px;
}

.block3 {
	width: 100%;
	height: auto;
	vertical-align: top;
	object-fit: contain;
	object-position: center top;
}

.block3_layout {
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 30px;
	min-width: 30px;
	right: 0px;
}

.icon_layout1 {
	position: absolute;
	top: 0px;
	height: 30px;
	left: 41px;
	width: 30px;
	min-width: 30px;
}

.icon_layout2 {
	position: absolute;
	top: 0px;
	height: 30px;
	left: 20px;
	width: 30px;
	min-width: 30px;
}

.icon_layout3 {
	position: absolute;
	top: 0px;
	height: 30px;
	left: 0px;
	width: 30px;
	min-width: 30px;
}

.flex1_spacer {
	@include flex-column;
	position: relative;
	flex: 1 1 auto;
}

.postgrid_nftprice {
	display: flex;
}

.postgrid_nftprice_layout {
	position: relative;
	height: min-content;
	min-width: 0px;
	width: fit-content;
	margin: 5px 0px;
}

.postgrid_nftprice_item {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
	min-width: 20px;
}

.image55 {
	background: var(--src) center center / contain no-repeat;
}

.image55_layout {
	position: relative;
	height: 20px;
	width: 20px;
	min-width: 20px;
}

.postgrid_nftprice_spacer {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
	min-width: 4px;
}

.postgrid_nftprice_item1 {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
}

.postgrid_nftprice1 {
	@include font-face($font_type0, rgb(79, 82, 86), 0px);
}

.postgrid_nftprice1_layout {
	position: relative;
	height: min-content;
	min-width: 0px;
	width: fit-content;
}

.postgrid_nftprice_spacer1 {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
	min-width: 10px;
}

.postgrid_nftprice_item2 {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
	min-width: 10px;
}

.postgrid_more {
	background: var(--src) center center / contain no-repeat;
}

.postgrid_more_layout {
	position: relative;
	height: 20px;
	margin-right: 12px;
	min-width: 10px;
}

.postgrid_cover {
	width: 100%;
	height: 220px;
	vertical-align: top;
	object-fit: contain;
	object-position: center top;
}

.postgrid_cover_layout {
	position: relative;
}

.postgrid_container {
	@include flex-column;
	background-color: rgb(255, 255, 255);
}

.postgrid_container_layout {
	position: relative;
	height: min-content;
}

.flex2 {
	@include flex-column;
}

.flex2_layout {
	position: relative;
	height: min-content;
	margin: 16px 24px;

	@include tn {
		margin: 16px 12px;
	}
}

.flex2_item {
	@include flex-column;
	position: relative;
}

.postgrid_title {
	display: -webkit-box;
	overflow: hidden;
	@include font-face($font_type1, rgb(36, 37, 38), 0px);
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.postgrid_title_box_layout {
	position: relative;
	height: min-content;
}

.postgrid_excerpt {
	display: -webkit-box;
	overflow: hidden;
	@include font-face($font_type0, rgb(154, 159, 163), 0px);
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.postgrid_excerpt_box_layout {
	position: relative;
	height: 40px;
	margin: 12px 0px 0px;
}

.flex2_item1 {
	@include flex-column;
	position: relative;

	@include tn {
		flex: 0 1 auto;
	}
}

.block5,
.block5_noBorder {
	display: flex;
	gap: 10px;
	border: 1px solid rgb(230, 232, 233);
	border-radius: 13px 13px 13px 13px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
}

.block5_noBorder {
	border: none;
	box-shadow: unset;
}

.block5_layout {
	position: relative;
	overflow: hidden;
	height: min-content;
	min-width: 0px;
	width: min-content;
	margin: 12px 0px 0px;
}

.block5_item {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;

	@include tn {
		min-width: unset;
	}
}

.flex6 {
	display: flex;
	border-radius: 13px 0px 0px 13px;
}

.flex6_layout {
	position: relative;
	height: min-content;
	min-width: 0px;
	width: fit-content;
}

.flex6_item {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
}

.postgrid_spacetag {
	@include h-center-content;
	font: $font_type2;
	color: rgb(79, 82, 86);
	text-align: center;
	letter-spacing: 0px;
}

.postgrid_spacetag_layout {
	position: relative;
	height: min-content;
	min-width: 0px;
	width: fit-content;
	margin: 4px 10px;
}

.postgrid_category__button {
	/* Category */
	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;
	gap: 10px;

	min-width: 0px;
	width: fit-content;
	height: auto;

	/* Gray/100 */

	background: #f3f3f4;
	border-radius: 13px;
}

.postgrid_category__button_text {
	/* Body/Body3 */

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 0.8rem;
	line-height: 1.5;
	white-space: nowrap;

	/* Blue/400 */
	color: #288ce8;
}

.flex6_item1 {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
	min-width: 1px;
}

.image5 {
	background: var(--src) center center / auto no-repeat;
}

.image5_layout {
	position: relative;
	flex-grow: 1;
	width: 1px;
	min-width: 1px;
}

.group {
	@include flex-column;
}

.group_layout {
	position: relative;
	height: 26px;
}

.postgrid_projecttag {
	@include h-center-content;
	font: $font_type2;
	color: rgb(154, 159, 163);
	text-align: center;
	letter-spacing: 0px;
}

.postgrid_projecttag_layout {
	position: relative;
	height: min-content;
	min-width: 0px;
	width: fit-content;
	margin: 4px 10px;
}

.flex61 {
	display: flex;
}

.flex61_layout {
	position: relative;
	height: min-content;
	margin: 16px 0px 0px;
}

.flex5 {
	display: flex;
}

.flex5_layout {
	position: relative;
	height: min-content;
	min-width: 0px;
	width: fit-content;
}

.flex5_item {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
	min-width: 20px;
}

.enthuse {
	background: var(--src) center center / contain no-repeat;
}

.enthuse_layout {
	position: relative;
	height: 20px;
	width: 20px;
	min-width: 20px;
}

.flex5_spacer {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
}

.postgrid_enthusednum {
	@include v-center-content;
	justify-content: center;
	font: $font_type3;
	color: rgb(154, 159, 163);
	text-align: center;
	letter-spacing: 0px;
}

.postgrid_enthusednum_layout {
	position: relative;
	flex: 0 0 auto;
	height: min-content;
}

.flex6_spacer {
	@include flex-column;
	position: relative;
	flex: 1 1 auto;
}

.block37 {
	display: flex;
}

.block37_layout {
	position: relative;
	height: min-content;
	min-width: 0px;
	width: fit-content;
}

.block37_item {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
	min-width: 20px;
}

.postgrid_comment {
	background: var(--src) center center / contain no-repeat;
}

.postgrid_comment_layout {
	position: relative;
	height: 20px;
	width: 20px;
	min-width: 20px;
}

.block37_spacer {
	@include flex-column;
	position: relative;
	flex: 0 0 auto;
	min-width: 12px;
}

.postgrid_share {
	background: var(--src) center center / contain no-repeat;
}

.postgrid_share_layout {
	position: relative;
	height: 20px;
	width: 20px;
	min-width: 20px;
}

.text_ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
