.input-text {
  border-color: #D3D6D8;
  @apply w-full border py-2 px-4 rounded;
}
.input-text-error {
  border-color: #eb5757;
}
.circle-wrapper {
  @apply inline-flex items-center justify-center rounded-full bg-black overflow-hidden relative;
}
.button-rounded {
  padding-top: 0.438rem;
  padding-bottom: 0.438rem;
  @apply text-denim-500 border-denim-500 border font-bold w-full rounded-md;
}
.button-rounded:hover {
  background: #e1effc;
}
.button-rounded-full {
  @apply rounded-full bg-denim-500 font-bold text-white px-8 py-2;
}
