
.explore-creations {
  width: fit-content;
  transition: transform 400ms linear;
}
.explore-genre .search-input-wrap{
  max-width: 1056px;
}
.items-wrap{
  max-width: 1296px;
}
.explore-genre .search-input-wrap .search-input{
  @apply shadow-md w-10/12 md:w-full font-medium border border-gray-300 p-2 placeholder-gray-300 text-center
}

.explore-genre .search-input-bg{
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}
.explore-genre .dark\:search-input-bg{
  background: none;
}

.top-item_creation img{
  width: 39rem;
  height: 21.25rem;
}

.top-item_creation .line-clamp{
  height: 126px;
}
.top-item{
  height: 23.25rem;
}
.reppls-creation{
  width: 922px;
  left: 446px;
}
.creators-creations{
  width: 996px;
  left: 446px;
}
.reppls-creation, .creators-creations{
  left: 446px;
}
.creator-circle:hover .user-card{
  display: block;
}
.creator-circle .user-card-bg{
  background-image: url('/images/assets/wave-gray.svg');
  background-repeat: repeat;
}
.explore-banners{
  height: 651px;
}

/* starting EXIT animation */
.banner-exit {
  opacity: 1;
}

/* ending EXIT animation */
.banner-exit-active {
  opacity: 0;
  transition: opacity 300ms ease;
}

.explore-triangle {
  border-width: 504px 0 504px 710px;
  border-color: transparent transparent  transparent #e6e8e9;
}

.filter-list::after{
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-color: transparent transparent transparent black;
  content: '';
  position: absolute;
  right: 1.5rem;
  top: 1.75rem;
}
.explore-creation-card{
  box-shadow: none;
}
@media (max-width: 768px) { 
  .explore-creator{
    width: 100%;
  }
  .explore-catrogry-wrap{
    flex-wrap: wrap;
  }
  .explore-catrogry{
    width: 49%;
    margin-bottom: 2%;
    height: 80px;
  }
  .explore-catrogry:nth-child(2n+1){
    margin-right: 2%;
  }
  .explore-hashtag{
    width: 49%;
    margin-bottom: 2%;
    height: 80px;
  }
  .explore-hashtag:nth-child(2n+1){
    margin-right: 2%;
  }
  .CrossfadeImage{
    height: 667px;
    /* position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -50px; */
  }
  .banner-imgs img{
    width: auto !important;
    height: 667px;
  }
  .explore-triangle{
    border-width: 304px 0 304px 510px;
    top: 23rem;
  }
}
/* desktop */
@media (min-width: 768px) { 
  .explore-width {
    max-width: 1272px;
  }
  .explore-creation-card .line-clamp-2{
    font-size: 1.25rem;
  }
  .top-item{
    height: 27.5rem;
  }
  .top-item_creation .line-clamp{
    height: 8.625rem;
  }
  .top-item_creation .creation-creators{
    width: 39rem;
  }
  .creation-creators{
    bottom: 3.75rem;
  }
  .explore-creator {
    max-width: 1600px;
  }
  .explore-creation-card{
    width: 25.5rem;
  }
  .explore-creation-card .h-50{
    height: 16.5rem;
  }
  .explore-creator .marquee-top .user-card{
    top: -2.8rem;
  }
  .explore-creator .marquee-down .user-card{
    bottom: -4rem;
  }
  .explore-triangle{
    top: 29rem;
  }
}
@media (min-width: 1600px) { 
  .explore-triangle{
    top: 23rem;
  }
}