/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 600 14px/1.28 "Montserrat", Helvetica, Arial, serif;
$font_type1: 36px/1.55 "Montserrat", Helvetica, Arial, serif;
$font_type2: 500 14px/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type3: 700 16px/1.25 "Montserrat", Helvetica, Arial, serif;
$font_type4: 700 14px/1.28 "Montserrat", Helvetica, Arial, serif;
$font_type5: 600 14px/1.71 "Montserrat", Helvetica, Arial, serif;
$font_type6: 600 14px/1.42 "Montserrat", Helvetica, Arial, serif;

.group {
  @include flex-column;
}
.group_layout {
  position: relative;
  height: min-content;
}
.decorator {
  background: var(--src) center center / cover no-repeat;
}
.decorator_layout {
  position: absolute;
  top: -25px;
  bottom: -25px;
  left: -25px;
  right: -25px;
  @include xxs {
    top: 0px;
    bottom: 0px;
    display: none;
  }
}
.text_body3 {
  @include v-center-content;
  @include font-face($font_type0, rgb(155, 159, 164), 0px);
  @include xxs {
    justify-content: center;
    text-align: center;
  }
}
.text_body3_layout {
  position: absolute;
  z-index: 100;
  display: none;
  height: 18px;
  bottom: 20px;
  width: 300px;
  left: -100vw;
  right: -100vw;
  margin: 0px auto;
}
.block {
  @include flex-column;
}
.block_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
}
.block_item {
  @include flex-column;
  position: relative;
  @include xxs {
    flex: 0 1 auto;
    display: none;
  }
}
.block7 {
  @include flex-column;
}
.block7_layout {
  position: relative;
  height: min-content;
}
.flex {
  display: flex;
}
.flex_layout {
  position: relative;
  height: min-content;
  margin: 25px 25px 18px 31px;
}
.flex_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 1299px;
}
.flex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 10px;
}
.block_item1 {
  @include flex-column;
  position: relative;
}
.block5 {
  @include flex-column;
}
.block5_layout {
  position: relative;
  height: min-content;
  margin: 100px 24px 122px;
  @include xxs {
    margin: 0px;
  }
}
.flex1 {
  @include flex-column;
}
.flex1_layout {
  position: relative;
  height: min-content;
  width: 100%;
  min-width: 898px;
  max-width: 898px;
  margin: 0px auto;
  @include md {
    min-width: 0px;
    margin: 0px 0px;
  }
}
.flex_item {
  @include flex-column;
  position: relative;
}
.group1 {
  @include flex-column;
  @include xxs {
    height: 100vh;
    width: 100vw;
  }
}
.group1_layout {
  position: relative;
  height: min-content;
}
.cover_group {
  background: var(--src) center center / contain no-repeat;
}
.cover_group_layout {
  position: absolute;
  top: 16px;
  height: 20px;
  width: 20px;
  right: 16px;
  @include max {
    display: none;
  }
  @include xxs {
    display: flex;
    z-index: 1;
  }
}
.block2 {
  display: flex;
  background-color: rgb(243, 243, 244);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.047058823529411764);
  border-radius: 16px 16px 16px 16px;
  @include xxs {
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
  }
}
.block2_layout {
  position: relative;
  height: min-content;
}
.block2_item {
  @include flex-column;
  position: relative;
  flex: 0 1 539px;
  @include sm {
    flex: 1 1 539px;
  }
}
.flex11 {
  @include flex-column;
}
.flex11_layout {
  position: relative;
  height: min-content;
  margin: 129px 85px;
  @include md {
    margin: 129px 60px;
  }
  @include xxs {
    margin: 100px 26px 129px;
  }
  @include tn {
    margin: 100px 22px 129px;
  }
}
.hero_title {
  display: flex;
  @include font-face($font_type1, rgb(36, 37, 38), -1px);
  @include sm {
    justify-content: center;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
  }
}
.hero_title_layout {
  position: relative;
  height: min-content;
}
.flex1_item {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}
.flex4 {
  display: flex;
}
.flex4_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px auto;
  @include max {
    margin: 0px auto 0px 0px;
  }
  @include sm {
    margin: 0px auto;
  }
}
.flex4_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body1 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
  white-space: pre-wrap;
}
.text_body1_box {
  @include v-center-content;
}
.text_body1_box_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.flex4_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.flex4_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 238px;
}
.text_body11 {
  @include v-center-content;
  @include font-face($font_type2, rgb(2, 120, 228), 0px);
}
.text_body11_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.flex1_item1 {
  @include flex-column;
  position: relative;
}
.block3 {
  @include flex-column;
  background-color: rgb(225, 239, 252);
  border: 1px solid rgb(2, 120, 228);
  border-radius: 4px 4px 4px 4px;
}
.block3_layout {
  position: relative;
  height: min-content;
  margin: 40px 0px 0px;
}
.flex3 {
  display: flex;
}
.flex3_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 16px auto;
}
.flex3_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
}
.image5 {
  background: var(--src) center center / contain no-repeat;
}
.image5_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.flex3_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex3_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.highlights {
  display: flex;
  justify-content: flex-end;
  font: $font_type3;
  color: rgb(79, 82, 86);
  text-align: right;
  letter-spacing: 0px;
}
.highlights_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px 2px 0px 0px;
}
.flex1_item2 {
  @include flex-column;
  position: relative;
}
.block4 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.11764705882352941);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.block4_layout {
  position: relative;
  height: min-content;
  margin: 12px 0px 0px;
}
.flex4_layout1 {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 16px auto;
}
.flex4_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
}
.image4 {
  background: var(--src) center center / contain no-repeat;
}
.image4_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.flex4_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex1_item3 {
  @include flex-column;
  position: relative;
}
.flex4_layout2 {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 12px 0px 0px;
}
.text_body12 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body12_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.flex4_item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 238px;
}
.text_body13 {
  @include v-center-content;
  @include font-face($font_type2, rgb(2, 120, 228), 0px);
}
.text_body13_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.flex4_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.flex4_item4 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.image2 {
  background: var(--src) center center / contain no-repeat;
}
.image2_layout {
  position: relative;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin: 2px 0px;
}
.flex1_item4 {
  @include flex-column;
  position: relative;
}
.flex5 {
  display: flex;
}
.flex5_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 32px auto 0px;
  @include max {
    margin: 32px auto 0px 0px;
  }
  @include xxs {
    margin: 32px auto 0px;
  }
}
.text_body2 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
  white-space: pre-wrap;
}
.text_body2_box {
  @include v-center-content;
}
.text_body2_box_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.text_body31 {
  @include v-center-content;
  @include font-face($font_type0, rgb(2, 120, 228), 0px);
}
.text_body31_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 1px 0px;
}
.block2_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 359px;
  @include sm {
    display: none;
  }
}
.cover_block {
  @include flex-column;
  background: var(--src) center center / 100% 100% no-repeat;
}
.cover_block_layout {
  position: relative;
  flex-grow: 1;
  min-height: 656px;
  flex-shrink: 0;
}
.text_body {
  @include v-center-content;
  justify-content: center;
  font: $font_type4;
  color: rgb(79, 82, 86);
  text-align: center;
  letter-spacing: 0px;
}
.text_body_layout {
  position: relative;
  height: min-content;
  margin: 187px 40px 0px;
}
.paragraph_body {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type5, rgb(79, 82, 86), 0px);
  white-space: pre-wrap;
}
.paragraph_body_box {
  @include v-center-content;
}
.paragraph_body_box_layout {
  position: relative;
  height: min-content;
  margin: 36px 40px 0px;
}
.paragraph_body1 {
  @include v-center-content;
  justify-content: center;
  font: $font_type6;
  color: rgb(154, 159, 163);
  text-align: center;
  letter-spacing: 0px;
}
.paragraph_body1_layout {
  position: relative;
  height: min-content;
  margin: 135px 40px 24px;
}
.flex_item1 {
  @include flex-column;
  position: relative;
  @include xxs {
    flex: 0 1 auto;
    display: none;
  }
}
.text_body4 {
  @include h-center-content;
  font: $font_type2;
  color: rgb(228, 238, 255);
  text-align: center;
  letter-spacing: 0px;
}
.text_body4_layout {
  position: relative;
  height: min-content;
  margin: 16px 22.5px 0px 21.5px;
}
.flex_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
  @include xxs {
    display: none;
  }
}
.text_body4_layout1 {
  position: relative;
  height: min-content;
  margin: 0px 22.5px 0px 21.5px;
}

$main-bg-color: #F3F3F4;
$main-text-color: #4F5356;
$secondary-text-color: #9B9FA4;
$input-color: white;
$input-border-color: #D3D6D8;
$action-color: #0278E4;

.wallet_button {
  width: 100%;
  height: 48px;
  background-color: $input-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid $input-border-color;
}

.wallet_button:active {
  transform: scale(0.97);
}

.wallet_button_icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.walletConnect_help_container {
  margin-top: 4px;
  display: flex;
}

.walletConnect_reset_button {
  color: $action-color;
  margin-left: 5px;
}

.walletConnect_help_icon {
  margin-left: 5px;
}

