.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  background-color: white;
  width: 480px;
  border-radius: 16px;
  padding: 40px;
  transition: 300ms;
  position: relative;
  animation: scalein 300ms ease-in-out;
  max-height: 80vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #242526;
    margin-top: 16px;
  }

  h3 {
    margin-top: 32px;
    font-size: 14px;
    font-weight: 600;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #9b9fa4;
    margin-top: 16px;
  }

  ul {
    list-style-type: disc;
    margin-left: 24px;
    font-size: 14px;
  }

  li {
    margin-top: 8px;
    color: #9b9fa4;
  }

  @media only screen and (max-width: 520px) {
    max-height: unset;
    height: 100vh;
    width: 100%;
    border-radius: unset;
    padding: 56px 24px 40px 24px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scalein {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}

.fadein {
  animation: fadein 300ms ease-in-out;
}

.bottom_section {
  margin-top: 16px;
  border-top: 2px dotted #e6e8e9;
  width: 100%;
  color: #0278e4;
  font-weight: 500;
  font-size: 12px;
}

.close_button {
  position: absolute;
  top: 32px;
  right: 26px;
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.x1,
.x2 {
  position: absolute;
  width: 18px;
  height: 2px;
  border-radius: 20px;
  background-color: #9b9fa4;
  top: 0;
  left: 0;
}

.x1 {
  rotate: 45deg;
}

.x2 {
  rotate: -45deg;
}
