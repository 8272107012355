.side-setting-business,
.about-setting-boxShadow {
    .css-h2bo97-multiValue,
    .css-1rhbuit-multiValue {
        border-radius: 2px;
        color: #288ce8;
        font-weight: 500;
        padding: 4px 12px;
        border: none;
        background: #f3f3f4;
        border-radius: 14px;
        height: 26px;
        margin: 4px 8px 4px 0;
        .css-1ve1o0y,
        .css-12jo7m5 {
            color: #288ce8;
            display: flex;
            font-size: 12px !important;
            align-items: center;
            font-weight: 500;
            padding-left: 0px;
            line-height: 18px;
        }
        .css-xb97g8,
        .css-nu7wqb {
            color: #bbc0c5;
            cursor: pointer;
            padding-right: 0;
        }

        .css-nu7wqb {
            padding: 0 !important;
        }
        .css-1ve1o0y {
            padding-left: 0;
        }
    }
    .css-xb97g8 svg {
        font-size: 16px;
        height: 16px;
        width: 16px;
        display: inline;
    }
    .css-30yeao-ValueContainer,
    .css-1hwfws3,
    .css-4ugd4a,
    .css-g1d714-ValueContainer {
        padding: 8px 12px;
    }
    .chakra-textarea {
        padding: 11px 80px 11px 16px;
    }
    .css-1pahdxg-control {
        border: 1px solid #d3d6d8 !important;
        box-shadow: 0 0 0 0px #d3d6d8 !important;
    }
    .css-yk16xz-control {
        border: 1px solid #d3d6d8;
    }
}

.css-xb97g8:hover {
    background-color: unset !important;
}

.language-select {
    .css-yk16xz-control {
        border: 1px;
    }
}

.about-setting-boxShadow {
    .css-1xc9rpo-control,
    .css-yk16xz-control {
        border-color: #e6e8e9 !important;
        color: "$mode.900";
        font-size: 14px;
        font-weight: 500;
    }
}

.about-setting-about-form {
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 6%);
    border-radius: 8px;
    background: #fafbfb;
    border: 1px solid #e6e8e9;
    margin-bottom: 1rem;
    .css-2b097c-container,
    .css-yk16xz-control {
        border-color: #e6e8e9;
    }
    .chakra-textarea {
        padding: 11px 80px 11px 16px;
    }
    textarea:focus,
    input:focus {
        border-color: #4f5356;
        box-shadow: none;
    }
    textarea:hover,
    input:hover {
        border-color: #d567c4;
        box-shadow: none;
    }
}
.form-error {
    textarea,
    input,
    .css-1phmz2y-control,
    .css-1glht4c-control {
        background-color: rgba(255, 102, 51, 0.1);
        border: 1px solid #ff6633 !important;
        box-shadow: none !important;
    }
    .error-word {
        color: #ff6633;
    }
    .css-co2noh-Input,
    .css-13ypqxj {
        input {
            border: none !important;
        }
    }
}

.side-setting-form {
    input {
        padding: 11px 50px 11px 16px;
    }
    input:focus {
        border-color: #4f5356;
        box-shadow: none;
    }
}
.space_editor,
.space-editor-viewer {
    border: 2px dashed rgb(255, 255, 255);
    .codex-editor {
        background-color: rgb(255, 255, 255);
    }
}
.space_editor:focus,
.space_editor:hover {
    border: 2px dashed #d3d6d8;
    border-radius: 24px;
}

.space-admin-select {
    .css-1wy0on6,
    .css-tlfecz-indicatorContainer,
    .css-1hb7zxy-IndicatorsContainer {
        display: none;
    }
    .css-yk16xz-control {
        border-color: #d3d6d8 !important;
    }
}

.space-editor {
    .ce-header {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }
    .codex-editor__redactor {
        padding-bottom: 32px !important;
    }
    .codex-editor--narrow .codex-editor__redactor {
        margin-right: 0;
    }
}
.notification-title-read {
    b {
        color: #0278e4;
    }
}

.notification-title-unread {
    color: #9b9fa4;
    b {
        color: #9b9fa4;
    }
}
