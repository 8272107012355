.main_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: white;
  height: calc(100vh - 150px);
  width: calc(100vw - 150px);
  max-height: 830px;
  max-width: 960px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1024px) {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    max-height: unset;
    max-width: unset;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 100vh;
    border-radius: unset;
  }
}

.modal_content {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 40px 40px 88px 40px;
  flex-direction: column;
  overflow-y: overlay;
  @media (max-width: 1024px) {
    padding: 40px;
    display: block;
  }
  @media (max-width: 600px) {
    padding: 24px;
    display: block;
  }
}

.top_section {
  color: #242526;
  h2 {
    font-weight: 600;
    font-size: 20px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    margin-top: 16px;
  }
}

.bottom_section {
  display: flex;
  margin-top: 40px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
}

.left_side {
  width: 50%;
  height: 100%;
  margin-top: -17px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.right_side {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: end;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
}

.recommendation_card {
  display: flex;
  flex-direction: column;
  width: 424px;
  height: fit-content;
  background-color: #fafbfb;
  padding: 32px;
  border: 1px solid #d3d6d8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  h3 {
    font-weight: 600;
    font-size: 14px;
    color: #bbc0c5;
  }

  .full_name {
    font-weight: 600;
    font-size: 14px;
    color: #0278e4;
    margin-top: 11px;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    color: #9b9fa4;
    margin-top: 16px;
  }

  @media (max-width: 1100px) {
    width: 380px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 424px;
    margin-bottom: 88px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 50px;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes add-border {
  0% {
    border-color: rgba(106, 106, 106, 0);
  }
  100% {
    border-color: rgb(106, 106, 106);
  }
}

.step_container {
  margin-top: 17px;
  padding-bottom: 17px;
  display: flex;
  align-items: start;
  gap: 12px;
  font-weight: 600;
  max-width: 424px;
  font-size: 16px;
  opacity: 0.5;
  filter: saturate(0);
  border-bottom: dashed 1px rgb(165, 165, 165);
  transition: 1000ms;
  animation: add-border 2000ms;

  @media (max-width: 1024px) {
    max-width: unset;
  }
}

.current_step_container {
  margin-top: 17px;
  padding-bottom: 17px;
  display: flex;
  align-items: start;
  gap: 12px;
  font-weight: 600;
  max-width: 424px;
  font-size: 16px;
  animation: fade-in ease-in-out 400ms;

  @media (max-width: 1024px) {
    max-width: unset;
  }
}

.step_right {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 7px;
}

.inputs_container {
  display: flex;
  flex-direction: column;
}

.input_container {
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
}

.input {
  height: 20px;
  aspect-ratio: 1;
}

.true_input {
  opacity: 0;
  animation: fade-in ease-in-out 400ms;
  animation-delay: 100ms;
  animation-fill-mode: forwards;
}

.false_input {
  opacity: 0;
  animation: fade-in ease-in-out 400ms;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
}

.hexagon_top {
  width: 0;
  border-bottom: 10px solid #2bc3a8;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
}

.hexagon_bot {
  width: 0;
  border-top: 10px solid #2bc3a8;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
}

.hexagon_mid {
  width: 34px;
  height: 18px;
  background-color: #2bc3a8;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.previous_answer {
  font-weight: 500;
  font-size: 14px;
  margin-top: 16px;
}

.buttons_container {
  display: flex;
  gap: 8px;
  margin-left: 46px;
  user-select: none;
  margin-bottom: 50px;
  button {
    width: 106px;
    height: 40px;
    border: solid 1px #d3d6d8;
    border-radius: 4px;
    color: #9b9fa4;
    font-weight: 600;
  }

  button:active {
    transition: unset;
    transform: scale(0.97);
  }

  button:nth-child(2) {
    color: #21ce99;
  }
}

.disabled {
  opacity: 0.4;
  user-select: none;
  pointer-events: none;
  color: rgb(195, 195, 195) !important;
  transition: 200ms;
}

.icons_container {
  display: flex;
  gap: 7px;
  img {
    height: 33px;
    filter: brightness(3.3);
    user-select: none;
    -webkit-user-drag: none;
  }
  font-weight: 600;
  font-size: 20px;
  color: #4f5356;
  margin-top: 19px;
  align-items: center;
  span {
    margin-left: 4px;
  }
}

.nav_bar {
  height: 88px;
  width: 100%;
  border-top: 1px solid #e6e8e9;
  bottom: 88px;
  bottom: 0;
  left: 0;
  border-radius: 0 0 16px 16px;
  background-color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 40px;
  gap: 8px;
  button {
    width: 103px;
    height: 40px;
    font-weight: 600;
    border-radius: 100px;
    &:active {
      scale: 0.97;
    }
  }

  @media (max-width: 600px) {
    position: unset;
    bottom: unset;
    left: unset;
    height: 88px;
    margin-bottom: 100px;
  }
}

.cancel_button {
  color: #9b9fa4;
}

.save_button {
  background-color: #288ce8;
  color: white;
  user-select: none;
  transition: 100ms;
  &:active {
    transition: unset;
  }
}

.save_button_disabled {
  background-color: #288ce8;
  color: white;
  pointer-events: none;
  opacity: 0.5;
  user-select: none;
  transition: 100ms;
}

.modal_content::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  display: none;
}

.modal_content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(216, 216, 216);
}

.input {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

.input {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #288ce8;
  width: 20px;
  height: 20px;
  border: 1px solid #d3d6d8;
  border-radius: 50%;
  display: grid;
  place-content: center;
  transition: 100ms;
  @media (hover: hover) {
    &:hover {
      border: 6px solid #e0e3e5;
    }
  }
}

.input::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  scale: 0;
  background-color: white;
  transition: 150ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

.input[type='radio']:checked {
  background-color: #0278e4;
  border: 1px solid #0278e4;
}

.input[type='radio']:checked::before {
  transition: unset;
  scale: 1;
}

.tooltip {
  span {
    width: fit-content;
    display: flex;
    gap: 4px;
    font-size: 12px;
    color: #bbc0c5;
    align-items: center;
    margin-top: 6px;
    cursor: default;
    transition: 200ms;
    &:hover {
      transition: 200ms;
      filter: brightness(1.15);
    }
  }
}

.info_icon {
  font-size: 14px;
}
