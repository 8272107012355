/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import 'utils';

$font_type0: 500 14px/1.28 'Montserrat', Helvetica, Arial, serif;
$font_type1: 36px/1.55 'Montserrat', Helvetica, Arial, serif;
$font_type2: 600 14px/1.42 'Montserrat', Helvetica, Arial, serif;
$font_type3: 500 14px/1.42 'Montserrat', Helvetica, Arial, serif;
$font_type4: 700 16px/1.25 'Montserrat', Helvetica, Arial, serif;
$font_type5: 600 1em/1.42 'Montserrat', Helvetica, Arial, serif;
$font_type6: 500 16px/1.25 'Inter', Helvetica, Arial, serif;
$font_type7: 800 14px/1.28 'Montserrat', Helvetica, Arial, serif;
$font_type8: 700 14px/1.28 'Montserrat', Helvetica, Arial, serif;
$font_type9: 600 14px/1.71 'Montserrat', Helvetica, Arial, serif;

.group {
  @include flex-column;
}
.group_layout {
  position: relative;
}
.text_body3 {
  @include h-center-content;
  font: $font_type0;
  color: rgb(155, 159, 164);
  text-align: center;
  letter-spacing: 0px;
}
.text_body3_layout {
  position: absolute;
  z-index: 20;
  display: none;
  height: 18px;
  bottom: 16px;
  width: 300px;
  left: -100vw;
  right: -100vw;
  margin: 0px auto;
}
.decorator {
  background: var(--src) center center / cover no-repeat;
}
.decorator_layout {
  position: absolute;
  top: -25px;
  bottom: -25px;
  left: -25px;
  right: -25px;
  @include xxs {
    top: 0px;
    bottom: 0px;
  }
}
.block {
  @include flex-column;
  // box-shadow: ;
}
.block_layout {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}
.block_item {
  @include flex-column;
  position: relative;
  @include xxs {
    flex: 0 1 auto;
    display: none;
  }
}
.block7 {
  @include flex-column;
}
.block7_layout {
  position: relative;
  height: min-content;
}
.flex {
  display: flex;
}
.flex_layout {
  position: relative;
  height: min-content;
  margin: 25px 25px 18px 31px;
}
.flex_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 1299px;
}
.flex_item {
  @include flex-column;
  position: relative;
  flex: 0 1 10px;
}
.cover_group {
  background: var(--src) center center / contain no-repeat;
}
.cover_group_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.block_item1 {
  @include flex-column;
  position: relative;
}
.block11 {
  @include flex-column;
}
.block11_layout {
  position: relative;
  height: min-content;
  margin: 94px 24px 128px;
  @include xxs {
    margin: 0px;
  }
}
.flex1 {
  @include flex-column;
}
.flex1_layout {
  position: relative;
  height: min-content;
  width: 62.71%;
  min-width: 898px;
  max-width: 898px;
  margin: 0px auto;
  @include md {
    width: 100%;
    min-width: 0px;
    margin: 0px 0px;
  }
}
.flex1_item {
  @include flex-column;
  position: relative;
}
.block1 {
  display: flex;
  background-color: rgb(243, 243, 244);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.047058823529411764);
  border-radius: 24px 24px 24px 24px;
  overflow: hidden;
  @include xxs {
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
  }
}
.block1_layout {
  position: relative;
  height: min-content;
  width: 100%;
  margin: 0px auto;
  @include xxs {
    width: 100%;
    height: 100vh;
  }
}
.block1_item {
  @include flex-column;
  position: relative;
  flex: 0 1 539px;
  @include md {
    flex: 1 1 539px;
  }
}
.block10 {
  @include flex-column;
}
.block10_layout {
  position: relative;
  height: min-content;
}
.cover_group_layout1 {
  position: absolute;
  top: 0px;
  height: 10px;
  width: 10px;
  right: 0px;
  @include max {
    display: none;
  }
  @include xxs {
    top: 16px;
    height: 24px;
    width: 24px;
    right: 16px;
    display: flex;
  }
}
.block9 {
  @include flex-column;
}
.block9_layout {
  position: relative;
  height: min-content;
}
.block9_item {
  @include flex-column;
  position: relative;
}
.hero_title {
  display: flex;
  @include font-face($font_type1, rgb(36, 37, 38), -1px);
  @include sm {
    justify-content: center;
    font-size: 24px;
    text-align: center;
  }
  @include xxs {
    font-weight: 600;
  }
}
.hero_title_layout {
  position: relative;
  height: min-content;
}
.block9_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}
.flex4 {
  display: flex;
}
.flex4_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 4px auto 0px;
  @include max {
    margin: 4px auto 0px 0px;
  }
  @include sm {
    margin: 4px auto 0px;
  }
}
.flex4_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body1 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
  font-weight: 500;
}
.text_body1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.flex4_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.flex4_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 238px;
}
.text_body11 {
  @include v-center-content;
  @include font-face($font_type2, rgb(2, 120, 228), 0px);
  font-weight: 500;
}
.text_body11_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.paragraph_body {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
  font-weight: 500;
}
.paragraph_body_layout {
  position: relative;
  height: min-content;
  margin: 40px 0px 0px;
}
.block2 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.block2_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.text_body2 {
  @include v-center-content;
  @include font-face($font_type3, rgb(210, 213, 216), 0px);
}
.text_body2_layout {
  position: relative;
  height: min-content;
  margin: 10px 16px;
}
.paragraph_body_layout1 {
  position: relative;
  height: min-content;
  margin: 32px 6px 0px;
}
.flex2 {
  display: flex;
}
.flex2_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex2_item {
  @include flex-column;
  position: relative;
  flex: 0 1 179px;
  @include sm {
    flex: 1 1 179px;
  }
}
.block3 {
  @include flex-column;
  background-color: rgb(225, 239, 252);
  border: 1px solid rgb(2, 120, 228);
  border-radius: 4px 4px 4px 4px;
}
.block3_layout {
  position: relative;
  height: min-content;
  width: 100%;
}
.flex3 {
  display: flex;
}
.flex3_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 15px auto;
}
.flex3_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
}
.image5 {
  background: var(--src) center center / contain no-repeat;
}
.image5_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.flex3_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex3_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.highlights {
  display: flex;
  justify-content: flex-end;
  font: $font_type4;
  color: rgb(79, 82, 86);
  text-align: right;
  letter-spacing: 0px;
  font-weight: 600;
}
.highlights_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px 2px 0px 0px;
}
.flex2_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
}
.block4 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.11764705882352941);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.block4_layout {
  position: relative;
  height: min-content;
  width: 100%;
}
.flex4_layout1 {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 16px auto;
}
.flex4_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
}
.image4 {
  background: var(--src) center center / contain no-repeat;
}
.image4_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.flex4_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.block9_item2 {
  @include flex-column;
  flex-direction: column;
  position: relative;
  flex: 0 1 52px;
}
.flex21 {
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
  margin-top: 16px;
}
.flex21_layout {
  position: relative;
  height: min-content;
}
.flex2_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 100%;
}
.flex3_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.flex2_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
  @include xxl {
    display: none;
  }
}
.flex2_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 100%;
}
.flex4_layout2 {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 16px auto;
}
.flex4_item3 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
}
.flex4_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.block2_layout1 {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.block5 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.block5_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex5 {
  display: flex;
}
.flex5_layout {
  position: relative;
  height: min-content;
  margin: 10px 12px;
}
.flex5_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include sm {
    flex: 1 0 auto;
    min-width: unset;
  }
}
.text_body2_layout1 {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 4px;
}
.flex5_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 251px;
}
.flex5_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}
.icon1 {
  background: var(--src) center center / contain no-repeat;
}
.icon1_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.flex6 {
  display: flex;
  align-items: center;
}
.flex6_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex6_item {
  @include flex-column;
  position: relative;
  flex: 0 1 24px;
}
.icon2 {
  background: var(--src) center center / contain no-repeat;
}
.icon2_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex6_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 8px;
}
.text_body12 {
  font: $font_type3;
  color: rgb(0, 0, 0);
  text-align: right;
  letter-spacing: 0px;
}
.text_body12_box {
  @include v-center-content;
  justify-content: flex-end;
}
.text_body12_box_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 3px 0px 1px;
}
.text_body12_span0 {
  @include font-face($font_type5, #bbc0c5ff, 0px);
  font-weight: 500;
}
.text_body12_span1 {
  @include font-face($font_type5, #73787cff, 0px);
}
.text_body12_span2 {
  @include font-face($font_type5, #0278e4ff, 0px);
  font-size: 14px;
  font-weight: 500;
}
.block6 {
  @include flex-column;
  background-color: rgb(129, 188, 242);
  border-radius: 20px 20px 20px 20px;
}
.block6_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.highlights1 {
  display: flex;
  justify-content: flex-end;
  font: $font_type6;
  color: rgb(255, 255, 255);
  text-align: right;
  letter-spacing: 0px;
}
.highlights1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 10px auto;
}
.flex7 {
  display: flex;
}
.flex7_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 19px auto 0px;
}
.flex7_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
}
.cover_block2 {
  background: var(--src) center center / contain no-repeat;
}
.cover_block2_layout {
  position: relative;
  height: 16px;
  width: 16px;
  min-width: 16px;
}
.flex7_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
}
.text_body31 {
  display: flex;
  justify-content: flex-end;
  font: $font_type7;
  color: rgb(79, 82, 86);
  text-align: right;
  letter-spacing: 0px;
}
.text_body31_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.block9_item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
  @include max {
    display: none;
  }
  @include xxs {
    display: flex;
  }
}
.flex7_layout1 {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px auto;
}
.flex7_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block1_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 359px;
  @include md {
    flex: 1 1 359px;
  }
  @include sm {
    flex: 0 1 359px;
    display: none;
  }
}
.cover_block {
  @include flex-column;
  background: var(--src) center center / 100% 100% no-repeat;
}
.cover_block_layout {
  position: relative;
  flex-grow: 1;
  min-height: 670px;
  flex-shrink: 0;
}
.text_body {
  @include v-center-content;
  justify-content: center;
  font: $font_type8;
  color: rgb(79, 82, 86);
  text-align: center;
  letter-spacing: 0px;
  font-weight: 600;
}
.text_body_layout {
  position: relative;
  height: min-content;
  margin: 187px 0px 0px;
}
.paragraph_body1 {
  list-style: disc;
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type9, rgb(79, 82, 86), 0px);
  white-space: pre-wrap;
  padding-left: 25px;
}
.paragraph_body1_box {
  @include v-center-content;
}
.paragraph_body1_box_layout {
  position: relative;
  height: min-content;
  margin: 24px 40px 0px 40px;
}
.paragraph_body11 {
  @include v-center-content;
  justify-content: center;
  font: $font_type2;
  color: rgb(154, 159, 163);
  text-align: center;
  letter-spacing: 0px;
  font-weight: 500;
}
.paragraph_body11_layout {
  position: relative;
  height: min-content;
  margin: 30px 36px 24px;
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.flex1_item1 {
  @include flex-column;
  position: relative;
  @include xxs {
    flex: 0 1 auto;
    display: none;
  }
}
.text_body4 {
  @include h-center-content;
  font: $font_type3;
  color: rgb(228, 238, 255);
  text-align: center;
  letter-spacing: 0px;
}
.text_body4_layout {
  position: relative;
  height: min-content;
  margin: 16px 15.5px 0px 14.5px;
}
.flex1_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
  @include xxs {
    display: none;
  }
}
.text_body4_layout1 {
  position: relative;
  height: min-content;
  margin: 0px 15.5px 0px 14.5px;
}

$error-text-color: rgb(255, 102, 51);
$action-color: rgb(40, 140, 232);
$input-background-color: white;
$input-border-color: #d3d6d8;
$main-text-color: #4f5356;
$warning-border-color: #ff6633;
$warning-background-color: #f4e5e0;
$popup-background-color: #f3f3f4;

.sign_up_form {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 320px;
  font-size: 14px;
  font-weight: 500;
  @media (max-width: 360px) {
    width: unset;
  }
}

.error_message {
  color: $error-text-color;
  font-size: 0.75rem;
}

.input_error_border {
  border-style: solid;
  border-width: 1px;
  border-color: $warning-border-color;
  background-color: $warning-background-color;
}

.input {
  height: 40px;
  background-color: $input-background-color;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: $input-border-color;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

.input:focus {
  border-color: grey;
  border-width: 1px;
}

.username_input {
  margin-top: 2px;
}

.password_input_container {
  width: 100%;
  position: relative;
  .input {
    padding-right: 40px;
  }
}

.show_hide_password_btn {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 6px;
  top: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remail_input {
  width: 300px;
}

.checkbox_container {
  display: flex;
  align-items: center;
  margin-top: 18px;
  @media (max-width: 350px) {
    flex-direction: column;
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  overflow: hidden;
  background: $input-background-color;
  margin-right: 10px;
}

.checkbox_text_container {
  display: flex;
}

.submit_button {
  height: 40px;
  background-color: $action-color;
  margin-top: 18px;
  color: $input-background-color;
  border-radius: 50px;
  transition: 100ms;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

.submit_button:active {
  transform: scale(0.96);
  transition: 0ms;
}

.submit_button_disabled {
  height: 40px;
  background-color: grey;
  margin-top: 18px;
  color: $input-background-color;
  border-radius: 50px;
  user-select: none;
  opacity: 0.3;
  transition: 100ms;
  font-weight: 600;
  font-size: 16px;
  pointer-events: none;
  width: 100%;
  cursor: pointer;
}

.success_container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
}

.success_container_background {
  background-color: $popup-background-color;
  height: 580px;
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: center;
  margin: 20px;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  @media (max-width: 480px) {
    height: 100%;
    align-items: flex-start;
    padding-top: 50px;
  }
}

.success_text_container {
  max-width: 500px;
  display: flex;
  margin-bottom: 30px;
}

.remail_error_message_container {
  height: 16px;
  margin-top: 0 !important;
}

.flex_helper {
  display: flex;
  align-items: center;
}

.tooltip_icon {
  margin-left: 3px;
}

.tooltip_list {
  font-weight: 500;
  color: #9b9fa4;
  span {
    color: #242526;
  }
}

.token_expired_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #242526;

  h1 {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 4px;
  }
  
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .resend_link_button {
    background-color: #288CE8;
    height: 40px;
    width: 259px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    margin-top: 32px;
  }

  .edit_email_button {
    color: #288CE8;
    font-weight: 600;
    margin-top: 16px;
  }

  button:active {
    transform: scale(0.97);
  }
  
}

.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 20px;
}

.wallet_explainer_link_container {
  width: 100%;
  text-align: center;
  margin-top: 16px;
  color: #0278E4;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  span {
    cursor: pointer;
  }
}