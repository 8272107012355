/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 600 20px/1.2 "Montserrat", Helvetica, Arial, serif;
$font_type1: 500 14px/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type2: 600 16px/1.25 "Montserrat", Helvetica, Arial, serif;

.block {
  display: flex;
  border: 1px solid rgb(211, 214, 216);
}
.block_layout {
  position: relative;
  overflow: hidden;
  min-height: 404px;
  flex-shrink: 0;
}
.block1 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 16px 16px 16px 16px;
  width: 100%;
  height: 100%;
}
.cover_group {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.cover_group_layout {
  position: relative;
  width: 12px;
  min-width: 12px;
}
.flex {
  @include flex-column;
}
.flex_layout {
  position: relative;
  height: min-content;
  margin: 0px 40px;
}
.flex_item {
  @include flex-column;
  position: relative;
}
.subtitle {
  @include font-face($font_type0, rgb(36, 37, 38), 0px);
}
.subtitle_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.paragraph_body {
  @include font-face($font_type1, rgb(79, 82, 86), 0px);
}
.paragraph_body_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 16px 0px 0px;
}
.block2 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.block2_layout {
  position: relative;
  height: min-content;
  margin: 24px 8px 0px 0px;
}
.text_body {
  @include v-center-content;
  @include font-face($font_type1, rgb(210, 213, 216), 0px);
}
.text_body_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 10px 16px;
}
.text_body1 {
  @include font-face($font_type1, rgb(154, 159, 163), 0px);
}
.text_body1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 40px 0px 0px;
}
.flex1 {
  display: flex;
}
.flex1_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.flex1_item {
  @include flex-column;
  position: relative;
  flex: 0 1 126px;
}
.block3 {
  @include flex-column;
  background-color: rgb(243, 243, 244);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 2px 2px 2px 2px;
}
.block3_layout {
  position: relative;
  overflow: hidden;
  height: 40px;
}
.flex2 {
  display: flex;
}
.flex2_layout {
  position: relative;
  height: min-content;
  margin: auto;
}
.flex2_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}
.icon1 {
  background: var(--src) center center / contain no-repeat;
}
.icon1_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex2_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.paragraph_body_layout1 {
  position: relative;
  flex: 1 0 auto;
  height: min-content;
  margin: 2px 0px;
}
.flex1_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex1_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 125px;
}
.block4 {
  @include flex-column;
  background-color: rgb(243, 243, 244);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 2px 2px 2px 2px;
}
.block4_layout {
  position: relative;
  height: min-content;
}
.flex3 {
  display: flex;
}
.flex3_layout {
  position: relative;
  height: min-content;
  margin: 8px 13px;
}
.flex3_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}
.image10 {
  background: var(--src) center center / contain no-repeat;
}
.image10_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex3_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.paragraph_body_layout2 {
  position: relative;
  flex: 1 0 auto;
  height: min-content;
  margin: 2px 0px;
}
.block5 {
  @include flex-column;
  background-color: rgb(243, 243, 244);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 2px 2px 2px 2px;
}
.block5_layout {
  position: relative;
  height: min-content;
}
.flex4 {
  display: flex;
}
.flex4_layout {
  position: relative;
  height: min-content;
  margin: 8px 5px;
}
.flex4_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 17px;
}
.image7 {
  background: var(--src) center center / contain no-repeat;
}
.image7_layout {
  position: relative;
  height: 24px;
  width: 17px;
  min-width: 17px;
}
.flex4_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.paragraph_body_layout3 {
  position: relative;
  flex: 1 0 auto;
  height: min-content;
  margin: 2px 0px;
}
.flex1_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 125px;
}
.block6 {
  @include flex-column;
  background-color: rgb(243, 243, 244);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 2px 2px 2px 2px;
}
.block6_layout {
  position: relative;
  height: min-content;
}
.flex5 {
  display: flex;
}
.flex5_layout {
  position: relative;
  height: min-content;
  margin: 8px 24px;
}
.flex5_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}
.image4 {
  background: var(--src) center center / contain no-repeat;
}
.image4_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex5_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.paragraph_body_layout4 {
  position: relative;
  flex: 1 0 auto;
  height: min-content;
  margin: 2px 0px;
}
.flex1_item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 26px;
}
.block7 {
  @include flex-column;
  background-color: rgb(243, 243, 244);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 2px 2px 2px 2px;
}
.block7_layout {
  position: relative;
  height: 40px;
}
.paragraph_body_layout5 {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 10px 5px 10px 8px;
}
.block8 {
  @include flex-column;
}
.block8_layout {
  position: relative;
  height: min-content;
  margin: 24px 0px 0px;
}
.group {
  @include flex-column;
}
.group_layout {
  position: relative;
  height: min-content;
}
.decorator {
  background: var(--src) center center / contain no-repeat;
}
.decorator_layout {
  position: absolute;
  top: -1px;
  height: 10px;
  width: 640px;
  right: 0px;
}
.flex6 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 16px 16px 16px 16px;
}
.flex6_layout {
  position: relative;
  height: min-content;
}
.line {
  background-color: rgb(230, 232, 233);
}
.line_layout {
  position: relative;
  height: 1px;
  margin: 1px 0px 0px;
}
.flex6_item {
  @include flex-column;
  position: relative;
}
.flex7 {
  display: flex;
}
.flex7_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 22px 40px 24px auto;
}
.flex7_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.highlights {
  @include h-center-content;
  font: $font_type2;
  color: rgb(154, 159, 163);
  text-align: center;
  letter-spacing: 0px;
}
.highlights_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 10px 16px;
}
.flex7_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex7_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 124px;
}
.block9 {
  @include flex-column;
  background-color: rgb(2, 120, 228);
  border-radius: 20px 20px 20px 20px;
}
.block9_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.highlights1 {
  @include h-center-content;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
}
.highlights1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 10px 32px;
}
