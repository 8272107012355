/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 600 16px/1.25 "Montserrat", Helvetica, Arial, serif;
$font_type1: 600 40px/0.95 "Montserrat", Helvetica, Arial, serif;
$font_type2: 600 14px/1.28 "Montserrat", Helvetica, Arial, serif;
$font_type3: 600 40px/1.2 "Montserrat", Helvetica, Arial, serif;
$font_type4: 500 16px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type5: 700 12px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type6: 500 14px/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type7: 500 12px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type8: 500 1em/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type9: 500 12px/1.5 "SF Pro", Helvetica, Arial, serif;
$font_type10: 600 20px/1.2 "Montserrat", Helvetica, Arial, serif;

.section1_layout {
  position: relative;
  height: min-content;
}
.section1 {
  flex-direction: column;
}
.group {
  @include flex-column;
}
.image49 {
  background: var(--src) center center / contain no-repeat;
}
.image49_layout {
  position: absolute;
  z-index: 1;
  top: 23px;
  height: 44px;
  width: 44px;
  right: 30px;
  @include xs {
    height: 40px;
    width: 40px;
  }
}
.group_layout {
  position: relative;
  height: min-content;
}
.flex {
  display: flex;
  background: var(--src) center center / auto 100% repeat-x;
  @include lg {
    flex-wrap: wrap;
    row-gap: 0px;
  }
}
.flex_layout {
  position: relative;
  height: min-content;
}
.flex_item {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
    flex: 0 0 100%;
  }
}
.block33 {
  @include flex-column;
}
.block33_layout {
  position: relative;
  flex-grow: 1;
  min-height: 800px;
  flex-shrink: 0;
}
.flex_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 550px;
  z-index: 99;
  @include lg {
    flex: 0 0 100%;
  }
}
.block25 {
  @include flex-column;
}
.block25_layout {
  position: relative;
  flex-grow: 1;
  @include lg {
    width: 100vw;
  }
}
.group1 {
  @include flex-column;
  background-color: 00000000;
}
.group1_layout {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -1500px;
  right: 0px;
  @include lg {
    left: 0px;
  }
}
.image2 {
  background: var(--src) center center / 100% 100% no-repeat;
}
.image2_layout {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 1px;
  right: 0px;
}
.block331 {
  @include flex-column;
  top: 0px;
  position: sticky !important;
  @include lg {
    position: relative;
  }
}
.block331_layout {
  position: relative;
  flex-grow: 1;
  margin: 72px 0px 0px;
  @include lg {
    z-index: 1;
  }
}
.block39 {
  @include flex-column;
  @include lg {
    margin-bottom: -180px;
    left: unset !important;
    right: unset !important;
    margin-left: 25%;
    margin-right: 25%;
    position: relative !important;
  }
  @include md {
    margin-left: 12.5%;
    margin-right: 12.5%;
  }
  @include xxs {
    margin-left: 24px;
    margin-right: 24px;
  }
}
.block39_layout {
  position: absolute;
  top: 0px;
  bottom: 40px;
  left: 40px;
  right: -39px;
  align-items: flex-end;
  @include lg {
    align-items: unset;
  }
}
.block32 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 11px 13px 22px 0px rgba(0, 0, 0, 0.08627450980392157);
  border-radius: 8px 8px 8px 8px;
  top: 72px !important;
  position: sticky !important;
}
.block32_layout {
  position: absolute;
  top: 0px;
  height: min-content;
  left: 0px;
  right: 0px;
  width: calc(48vh);
  max-width: 549px;
  @include lg {
    width: auto;
    max-width: unset;
    left: 225px;
    right: 225px;
  }
}
.block32_item {
  @include flex-column;
  position: relative;
  @include lg {
    flex: 0 1 auto;
    display: none;
  }
}
.image23 {
  background: var(--src) center center / contain no-repeat;
  min-height: min(630px, calc(100vh - 72px - 40px - 168px));
}
.image23_layout {
  position: relative;
  flex-grow: 1;
}
.block32_item1 {
  @include flex-column;
  position: relative;
  @include max {
    flex: 0 1 633px;
    display: none;
  }
  @include lg {
    flex: 0 1 auto;
    display: flex;
  }
}
.image231 {
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.image231_layout {
  position: relative;
}
.block32_item2 {
  @include flex-column;
  position: relative;
}
.line2 {
  background-color: rgb(230, 232, 233);
}
.line2_layout {
  position: relative;
  height: 1px;
}
.block32_item3 {
  @include flex-column;
  position: relative;
}
.flex1 {
  flex-direction: column;
}
.flex1_layout {
  position: relative;
  height: min-content;
}
.flex90 {
  display: flex;
  @include sm {
    flex-wrap: wrap;
    row-gap: 0px;
  }
}
.flex90_layout {
  position: relative;
  height: min-content;
  margin: 0px 24px;
}
.flex90_item {
  @include flex-column;
  position: relative;
  flex: 1 1 227px;
  @include sm {
    flex: 0 0 100%;
  }
}
.flex2 {
  @include flex-column;
}
.flex2_layout1 {
  position: relative;
  height: min-content;
  margin: 24px;
  @include sm {
    margin: 24px 24px 12px;
  }
}
.flex79 {
  @include flex-column;
  @include xxs {
    flex-direction: row;
  }
}
.flex79_layout {
  position: relative;
  height: min-content;
  margin: 0px 8px;
}
.flex79_item {
  @include flex-column;
  position: relative;
}
.highlights4 {
  @include font-face($font_type0, rgb(154, 159, 163), 0px);
}
.highlights4_layout {
  position: relative;
  height: min-content;
}
.flex79_item1 {
  @include flex-column;
  position: relative;
  @include xxs {
    flex: 1 0 auto;
  }
}
.flex3 {
  display: flex;
}
.flex3_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 8px auto 0px;
  @include max {
    margin: 8px auto 0px 0px;
  }
  @include sm {
    margin: 8px 0px 0px auto;
  }
}
.flex3_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xxs {
    flex: 1 0 auto;
    min-width: unset;
  }
}
.hero_title {
  display: flex;
  align-items: flex-end;
  @include font-face($font_type1, rgb(36, 37, 38), 0.8px);
  @include xs {
    font-size: 32px;
    text-align: left;
  }
}
.hero_title_layout {
  position: relative;
  flex-grow: 1;
  min-height: 38px;
  flex-shrink: 0;
  min-width: 0px;
  width: fit-content;
  margin: 0px 0px 0px auto;
}
.flex3_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.flex3_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.text_body6 {
  @include v-center-content;
  @include font-face($font_type2, rgb(36, 37, 38), 0px);
}
.text_body6_layout {
  position: relative;
  height: min-content;
  margin: 20px 0px 0px;
}
.flex3_item2 {
  @include flex-column;
  position: relative;
}
.group2 {
  @include flex-column;
  background-color: rgb(2, 120, 228);
  border-radius: 20px 20px 20px 20px;
}
.group2_layout {
  position: relative;
  height: min-content;
  margin: 12px 0px 0px;
}
.highlights5 {
  @include h-center-content;
  font: $font_type0;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
}
.highlights5_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 10px auto;
}
.flex3_spacer1 {
  @include flex-column;
  position: relative;
  @include sm {
    display: none;
    flex: 0 0 16px;
  }
}
.flex3_item3 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @include sm {
    display: none;
    flex: 0 0 1px;
    min-width: unset;
  }
}
.image24 {
  background: var(--src) center center / contain no-repeat;
}
.image24_layout {
  position: relative;
  height: 166px;
}
.flex3_spacer2 {
  @include flex-column;
  position: relative;
  @include sm {
    display: none;
  }
}
.flex3_item4 {
  @include flex-column;
  position: relative;
  flex: 1 1 227px;
  @include sm {
    flex: 0 0 100%;
  }
}
.flex4 {
  @include flex-column;
}
.flex4_layout {
  position: relative;
  height: min-content;
  margin: 24px;
  @include sm {
    margin: 0px 24px 24px;
  }
}
.flex4_item {
  @include flex-column;
  position: relative;
  @include sm {
    flex: 0 1 auto;
    display: none;
  }
}
.highlights3 {
  display: -webkit-box;
  overflow: hidden;
  @include font-face($font_type0, rgb(187, 192, 197), 0px);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.highlights3_box_layout {
  position: relative;
  height: min-content;
  margin: 0px 8px;
}
.flex5 {
  display: flex;
}
.flex5_layout {
  position: relative;
  height: min-content;
  margin: 8px 8px 0px;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type1, rgb(154, 159, 163), 0.8px);
}
.hero_title1_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.flex5_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.text_body2 {
  @include v-center-content;
  @include font-face($font_type2, rgb(154, 159, 163), 0px);
}
.text_body2_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 20px 0px 0px;
}
.flex5_item {
  @include flex-column;
  position: relative;
}
.group3 {
  @include flex-column;
  border: 1px solid rgb(40, 140, 232);
  border-radius: 20px 20px 20px 20px;
}
.group3_layout {
  position: relative;
  height: min-content;
  margin: 12px 0px 0px;
  @include sm {
    margin: 0px;
  }
}
.highlights6 {
  @include h-center-content;
  font: $font_type0;
  color: rgb(2, 120, 228);
  text-align: center;
  letter-spacing: 0px;
}
.highlights6_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 10px auto;
}
.flex5_spacer1 {
  @include flex-column;
  position: relative;
  @include lg {
    display: none;
  }
}
.flex5_item1 {
  background-color: rgb(255, 255, 255);
  @include flex-column;
  position: relative;
  flex: 0 1 825px;
  @include lg {
    flex: 0 0 100%;
  }
}
.block26 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
}
.block26_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 119px;
  @include lg {
    margin: 0px;
  }
}
.block27 {
  @include flex-column;
}
.block27_layout {
  position: relative;
  height: min-content;
  @include xl {
    margin: 0px 40px 0px 0px;
  }
  @include lg {
    margin: 0px;
  }
  @include sm {
    margin: 0px 40px;
  }
  @include xxs {
    margin: 0px 24px;
  }
}
.flex11 {
  @include flex-column;
  width: 100%;
  height: 100%;
}
.block6 {
  display: flex;
}
.block6_layout {
  position: relative;
  height: min-content;
}
.flex12 {
  @include flex-column;
}
.flex12_layout {
  position: relative;
  flex: 1 1 auto;
  height: min-content;
}
.highlights {
  @include font-face($font_type0, rgb(79, 82, 86), 0px);
}
.highlights_layout {
  position: relative;
  height: min-content;
}
.hero_title2 {
  @include v-center-content;
  @include font-face($font_type3, rgb(36, 37, 38), -0.4px);
  @include xs {
    font-size: 32px;
    text-align: left;
  }
}
.hero_title2_layout {
  position: relative;
  height: min-content;
  margin: 12px 0px 0px;
}
.highlights1 {
  @include font-face($font_type4, rgb(154, 159, 163), 0px);
}
.highlights1_layout {
  position: relative;
  height: min-content;
  margin: 12px 0px 0px;
}
.block38 {
  @include flex-column;
  border: 1px solid rgb(211, 214, 216);
  border-radius: 20px 20px 20px 20px;
}
.block38_layout {
  position: relative;
  height: min-content;
  margin: 36px 0px 40px;
}
.image3 {
  background: var(--src) center center / contain no-repeat;
  border-width: 0px;
}
.image3_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 7px 15px;
}
.flex7 {
  @include flex-column;
}
.flex7_layout {
  position: relative;
  height: min-content;
  margin: 48px 0px 0px;
}
.small_text_body {
  @include font-face($font_type5, rgb(187, 192, 197), 0px);
}
.small_text_body_layout {
  position: relative;
  height: min-content;
}
.highlights2 {
  @include font-face($font_type4, rgb(79, 82, 86), 0px);
}
.highlights2_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.block7 {
  display: flex;
  @include xxs {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.block7_layout {
  position: relative;
  height: min-content;
  margin: 40px 0px 0px;
}
.block7_item {
  @include flex-column;
  position: relative;
  flex: 0 1 373px;
  @include xxs {
    flex: 0 0 100%;
  }
}
.flex8 {
  @include flex-column;
}
.flex8_layout {
  position: relative;
  height: min-content;
  margin: 0px 40px 40px 0px;
  @include xxs {
    margin: 0px;
  }
}
.icon {
  background: var(--src) center center / contain no-repeat;
}
.icon_layout {
  position: relative;
  height: 56px !important;
  width: 56px !important;
  min-width: 56px;
  margin: 16px 0px 0px;
}
.highlights_layout1 {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.paragraph_body {
  @include font-face($font_type6, rgb(79, 82, 86), 0px);
}
.paragraph_body_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.flex8_item {
  @include flex-column;
  position: relative;
}
.cover_block21 {
  @include flex-column;
  background-color: rgb(2, 120, 228);
  border-radius: 16px 16px 16px 16px;
}
.cover_block21_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 8px 0px 0px;
}
.text_body {
  @include h-center-content;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
}
.text_body_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 7px 29px;
}
.flex8_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include sm;
  @include xxs {
    display: none;
  }
}
.flex8_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 373px;
  @include xxs {
    flex: 0 0 100%;
  }
}
.flex9 {
  @include flex-column;
}
.flex9_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 40px 40px;
  @include sm {
    margin: 0px 0px 40px;
  }
}
.highlights_layout2 {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.flex9_item {
  @include flex-column;
  position: relative;
}
.block29 {
  @include flex-column;
}
.block29_layout {
  position: relative;
  min-height: 20px;
  flex-shrink: 0;
}
.block28 {
  @include flex-column;
}
.block28_layout {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flex6 {
  display: flex;
}
.flex6_layout {
  position: relative;
  height: min-content;
}
.flex6_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.flex6_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 40.5px;
  @include xxs {
    flex: 1 1 auto;
  }
}
.flex6_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
}
.image4 {
  background: var(--src) center center / contain no-repeat;
}
.image4_layout {
  position: relative;
  height: 20px;
  width: 2px;
  min-width: 2px;
}
.highlights31 {
  @include font-face($font_type0, 0px);
}
.highlights31_layout {
  position: relative;
  height: min-content;
}
.flex6_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 2px;
}
.highlights32 {
  @include font-face($font_type0, rgb(187, 192, 197), 0px);
}
.highlights32_layout {
  position: relative;
  height: min-content;
}
.block8 {
  @include flex-column;
}
.block8_layout {
  position: relative;
  height: min-content;
  margin: 40px 0px 0px;
}
.flex13 {
  display: flex;
  @include xs {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.flex13_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex13_item {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
}
.cover_block10 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.cover_block10_layout {
  position: relative;
  height: min-content;
}
.flex10 {
  @include flex-column;
}
.flex10_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex14 {
  display: flex;
}
.flex14_layout {
  position: relative;
  height: min-content;
}
.image6 {
  background: var(--src) center center / contain no-repeat;
}
.image6_layout {
  position: relative;
  flex: 0 0 auto;
  height: 6px;
  width: 6px;
  min-width: 6px;
}
.flex1_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.icon1 {
  background: var(--src) center center / contain no-repeat;
}
.icon1_layout {
  position: relative;
  flex: 0 0 auto;
  height: 6px;
  width: 6px;
  min-width: 6px;
}
.flex2_layout {
  position: relative;
  height: min-content;
  margin: 16px 8px;
}
.small_text_body1 {
  display: -webkit-box;
  overflow: hidden;
  @include font-face($font_type7, rgb(79, 83, 86), 0px);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.small_text_body1_box_layout {
  position: relative;
  height: min-content;
}
.text_body1 {
  display: -webkit-box;
  overflow: hidden;
  @include font-face($font_type2, rgb(79, 83, 86), 0px);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.text_body1_box_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.small_text_body2 {
  display: flex;
  justify-content: flex-end;
  font: $font_type7;
  color: rgb(154, 159, 163);
  text-align: right;
  letter-spacing: 0px;
}
.small_text_body2_layout {
  position: relative;
  height: min-content;
  margin: 6px 0px 0px;
}
.flex3_layout1 {
  position: relative;
  height: min-content;
}
.image7 {
  background: var(--src) center center / contain no-repeat;
}
.image7_layout {
  position: relative;
  flex: 0 0 auto;
  height: 6px;
  width: 6px;
  min-width: 6px;
}
.flex3_spacer3 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex3_spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    flex: 0 0 20px;
    min-width: unset;
  }
}
.flex3_item5 {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
}
.flex15 {
  @include flex-column;
}
.flex15_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex16 {
  display: flex;
}
.flex16_layout {
  position: relative;
  height: min-content;
}
.small_text_body11_box_layout {
  position: relative;
  height: min-content;
}
.text_body11_box_layout {
  position: relative;
  height: min-content;
  margin: 6px 0px 0px;
}
.flex3_layout2 {
  position: relative;
  height: min-content;
}
.flex3_spacer5 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex3_spacer6 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    display: none;
  }
}
.flex3_item6 {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
  @include xxs {
    flex: 0 0 calc(50% - 8px);
  }
}
.flex17 {
  @include flex-column;
}
.flex17_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex18 {
  display: flex;
}
.flex18_layout {
  position: relative;
  height: min-content;
}
.flex3_layout3 {
  position: relative;
  height: min-content;
}
.flex3_spacer7 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex3_spacer8 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    flex: 0 0 20px;
    min-width: unset;
  }
  @include xxs {
    flex: 0 0 16px;
  }
}
.flex3_item7 {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
  @include xxs {
    flex: 0 0 calc(50% - 8px);
  }
}
.flex19 {
  @include flex-column;
}
.flex19_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex110 {
  display: flex;
}
.flex110_layout {
  position: relative;
  height: min-content;
}
.flex3_layout4 {
  position: relative;
  height: min-content;
}
.flex3_spacer9 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex20 {
  @include flex-column;
}
.flex20_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex111 {
  display: flex;
}
.flex111_layout {
  position: relative;
  height: min-content;
}
.flex3_layout5 {
  position: relative;
  height: min-content;
}
.flex3_spacer10 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex3_spacer11 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    flex: 0 0 20px;
    min-width: unset;
  }
}
.flex3_item8 {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
}
.flex21 {
  @include flex-column;
}
.flex21_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex112 {
  display: flex;
}
.flex112_layout {
  position: relative;
  height: min-content;
}
.small_text_body11 {
  @include font-face($font_type7, rgb(79, 82, 86), 0px);
}
.small_text_body11_layout {
  position: relative;
  height: min-content;
}
.text_body11 {
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body11_layout {
  position: relative;
  height: min-content;
  margin: 6px 0px 0px;
}
.flex3_layout6 {
  position: relative;
  height: min-content;
}
.flex3_spacer12 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex3_spacer13 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    display: none;
  }
}
.flex3_item9 {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
}
.flex22 {
  @include flex-column;
}
.flex22_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex113 {
  display: flex;
}
.flex113_layout {
  position: relative;
  height: min-content;
}
.small_text_body12_box_layout {
  position: relative;
  height: min-content;
}
.text_body12_box_layout {
  position: relative;
  height: min-content;
  margin: 6px 0px 0px;
}
.flex3_layout7 {
  position: relative;
  height: min-content;
}
.flex3_spacer14 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex3_spacer15 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    flex: 0 0 20px;
    min-width: unset;
  }
}
.flex3_item10 {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
}
.flex23 {
  @include flex-column;
}
.flex23_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex114 {
  display: flex;
}
.flex114_layout {
  position: relative;
  height: min-content;
}
.flex3_layout8 {
  position: relative;
  height: min-content;
}
.flex3_spacer16 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex24 {
  @include flex-column;
}
.flex24_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex115 {
  display: flex;
}
.flex115_layout {
  position: relative;
  height: min-content;
}
.flex3_layout9 {
  position: relative;
  height: min-content;
}
.flex3_spacer17 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex3_spacer18 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    flex: 0 0 20px;
    min-width: unset;
  }
}
.flex3_item11 {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
}
.flex25 {
  @include flex-column;
}
.flex25_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex116 {
  display: flex;
}
.flex116_layout {
  position: relative;
  height: min-content;
}
.flex3_layout10 {
  position: relative;
  height: min-content;
}
.flex3_spacer19 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex3_spacer20 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    display: none;
  }
}
.flex3_item12 {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
}
.flex26 {
  @include flex-column;
}
.flex26_layout {
  position: relative;
  height: min-content;
  margin: 8px;
}
.flex117 {
  display: flex;
}
.flex117_layout {
  position: relative;
  height: min-content;
}
.flex3_layout11 {
  position: relative;
  height: min-content;
}
.flex3_spacer21 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex3_spacer22 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include sm;
  @include xs {
    flex: 0 0 20px;
    min-width: unset;
  }
}
.flex3_item13 {
  @include flex-column;
  position: relative;
  flex: 1 1 165px;
  @include xs {
    flex: 0 0 calc(50% - 10px);
  }
}
.image8 {
  background: var(--src) center center / contain no-repeat;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.058823529411764705));
  border-radius: 8px 8px 8px 8px;
}
.image8_layout {
  position: relative;
  height: 106px;
}
.block9 {
  @include flex-column;
}
.block9_layout {
  position: relative;
  height: min-content;
  margin: 64px 0px 0px;
}
.flex161 {
  display: flex;
  @include xxs {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.flex161_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex16_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xxs {
    flex: 0 0 40px;
  }
}
.icon3 {
  background: var(--src) center center / contain no-repeat;
}
.icon3_layout {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.flex16_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include xxs {
    flex: 0 0 8px;
    min-width: unset;
  }
}
.flex16_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xxs {
    flex: 0 0 190px;
  }
}
.highlights7 {
  @include v-center-content;
  @include font-face($font_type0, rgb(79, 82, 86), 0px);
}
.highlights7_layout {
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  flex-shrink: 0;
}
.flex16_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
  @include xxs {
    flex: 0 0 16px;
    min-width: unset;
  }
}
.cover_block9 {
  background: var(--src) center center / contain no-repeat;
}
.cover_block9_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin: 10px 0px;
}
.flex16_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include xxs {
    display: none;
  }
}
.paragraph_body_layout1 {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex16_item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.text_body21 {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type2;
  color: rgb(154, 159, 163);
  text-align: right;
  letter-spacing: 0px;
  width: 100%;
  height: 100%;
}
.block10 {
  @include flex-column;
}
.block10_layout {
  position: relative;
  height: min-content;
  width: 100%;
  margin: 64px 0px 0px;
}
.flex171 {
  display: flex;
}
.flex171_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex17_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}
.image47 {
  background: var(--src) center center / contain no-repeat;
}
.image47_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex17_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.highlights8 {
  @include v-center-content;
  @include font-face($font_type0, rgb(79, 82, 86), 0px);
}
.highlights8_layout {
  position: relative;
  flex: 1 1 auto;
  min-height: 20px;
  flex-shrink: 0;
  margin: 2px 0px;
}
.flex181 {
  display: flex;
}
.flex181_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex18_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}
.image46 {
  background: var(--src) center center / contain no-repeat;
}
.image46_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex18_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.highlights9 {
  @include v-center-content;
  @include font-face($font_type0, rgb(79, 82, 86), 0px);
}
.highlights9_layout {
  position: relative;
  flex: 1 1 auto;
  min-height: 20px;
  flex-shrink: 0;
  margin: 0px 0px 1px;
}
.flex191 {
  display: flex;
}
.flex191_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex19_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}
.cover_block7 {
  background: var(--src) center center / contain no-repeat;
}
.cover_block7_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 2px 0px;
}
.flex19_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}
.highlights10 {
  @include v-center-content;
  @include font-face($font_type0, rgb(79, 82, 86), 0px);
}
.highlights10_layout {
  position: relative;
  flex: 1 1 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.flex201 {
  display: flex;
  @include sm {
    flex-wrap: wrap;
    row-gap: 40px;
  }
}
.flex201_layout {
  position: relative;
  height: min-content;
  margin: 64px 0px 0px;
  @include sm {
    margin: 40px 0px 0px;
  }
}
.flex20_item {
  @include flex-column;
  position: relative;
  flex: 1 1 333px;
  @include sm {
    flex: 0 0 100%;
  }
}
.block23 {
  @include flex-column;
}
.block23_layout {
  position: relative;
  height: min-content;
}
.flex182 {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
}
.flex182_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex18_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block5 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block5_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.text_body3 {
  @include font-face($font_type6, rgb(2, 120, 228), 0px);
}
.text_body3_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 4px 10px;
}
.flex18_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block61 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block61_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.flex18_item3 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block71 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block71_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.flex18_spacer1 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include sm {
    display: none;
  }
}
.flex18_item4 {
  @include flex-column;
  position: relative;
  flex: 1 1 373px;
  @include sm {
    flex: 0 0 100%;
  }
}
.block24 {
  @include flex-column;
}
.block24_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 40px;
  @include sm {
    margin: 0px;
  }
  @include xs {
    margin: 0px 1.5px;
  }
}
.flex192 {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
}
.flex192_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.flex19_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block81 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block81_layout {
  position: relative;
  height: min-content;
}
.flex19_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block91 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block91_layout {
  position: relative;
  height: min-content;
}
.flex19_item3 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block101 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block101_layout {
  position: relative;
  height: min-content;
}
.flex19_item4 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.block11 {
  @include flex-column;
  border: 1px solid rgb(78, 161, 236);
  border-radius: 13px 13px 13px 13px;
}
.block11_layout {
  position: relative;
  height: min-content;
}
.flex101 {
  @include flex-column;
}
.flex101_layout {
  position: relative;
  height: min-content;
  margin: 48px 0px 0px;
  @include sm {
    margin: 40px 0px 0px;
  }
}
.flex10_item {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
}
.flex162 {
  display: flex;
}
.flex162_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 16px 0px 0px;
}
.flex16_item4 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xxs {
    min-width: unset;
  }
}
.icon3_layout1 {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex16_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include xxs;
}
.flex16_item5 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.icon3_layout2 {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex16_spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include xxs;
}
.flex16_item6 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.icon3_layout3 {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex16_spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include xxs;
}
.flex16_item7 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.icon3_layout4 {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.text_body4 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type6, rgb(0, 0, 0), 0px);
  white-space: pre-wrap;
}
.text_body4_box_layout {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.text_body4_span0 {
  @include font-face($font_type8, #9a9fa3ff, 0px);
}
.text_body4_span1 {
  @include font-face($font_type8, #0278e4ff, 0px);
}
.text_body4_span2 {
  @include font-face($font_type8, #9a9fa3ff, 0px);
}
.flex16_item8 {
  @include flex-column;
  position: relative;
  flex: 0 1 336px;
}
.flex27 {
  @include flex-column;
}
.flex27_layout {
  position: relative;
  height: min-content;
  width: 100%;
  margin: 23px 0px 0px;
}
.block51 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 8px 8px 8px 8px;
}
.block51_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
}
.flex78 {
  display: flex;
}
.flex78_layout {
  position: relative;
  height: min-content;
  margin: 0px 16px 0px 24px;
  @include xxs {
    margin: 0px 16px;
  }
}
.flex78_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xs {
    min-width: unset;
  }
}
.block34 {
  display: flex;
}
.block34_layout {
  position: relative;
  height: min-content;
}
.block34_item {
  @include flex-column;
  position: relative;
  @include xs {
    flex: 0 1 auto;
  }
}
.flex791 {
  @include flex-column;
}
.flex791_layout {
  position: relative;
  height: min-content;
  margin: 13px 0px;
}
.text_body12 {
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body12_layout {
  position: relative;
  height: min-content;
}
.small_text_body12 {
  @include font-face($font_type9, rgb(154, 159, 163), 0px);
}
.small_text_body12_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px 0px;
}
.flex79_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
  @include max {
    display: none;
  }
  @include xs {
    display: flex;
  }
}
.flex163 {
  display: flex;
  @include xxs {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.flex163_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.icon1_layout1 {
  position: relative;
  height: 32px !important;
  width: 32px !important;
  min-width: 32px;
}
.flex16_item9 {
  @include flex-column;
  position: relative;
  flex: 1 0 auto;
  @include xxs {
    flex: 0 0 calc(100% - 48px);
  }
}
.text_body13 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body13_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_spacer5 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
  @include xs;
  @include tn {
    min-width: 12px;
  }
}
.flex16_item10 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @include xs;
}
.image61 {
  background: var(--src) center center / cover no-repeat;
}
.image61_layout {
  position: relative;
  flex-grow: 1;
  width: 1px;
  min-width: 1px;
}
.flex16_spacer6 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include xs {
    display: none;
  }
}
.flex16_item11 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 32px;
  margin: auto;
  @include xs {
    display: none;
  }
}
.icon1_layout2 {
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
  margin: 17px 0px;
}
.flex16_spacer7 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include xs {
    display: none;
  }
}
.flex16_item12 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xs {
    min-width: unset;
    display: none;
  }
}
.text_body14 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body14_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_spacer8 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include tn {
    flex: 1 0 12px;
  }
}
.flex16_item13 {
  @include flex-column;
  position: relative;
  @include tn {
    flex: 0 1 auto;
  }
}
.subtitle {
  @include v-center-content;
  @include font-face($font_type10, rgb(36, 37, 38), 0px);
}
.subtitle_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_spacer9 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.flex16_item14 {
  @include flex-column;
  position: relative;
}
.text_body22 {
  @include v-center-content;
  @include font-face($font_type2, rgb(36, 37, 38), 0px);
}
.text_body22_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_item15 {
  @include flex-column;
  position: relative;
  flex: 0 1 66px;
}
.block52 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 8px 8px 8px 8px;
}
.block52_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
  margin: 24px 0px 0px;
}
.flex792 {
  @include flex-column;
}
.flex792_layout {
  position: relative;
  height: min-content;
  margin: 13px 0px;
}
.text_body15 {
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body15_layout {
  position: relative;
  height: min-content;
}
.small_text_body13 {
  @include font-face($font_type9, rgb(154, 159, 163), 0px);
}
.small_text_body13_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px 0px;
}
.flex79_item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
  @include max {
    display: none;
  }
  @include xs {
    display: flex;
  }
}
.flex164 {
  display: flex;
  @include xxs {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.flex164_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.icon1_layout3 {
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
}
.flex16_item16 {
  @include flex-column;
  position: relative;
  flex: 1 0 auto;
  @include xxs {
    flex: 0 0 calc(100% - 48px);
  }
}
.text_body16 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body16_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_spacer10 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
  @include xs;
  @include tn {
    min-width: 12px;
  }
}
.flex16_item17 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @include xs;
}
.image62 {
  background: var(--src) center center / cover no-repeat;
}
.image62_layout {
  position: relative;
  flex-grow: 1;
  width: 1px;
  min-width: 1px;
}
.flex16_spacer11 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include xs {
    display: none;
  }
}
.flex16_item18 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 32px;
  @include xs {
    display: none;
  }
}
.icon1_layout4 {
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
  margin: 17px 0px;
}
.flex16_spacer12 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include xs {
    display: none;
  }
}
.flex16_item19 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xs {
    min-width: unset;
    display: none;
  }
}
.text_body17 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body17_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_spacer13 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include tn {
    flex: 1 0 12px;
  }
}
.flex16_item20 {
  @include flex-column;
  position: relative;
  @include tn {
    flex: 0 1 auto;
  }
}
.flex16_spacer14 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.flex16_item21 {
  @include flex-column;
  position: relative;
}
.text_body23 {
  @include v-center-content;
  @include font-face($font_type2, rgb(36, 37, 38), 0px);
}
.text_body23_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_item22 {
  @include flex-column;
  position: relative;
  flex: 0 1 66px;
}
.block53 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 8px 8px 8px 8px;
}
.block53_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
  margin: 24px 0px 0px;
}
.flex793 {
  @include flex-column;
}
.flex793_layout {
  position: relative;
  height: min-content;
  margin: 13px 0px;
}
.text_body18 {
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body18_layout {
  position: relative;
  height: min-content;
}
.small_text_body14 {
  @include font-face($font_type9, rgb(154, 159, 163), 0px);
}
.small_text_body14_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px 0px;
}
.flex79_item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
  @include max {
    display: none;
  }
  @include xs {
    display: flex;
  }
}
.flex165 {
  display: flex;
  @include xxs {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.flex165_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.icon1_layout5 {
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
}
.flex16_item23 {
  @include flex-column;
  position: relative;
  flex: 1 0 auto;
  @include xxs {
    flex: 0 0 calc(100% - 48px);
  }
}
.text_body19 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body19_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_spacer15 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
  @include xs;
  @include tn {
    min-width: 12px;
  }
}
.flex16_item24 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @include xs;
}
.image63 {
  background: var(--src) center center / cover no-repeat;
}
.image63_layout {
  position: relative;
  flex-grow: 1;
  width: 1px;
  min-width: 1px;
}
.flex16_spacer16 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include xs {
    display: none;
  }
}
.flex16_item25 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 32px;
  @include xs {
    display: none;
  }
}
.icon1_layout6 {
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
  margin: 17px 0px;
}
.flex16_spacer17 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include xs {
    display: none;
  }
}
.flex16_item26 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xs {
    min-width: unset;
    display: none;
  }
}
.text_body110 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body110_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_spacer18 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include tn {
    flex: 1 0 12px;
  }
}
.flex16_item27 {
  @include flex-column;
  position: relative;
  @include tn {
    flex: 0 1 auto;
  }
}
.flex16_spacer19 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.flex16_item28 {
  @include flex-column;
  position: relative;
}
.text_body24 {
  @include v-center-content;
  @include font-face($font_type2, rgb(36, 37, 38), 0px);
}
.text_body24_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_item29 {
  @include flex-column;
  position: relative;
  flex: 0 1 66px;
}
.block54 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 8px 8px 8px 8px;
}
.block54_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
  margin: 24px 0px 0px;
}
.flex794 {
  @include flex-column;
}
.flex794_layout {
  position: relative;
  height: min-content;
  margin: 13px 0px;
}
.text_body111 {
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body111_layout {
  position: relative;
  height: min-content;
}
.small_text_body15 {
  @include font-face($font_type9, rgb(154, 159, 163), 0px);
}
.small_text_body15_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px 0px;
}
.flex79_item5 {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
  @include max {
    display: none;
  }
  @include xs {
    display: flex;
  }
}
.flex166 {
  display: flex;
  @include xxs {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.flex166_layout {
  position: relative;
  height: min-content;
  margin: 8px 0px 0px;
}
.icon1_layout7 {
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
}
.flex16_item30 {
  @include flex-column;
  position: relative;
  flex: 1 0 auto;
  @include xxs {
    flex: 0 0 calc(100% - 48px);
  }
}
.text_body112 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body112_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_spacer20 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
  @include xs;
  @include tn {
    min-width: 12px;
  }
}
.flex16_item31 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @include xs;
}
.image64 {
  background: var(--src) center center / cover no-repeat;
}
.image64_layout {
  position: relative;
  flex-grow: 1;
  width: 1px;
  min-width: 1px;
}
.flex16_spacer21 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 16px;
  @include xs {
    display: none;
  }
}
.flex16_item32 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 32px;
  @include xs {
    display: none;
  }
}
.icon1_layout8 {
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
  margin: 17px 0px;
}
.flex16_spacer22 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
  @include xs {
    display: none;
  }
}
.flex16_item33 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include xs {
    min-width: unset;
    display: none;
  }
}
.text_body113 {
  @include v-center-content;
  @include font-face($font_type2, rgb(79, 82, 86), 0px);
}
.text_body113_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_spacer23 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include tn {
    flex: 1 0 12px;
  }
}
.flex16_item34 {
  @include flex-column;
  position: relative;
  @include tn {
    flex: 0 1 auto;
  }
}
.flex16_spacer24 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.flex16_item35 {
  @include flex-column;
  position: relative;
}
.text_body25 {
  @include v-center-content;
  @include font-face($font_type2, rgb(36, 37, 38), 0px);
}
.text_body25_layout {
  position: relative;
  flex-grow: 1;
}
.flex16_item36 {
  background-color: rgb(255, 255, 255);
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
    flex: 0 0 100%;
  }
}
