/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 700 14px/1.28 "Montserrat", Helvetica, Arial, serif;
$font_type1: 500 14px/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type2: 500 14px/1.3 "Montserrat", Helvetica, Arial, serif;
$font_type3: 500 12px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type4: 600 14px/1.28 "Montserrat", Helvetica, Arial, serif;
$font_type5: 600 32px/1.18 "Montserrat", Helvetica, Arial, serif;
$font_type6: 500 1em/1.42 "Montserrat", Helvetica, Arial, serif;
$font_type7: 500 16px/1.25 "Montserrat", Helvetica, Arial, serif;
$font_type8: 700 12px/1.5 "Montserrat", Helvetica, Arial, serif;
$font_type9: 600 16px/1.25 "Montserrat", Helvetica, Arial, serif;

.block {
  display: flex;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(211, 214, 216);
}
.block_layout {
  position: absolute;
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.block33 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
}
.block33_layout {
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  min-height: 1345px;
  flex-shrink: 0;
}
.block_item {
  @include flex-column;
  position: relative;
  flex: 0 1 344px;
}
.block331 {
  @include flex-column;
  background: var(--src) center center / 100% 100% no-repeat;
  border: 1px solid rgb(155, 155, 155);
  border-width: 0px;
  border-right-width: 1px;
}
.block331_layout {
  position: absolute;
  overflow: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flex8 {
  @include flex-column;
}
.flex8_layout {
  position: relative;
  height: min-content;
  margin: 100px 0px 0px;
  @include lg {
    margin: 89px 0px 0px;
  }
}
.block24 {
  @include flex-column;
  width: 100%;
  height: 100%;
}
.text_body2 {
  @include h-center-content;
  font: $font_type0;
  color: rgb(187, 192, 197);
  text-align: center;
  letter-spacing: 0px;
}
.text_body2_layout {
  position: relative;
  height: min-content;
  width: 111px;
  min-width: 111px;
  margin: 0px auto;
  white-space: nowrap;
}
.flex9 {
  @include flex-column;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 8px 8px;
}
.flex9_layout {
  position: relative;
  min-height: 480px;
  flex-shrink: 0;
  margin: 16px 0px 0px;
}
.content_box11 {
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 0px 0px;
  @include lg {
    flex-wrap: wrap;
    row-gap: 0px;
  }
  border-width: 0px;
}
.content_box11_layout {
  position: relative;
  height: min-content;
}
.content_box11_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 118px;
  @include lg {
    flex: 0 0 100%;
    min-width: unset;
  }
}
.content_box10 {
  @include flex-column;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 0px 0px;
  border-width: 0px;
  border-bottom-width: 1px;
}
.content_box10_layout {
  position: relative;
  height: min-content;
}
.block21 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 30px 30px 30px 30px;
}
.block21_layout {
  position: relative;
  height: 60px;
  width: 60px;
  min-width: 60px;
  margin: 16px 0px 0px 29px;
}
.icon1 {
  background: var(--src) center center / contain no-repeat;
}
.icon1_layout {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 15px;
}
.text_body1 {
  @include h-center-content;
  font: $font_type3;
  color: rgb(79, 82, 86);
  text-align: center;
  letter-spacing: 0px;
}
.text_body1_layout {
  position: relative;
  height: min-content;
  margin: 8px 16px 16px;
}
.content_box11_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
  }
}
.content_box11_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @include lg {
    display: none;
    flex: 0 0 100%;
    min-width: unset;
  }
}
.image2 {
  background: var(--src) center center / contain no-repeat;
}
.image2_layout {
  position: relative;
  height: 120px;
  width: 1px;
  min-width: 1px;
}
.content_box {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 8px 8px 0px 0px;
  border-width: 0px;
  border-bottom-width: 1px;
}
.content_box_layout {
  position: relative;
  height: min-content;
}
.block23 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 30px 30px 30px 30px;
}
.block23_layout {
  position: relative;
  height: 60px;
  width: 60px;
  min-width: 60px;
  margin: 16px 0px 0px 29px;
}
.icon1_layout1 {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 15px 0px 0px 15px;
}
.text_body1_layout1 {
  position: relative;
  height: min-content;
  margin: 8px 26.5px 16px;
}
.content_box3 {
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px none rgb(230, 232, 233);
  @include lg {
    flex-wrap: wrap;
    row-gap: 0px;
  }
}
.content_box3_layout {
  position: relative;
  height: min-content;
}
.content_box3_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 118px;
  @include lg {
    flex: 0 0 100%;
    min-width: unset;
  }
}
.content_box1 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 0px 0px 0px 0px;
  border-width: 0px;
  border-bottom-width: 1px;
}
.content_box1_layout {
  position: relative;
  height: min-content;
}
.block26 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 30px 30px 30px 30px;
}
.block26_layout {
  position: relative;
  height: 60px;
  width: 60px;
  min-width: 60px;
  margin: 16px 0px 0px 29px;
}
.block27 {
  background: var(--src) center center / contain no-repeat;
}
.block27_layout {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 15px;
}
.text_body1_layout2 {
  position: relative;
  height: min-content;
  width: 32px;
  min-width: 32px;
  margin: 8px auto 16px;
}
.content_box3_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
  }
}
.content_box3_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @include lg {
    display: none;
    flex: 0 0 100%;
    min-width: unset;
  }
}
.content_box2 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-width: 0px;
  border-bottom-width: 1px;
}
.content_box2_layout {
  position: relative;
  height: min-content;
}
.icon1_layout2 {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 15px 0px 0px 15px;
}
.text_body1_layout3 {
  position: relative;
  height: min-content;
  margin: 8px 14px 16px 15px;
}
.content_box6 {
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px none rgb(230, 232, 233);
  @include lg {
    flex-wrap: wrap;
    row-gap: 0px;
  }
}
.content_box6_layout {
  position: relative;
  height: min-content;
}
.content_box6_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 118px;
  @include lg {
    flex: 0 0 100%;
    min-width: unset;
  }
}
.content_box4 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-width: 0px;
  border-bottom-width: 1px;
}
.content_box4_layout {
  position: relative;
  height: min-content;
}
.block30 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 30px 30px 30px 30px;
}
.block30_layout {
  position: relative;
  height: 60px;
  width: 60px;
  min-width: 60px;
  margin: 16px 0px 0px 29px;
}
.image11 {
  background: var(--src) center center / contain no-repeat;
}
.image11_layout {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 15px;
}
.text_body11 {
  @include h-center-content;
  font: $font_type2;
  color: rgb(79, 82, 86);
  text-align: center;
  letter-spacing: 0px;
}
.text_body11_layout {
  position: relative;
  height: min-content;
  margin: 8px 18.5px 16px 19.5px;
}
.content_box6_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg {
    display: none;
  }
}
.content_box6_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @include lg {
    display: none;
    flex: 0 0 100%;
    min-width: unset;
  }
}
.content_box41 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-width: 0px;
  border-bottom-width: 1px;
}
.content_box41_layout {
  position: relative;
  height: min-content;
}
.icon1_layout3 {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 15px 0px 0px 15px;
}
.small_text_body2 {
  @include h-center-content;
  font: $font_type3;
  color: rgb(79, 82, 86);
  text-align: center;
  letter-spacing: 0px;
}
.small_text_body2_layout {
  position: relative;
  height: min-content;
  margin: 8px 4.5px 16.5px;
}
.content_box31 {
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px none rgb(230, 232, 233);
  border-radius: 0px 0px 8px 8px;
  @include lg {
    flex-wrap: wrap;
    row-gap: 0px;
  }
}
.content_box31_layout {
  position: relative;
  height: min-content;
}
.content_box5 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-style: none;
  border-width: 1px;
  border-radius: 0px 0px 0px 8px;
}
.content_box5_layout {
  position: relative;
  height: min-content;
}
.icon1_layout4 {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 15px 0px 0px 15px;
}
.text_body1_layout4 {
  position: relative;
  height: min-content;
  margin: 8px 0px 16px;
}
.content_box3_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 1px;
  @include lg {
    display: none;
    flex: 0 0 100%;
    min-width: unset;
  }
}
.content_box7 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 0px 0px 8px 8px;
  border-width: 0px;
  @include lg {
    border-width: 0px;
    border-top-width: 1px;
  }
}
.content_box7_layout {
  position: relative;
  height: min-content;
}
.icon1_layout5 {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 15px 0px 0px 15px;
}
.text_body1_layout5 {
  position: relative;
  height: min-content;
  margin: 8px 32.5px 16px 33.5px;
}
.flex8_item {
  @include flex-column;
  position: relative;
}
.flex2 {
  @include flex-column;
}
.flex2_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 24px auto 0px;
}
.text_body12 {
  @include h-center-content;
  font: $font_type4;
  color: rgb(187, 192, 197);
  text-align: center;
  letter-spacing: 0px;
}
.text_body12_layout {
  position: relative;
  height: min-content;
  width: 62px;
  min-width: 62px;
  margin: 0px auto;
}
.flex2_item {
  @include flex-column;
  position: relative;
}
.flex3 {
  display: flex;
  @include lg {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.flex3_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 12px auto 0px;
}
.flex3_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 70px;
  @include lg {
    flex: 0 0 100%;
    min-width: unset;
  }
}
.group {
  @include flex-column;
}
.group_layout {
  position: relative;
  height: 70px;
  width: 70px;
  min-width: 70px;
  margin: 0px auto;
}
.block22 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 35px 35px 35px 35px;
}
.block22_layout {
  position: absolute;
  overflow: hidden;
  top: 0px;
  height: 70px;
  left: 0px;
  right: 0px;
  width: 70px;
  min-width: 70px;
  margin: 0px auto;
}
.icon1_layout6 {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 20px;
}
.icon4 {
  background: var(--src) center center / contain no-repeat;
}
.icon4_layout {
  position: absolute;
  top: 5px;
  height: 10px;
  width: 10px;
  min-width: 10px;
  right: 7px;
}
.flex3_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 14px;
  @include lg {
    display: none;
  }
}
.block231 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 35px 35px 35px 35px;
}
.block231_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px auto;
}
.icon1_layout7 {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 20px;
}
.block232 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 35px 35px 35px 35px;
}
.block232_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px auto;
}
.icon1_layout8 {
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 20px;
}
.block_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 1096px;
}
.block34 {
  @include flex-column;
}
.block34_layout {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.image1 {
  background: var(--src) center center / auto no-repeat;
}
.image1_layout {
  position: absolute;
  z-index: 6;
  top: 0px;
  bottom: 0px;
  min-height: 1189px;
  flex-shrink: 0;
  left: 0px;
  width: 1px;
}
.flex {
  @include flex-column;
}
.flex_layout {
  position: absolute;
  overflow: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.group_layout2 {
  position: relative;
  height: min-content;
}
.block1 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
}
.block1_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
}
.flex8_layout1 {
  position: relative;
  height: min-content;
  width: 71.17%;
  margin: 85px auto;
}
.flex11 {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 16px;
}
.big_title {
  @include v-center-content;
  @include font-face($font_type5, rgb(187, 192, 197), 0px);
}
.big_title_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 57px;
  flex-shrink: 0;
}
.flex11_spacer {
  @include flex-column;
  position: relative;
  flex: 1 0 auto;
}
.text_body {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type1;
  color: rgb(187, 192, 197);
  text-align: right;
  letter-spacing: 0px;
}
.text_body_layout {
  position: absolute;
  right: 40px;
  flex: 0 0 auto;
  height: min-content;
  margin: 37px 0px 0px;
}
.flex12 {
  @include flex-column;
}
.flex12_layout {
  position: relative;
  height: min-content;
  width: 100%;
}
.block5 {
  @include flex-column;
}
.block5_layout {
  position: relative;
  height: min-content;
}
.flex13 {
  @include flex-column;
}
.flex13_layout {
  position: relative;
  height: min-content;
  margin: 16px;
  z-index: 3;
}
.flex13_item {
  @include flex-column;
  position: relative;
}
.group_layout1 {
  position: relative;
  height: min-content;
  margin: 16px 0px 0px;
}
.block6 {
  @include flex-column;
  background-color: rgb(250, 251, 251);
  border: 1.5px solid rgb(230, 232, 233);
  border-radius: 16px 16px 16px 16px;
}
.block6_layout {
  position: relative;
  height: min-content;
}
.paragraph_body {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: $font_type1;
  color: rgb(154, 159, 163);
  text-align: center;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
.paragraph_body_box {
  @include v-center-content;
  justify-content: center;
}
.paragraph_body_box_layout {
  position: relative;
  height: min-content;
  aspect-ratio: 16 / 9;
}
.block7 {
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 18px 18px 18px 18px;
}
.block7_layout {
  position: absolute;
  overflow: hidden;
  top: 65px;
  height: 38px;
  width: 180px;
  left: -100vw;
  right: -100vw;
  margin: 0px auto;
}
.block7_item {
  @include flex-column;
  position: relative;
  flex: 0 1 32px;
}
.block8 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 18px 18px 18px 18px;
}
.block8_layout {
  position: relative;
  height: min-content;
}
.block9 {
  background: var(--src) center center / contain no-repeat;
  transform: rotate(180deg);
}
.block9_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin: 8px 4px 8px 8px;
}
.block7_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 114px;
}
.block10 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
}
.block10_layout {
  position: relative;
  height: min-content;
}
.text_body13 {
  @include h-center-content;
  font: $font_type1;
  color: rgb(210, 213, 216);
  text-align: center;
  letter-spacing: 0px;
}
.text_body13_layout {
  position: relative;
  height: min-content;
  margin: 8px 6.5px;
}
.block7_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 32px;
}
.block81 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 18px 18px 18px 18px;
}
.block81_layout {
  position: relative;
  height: 36px;
}
.block91 {
  background: var(--src) center center / 100% 100% no-repeat;
}
.block91_layout {
  position: relative;
  height: 20px;
  margin: 8px 8px 8px 4px;
}
.block233 {
  @include flex-column;
  width: 100%;
  height: 100%;
}
.block11 {
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 26px 26px 26px 26px;
}
.block11_layout {
  position: absolute;
  overflow: hidden;
  height: 44px;
  bottom: -26px;
  width: fit-content;
  left: -100vw;
  right: -100vw;
  margin: 0px auto;
  z-index: 3;
}
.block11_item {
  @include flex-column;
  position: relative;
}
.block12 {
  @include flex-column;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 26px 0px 0px 26px;
  border-width: 0px;
  border-right-width: 1px;
}
.block12_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.text_body21 {
  @include h-center-content;
  font: $font_type1;
  color: rgb(2, 120, 228);
  text-align: center;
  letter-spacing: 0px;
}
.text_body21_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 15px 45.5px;
  @include lg {
    margin: 15px 24px;
  }
}
.text_body22 {
  @include h-center-content;
  font: $font_type1;
  color: rgb(2, 120, 228);
  text-align: center;
  letter-spacing: 0px;
}
.text_body22_layout {
  position: relative;
  height: min-content;
  margin: 12px 68.5px;
  @include lg {
    margin: 12px 17px;
  }
}
.block36 {
  @include flex-column;
  border: 1px solid rgb(230, 232, 233);
  border-radius: 0px 26px 26px 0px;
  border-width: 0px;
  border-left-width: 1px;
}
.block36_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.text_body23 {
  @include h-center-content;
  font: $font_type1;
  color: rgb(2, 120, 228);
  text-align: center;
  letter-spacing: 0px;
}
.text_body23_layout {
  position: relative;
  height: min-content;
  margin: 16px 23px 16px 28px;
  @include lg {
    margin: 16px 7px;
  }
}
.flex14 {
  display: flex;
}
.flex14_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: 100%;
  justify-content: center;
  margin: 24px auto 0px;
}
.flex14_item {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
}
.block13 {
  background: var(--src) center center / cover no-repeat;
  filter: drop-shadow(0px 2px 4px 0px);
}
.block13_layout {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.flex14_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 32px;
}
.text_body3 {
  @include font-face($font_type1, rgb(0, 0, 0), 0px);
}
.text_body3_box_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 10px 0px;
}
.text_body3_span0 {
  @include font-face($font_type6, #4f5256ff, 0px);
}
.text_body3_span1 {
  @include font-face($font_type6, #9a9fa3ff, 0px);
}
.block131 {
  background: var(--src) center center / cover no-repeat;
}
.block131_layout {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.flex15 {
  @include flex-column;
}
.flex15_layout {
  position: relative;
  height: min-content;
  width: 81.13%;
  margin: 24px 10.06% 16px 8.81%;
}
.block14 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.block14_layout {
  position: relative;
  height: min-content;
}
.flex16 {
  display: flex;
}
.flex16_layout {
  position: relative;
  height: min-content;
  margin: 10px 16px;
}
.text_body14 {
  @include v-center-content;
  @include font-face($font_type1, rgb(210, 213, 216), 0px);
}
.text_body14_layout {
  position: relative;
  min-height: 20px;
  flex-shrink: 0;
}
.flex16_spacer {
  @include flex-column;
  position: relative;
  flex: 1 0 auto;
}
.small_text_body {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type3;
  color: rgb(154, 159, 163);
  text-align: right;
  letter-spacing: 0px;
}
.small_text_body_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 0px 0px 2px;
}
.block15 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 4px 4px 4px 4px;
}
.block15_layout {
  position: relative;
  height: min-content;
  margin: 12px 0px 0px;
}
.blockEditor {
  @include flex-column;
}
.blockEditor_layout {
  position: relative;
  height: min-content;
  // width: 69.35%;
  width: 100%;
  margin: 0px auto;
  @include md {
    padding: 0 24px;
  }
}
.blockRender_layout {
  position: relative;
  height: min-content;
  // width: 84%;
  width: 100%;
  margin: 0px auto;
  @include sm {
    width: 100%;
  }
  @include xxs {
    width: 100%;
  }
}
.flex17 {
  display: flex;
}
.flex17_layout {
  position: relative;
  height: min-content;
  margin: 11px 16px;
}
.flex17_item {
  @include flex-column;
  position: relative;
}
.text_body15 {
  @include font-face($font_type1, rgb(210, 213, 216), 0px);
}
.text_body15_layout {
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  flex-shrink: 0;
  min-width: 0px;
  width: fit-content;
}
.flex17_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.small_text_body_layout1 {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 42px 0px 0px;
}
.blockBorder {
  @include flex-column;
  border-radius: 24px 24px 24px 24px;
}
.blockBorder_layout {
  position: relative;
  height: min-content;
}
.flex19_layout {
  position: relative;
  flex-shrink: 0;
  margin: 16px;
}
.buttons {
  display: flex;
  color: #0099FF;
}
.buttons_layout {
  position: absolute;
  top: 12px;
  height: 40px;
  right: 12px;
  gap: 8px;
}
.buttons_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
  background-color: #fff;
  border: 1px solid #D3D6D8;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
}
.block3 {
  background: var(--src) center center / contain no-repeat;
}
.block3_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin: 10px 10px 10px 16px;
  filter: invert(30%) sepia(9%) saturate(375%) hue-rotate(164deg) brightness(34%) contrast(81%);
}
.block4 {
  background: var(--src) center center / contain no-repeat;
}
.block4_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin: 10px;
}
.block31 {
  @include flex-column;
  border-radius: 24px 24px 24px 24px;
}
.block31_layout {
  position: relative;
  height: min-content;
}
.block41 {
  @include flex-column;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(211, 214, 216);
  border-radius: 8px 8px 8px 8px;
}
.block41_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
  margin: 16px;
  z-index: 3;
}
.image {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.image_layout {
  position: relative;
  width: calc(100% - 56px);
  margin: 28px;
}
.block51 {
  @include flex-column;
  background-color: rgb(250, 251, 251);
  border-radius: 0px 0px 8px 8px;
}
.block51_layout {
  position: relative;
  height: min-content;
  margin: 28px 0px 0px;
}
.flex10 {
  display: flex;
}
.flex10_layout {
  position: relative;
  height: min-content;
  margin: 16px 24px;
}
.flex10_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 157px;
}
.block234 {
  display: flex;
}
.block234_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 3px;
}
.block23_item {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.block61 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.058823529411764705);
  border: 1px solid rgb(230, 232, 233);
  border-radius: 13px 13px 13px 13px;
}
.block61_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px 0px 14px;
}
.small_text_body1 {
  @include h-center-content;
  font: $font_type3;
  color: rgb(140, 143, 155);
  text-align: center;
  letter-spacing: 0px;
}
.small_text_body1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 3px 9px;
}
.block23_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
}
.block23_item1 {
  @include flex-column;
  position: relative;
}
.flex111 {
  @include flex-column;
}
.flex111_layout {
  position: relative;
  height: min-content;
  margin: 2px 0px 0px;
}
.highlights {
  @include font-face($font_type7, rgb(79, 82, 86), 0px);
}
.highlights_layout {
  position: relative;
  height: min-content;
}
.small_text_body11 {
  @include font-face($font_type3, rgb(154, 159, 163), 0px);
}
.small_text_body11_layout {
  position: relative;
  height: min-content;
}
.flex10_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.flex10_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 60px;
}
.flex91 {
  display: flex;
}
.flex91_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 20px;
}
.flex9_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
}
.image13 {
  background: var(--src) center center / contain no-repeat;
}
.image13_layout {
  position: relative;
  height: 20px;
  width: 12px;
  min-width: 12px;
}
.flex9_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.text_body16 {
  @include v-center-content;
  justify-content: center;
  font: $font_type1;
  color: rgb(79, 82, 86);
  text-align: center;
  letter-spacing: 0px;
}
.text_body16_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.block42 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
}
.block42_layout {
  position: absolute;
  height: 72px;
  z-index: 10;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.line {
  background: var(--src) center center / contain no-repeat;
}
.line_layout {
  position: absolute;
  top: 0px;
  height: 1px;
  left: 0px;
  right: 0px;
  margin: 0px 0px 72px;
}
.flex20 {
  display: flex;
}
.flex20_layout {
  position: relative;
  height: min-content;
  width: 71.43%;
  margin: 16px auto;
  @include lg {
    width: 93.3%;
  }
}
.flex20_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 18px;
}
.small_text_body12 {
  @include v-center-content;
  justify-content: center;
  font: $font_type3;
  color: rgb(155, 159, 164);
  text-align: center;
  letter-spacing: 0px;
  @include lg {
    text-align: center;
  }
}
.small_text_body12_layout {
  position: relative;
  height: min-content;
  margin: 11px 0px;
}
.flex20_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.flex20_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 39px;
}
.small_text_body13 {
  @include v-center-content;
  @include font-face($font_type8, rgb(79, 82, 86), 0px);
}
.small_text_body13_layout {
  position: relative;
  height: min-content;
  margin: 11px 0px;
}
.flex20_spacer1 {
  @include flex-column;
  position: relative;
  flex: 1 1 366px;
}
.flex191 {
  display: flex;
}
.flex191_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.flex1 {
  display: flex;
}
.flex1_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 8px 0px;
}
.flex_item {
  @include flex-column;
  position: relative;
  flex: 0 1 22px;
}
.image23 {
  background: var(--src) center center / contain no-repeat;
}
.image23_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.flex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
}
.highlights1 {
  @include v-center-content;
  justify-content: center;
  font: $font_type9;
  color: rgb(154, 159, 163);
  text-align: center;
  letter-spacing: 0px;
}
.highlights1_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.flex19_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
}
.block52 {
  flex: 0 0 auto;
}
.block52_layout {
  position: relative;
  flex: 0 0 auto;
  height: 40px;
  min-width: 126px;
}
.flex19_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
}
.block53 {
  flex: 0 0 auto;
}
.block53_layout {
  position: relative;
  flex: 0 0 auto;
  height: 40px;
  width: 126px;
  min-width: 126px;
}
.block_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
